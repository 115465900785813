<template>
  <div>
    <!-- <OfferingBanner title="Manor DAO" number="008" :hide-social-bar="true" /> -->
    <div
      class="noise-bg fixed inset-0 z-50 opacity-50 pointer-events-none mix-blend-exclusion brightness-75"
    />
    <overview-page />
    <signup-form />
    <div class="bg-green pb-10 overflow-hidden">
      <h3
        class="text-2xl lg:text-3xl font-serif px-5 lg:px-10 w-full text-center mb-6"
      >
        Discover Poolsuite projects in
      </h3>
      <div className="px-2">
        <marquee-text
          class="h-10"
          :duration="100"
        >
          <div class="h-10">
            <img
              width="33"
              height="40"
              src="@/assets/seen-in/apple.svg"
              className="h-full mx-4 inline-block"
            >
            <img
              width="246"
              height="40"
              src="@/assets/seen-in/fast-company.svg"
              className="h-full mx-4 inline-block"
            >
            <img
              width="151"
              height="40"
              src="@/assets/seen-in/forbes.svg"
              className="h-full mx-4 inline-block"
            >
            <img
              width="282"
              height="40"
              src="@/assets/seen-in/monocle.svg"
              className="h-full mx-4 inline-block"
            >
            <img
              width="261"
              height="40"
              src="@/assets/seen-in/techchrunch.svg"
              className="h-full mx-4 inline-block"
            >
            <img
              width="174"
              height="40"
              src="@/assets/seen-in/vanityfair.svg"
              className="h-full mx-4 inline-block"
            >
            <img
              width="150"
              height="40"
              src="@/assets/seen-in/vogue.svg"
              className="h-full mx-4 inline-block"
            >
            <img
              width="33"
              height="40"
              src="@/assets/seen-in/apple.svg"
              className="h-full mx-4 inline-block"
            >
            <img
              width="246"
              height="40"
              src="@/assets/seen-in/fast-company.svg"
              className="h-full mx-4 inline-block"
            >
            <img
              width="151"
              height="40"
              src="@/assets/seen-in/forbes.svg"
              className="h-full mx-4 inline-block"
            >
            <img
              width="282"
              height="40"
              src="@/assets/seen-in/monocle.svg"
              className="h-full mx-4 inline-block"
            >
            <img
              width="261"
              height="40"
              src="@/assets/seen-in/techchrunch.svg"
              className="h-full mx-4 inline-block"
            >
            <img
              width="174"
              height="40"
              src="@/assets/seen-in/vanityfair.svg"
              className="h-full mx-4 inline-block"
            >
            <img
              width="150"
              height="40"
              src="@/assets/seen-in/vogue.svg"
              className="h-full mx-4 inline-block"
            >
          </div>
        </marquee-text>
      </div>
      <div class="pt-10 flex items-center justify-center">
        <button
          class="underline font-serif text-base"
          @click="togglePrivacy"
        >
          Privacy policy
        </button>

        <div
          v-if="showPrivacy"
          class="fixed left-0 right-0 top-0 bg-green p-10 bg-opacity-70 h-screen overflow-scroll"
          style="z-index: 4000"
          @click.self="togglePrivacy"
        >
          <button
            class="fixed top-3 right-4"
            @click="togglePrivacy"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
          <div class="mx-auto px-4 py-1 rounded-lg bg-white prose font-serif">
            <div class="">
              <p>
                <strong><em>Internet Leisure Corp. (the Company)</em></strong>
              </p>
              <p><strong>DATA PROTECTION PRIVACY NOTICE</strong></p>
              <p>
                The Company is incorporated in the British Virgin Islands and
                its registered office is at Craigmuir Chambers, PO Box 71, Road
                Town Tortola VG1110, British Virgin Islands.
              </p>
              <p>
                The Company is a data controller in respect of your personal
                data for the purposes of the Virgin Islands Data Protection Act,
                2021 (the <strong><em>Act</em></strong>). The Company is responsible for ensuring that it uses your
                personal data in compliance with the Act.&nbsp;
              </p>
              <p>
                The Company will generally act as the data controller of any
                such personal data. &nbsp;
              </p>
              <p>
                This privacy notice applies to you if are a natural person and
                (i) you have provided your personal data to the Company, (ii)
                your personal data has been provided to the Company on our
                behalf; or (iii) the Company otherwise uses your personal data.
                This privacy notice sets out the basis on which personal data
                about you will be processed by the Company. Please take the time
                to read and understand this privacy notice.
              </p>
              <p><strong>Source of personal data</strong></p>
              <p>
                The Company collects personal information through contact forms
                submitted on its website, which may contain:
              </p>
              <ol>
                <li>your first name;</li>
                <li>your email address; and</li>
                <li>
                  your blockchain wallet address if you connect your wallet to
                  the website when submitting the form.
                </li>
              </ol>
              <p><strong>Uses of your personal data</strong></p>
              <p>
                Your personal data may be stored and processed by the
                Company<em>&nbsp;</em>for the following purposes:
              </p>
              <p>
                (a) &nbsp; &nbsp;general business administration, including
                communicating with you and communicating with service providers
                and counterparties; and
              </p>
              <p>
                (b) &nbsp; &nbsp;compliance with legal and regulatory
                obligations and industry standards, including know-your-client
                procedures, the automatic exchange of tax information and legal
                judgments.
              </p>
              <p>
                The Company is entitled to process your personal data in these
                ways for the following reasons:
              </p>
              <p>(a) &nbsp; &nbsp;you have given your express consent;</p>
              <p>
                (b) &nbsp; &nbsp;processing may be necessary to discharge a
                relevant legal or regulatory obligation to which you are the
                subject (other than an obligation imposed by a contract);
              </p>
              <p>
                (c) &nbsp; &nbsp;processing may be necessary for the
                administration of justice;&nbsp;
              </p>
              <p>
                (d) &nbsp; &nbsp;processing may be necessary for the exercise of
                any functions conferred on a person by or under any law;
                and&nbsp;
              </p>
              <p>(e) &nbsp; &nbsp;The personal data will, in all cases:</p>
              <p>
                &nbsp;(i) &nbsp; &nbsp;be processed for a lawful purpose
                directly related to an activity of the Company or Manor DAO, a
                decentralised autonomous organisation (<strong><em>Manor DAO</em></strong>);&nbsp;
              </p>
              <p>
                (ii) &nbsp; &nbsp;be necessary for, or directly related to, the
                purpose referred to at limb (i) above; and
              </p>
              <p>
                &nbsp;(iii) &nbsp; &nbsp;be adequate but not excessive in
                relation to the purpose referred to at limb (i) above.
              </p>
              <p><strong>Personal data that the Company might use</strong></p>
              <p>
                The Company might process the following personal data about you:
              </p>
              <p>
                (a) &nbsp; &nbsp;information provided to the Company by you or
                on your behalf which might include your name, contact details,
                and blockchain wallet address if you connect your wallet to the
                website when submitting the form. Such information might be
                provided in an application form or in other documents,
                face-to-face, by telephone, by email or otherwise; and
              </p>
              <p>
                &nbsp;(b) &nbsp; &nbsp;information that the Company obtains from
                other sources which might include information from public
                websites and other public sources and information received from
                the applicant&rsquo;s advisers or from intermediaries.
              </p>
              <p>
                <strong>Disclosure of your personal data to third parties</strong>
              </p>
              <p>
                The Company may from time to time, in accordance with the
                purposes described above, disclose your personal data to other
                parties, including Manor DAO, professional advisers such as law
                firms and accountancy firms, other service providers of the
                Company, including technology service providers, counterparties,
                courts and regulatory, tax and governmental authorities. Some of
                these persons will process your personal data in accordance with
                the Company&rsquo;s instructions and others will themselves be
                responsible for their use of your personal data. These persons
                may be permitted to further disclose the personal data to other
                parties.
              </p>
              <p>
                Where these third parties process your personal data on our
                behalf we shall ensure that such third parties have appropriate
                data protection measures in place.&nbsp;
              </p>
              <p>
                <strong>Transfers of your personal data outside the British Virgin
                  Islands</strong>
              </p>
              <p>
                Your personal data may be transferred to and stored by persons
                outside the British Virgin Islands, and in particular may be
                transferred to and stored by Manor DAO and its or the
                Company&rsquo;s affiliates or service providers of the Company
                outside the British Virgin Islands.&nbsp;
              </p>
              <p>
                Where personal data is transferred outside the British Virgin
                Islands, the Company will ensure that there is proof of adequate
                data protection safeguards. You can obtain more details of the
                protection given to your personal data when it is transferred
                outside the British Virgin Islands, including a copy of any
                standard data protection clauses entered into with recipients of
                your personal data, by contacting the Company using the details
                set out under &ldquo;Contacting the Company&rdquo; below.
              </p>
              <p><strong>Retention of personal data</strong></p>
              <p>
                How long the Company holds your personal data for will vary. The
                retention period will be determined by various criteria,
                including the purposes for which the Company is using it (as it
                will need to be kept for as long as is necessary for any of
                those purposes) and legal obligations (as laws or regulations
                may set a minimum period for which the Company has to keep your
                personal data).
              </p>
              <p><strong>Your rights</strong></p>
              <p>
                You have a number of legal rights in relation to the personal
                data that the Company holds about you. These rights include the
                following:
              </p>
              <p>
                (a) &nbsp; &nbsp;The right to obtain information regarding the
                processing of your personal data and request access to the
                personal data that the Company holds about you.
              </p>
              <p>
                (b) &nbsp; &nbsp;The right to request that the Company rectifies
                your personal data if it is incomplete, incorrect, misleading,
                or excessive.
              </p>
              <p>
                (c) &nbsp; &nbsp;The right to object to the Company using your
                personal data for direct marketing purposes.
              </p>
              <p>
                (d) &nbsp; &nbsp;The right to lodge a complaint with the BVI
                Information Commissioner if you think that any of your rights
                have been infringed by the Company.
              </p>
              <p>
                You can exercise your rights by contacting the Company using the
                details set out under &ldquo;Contacting the Company&rdquo;
                below. You can also find out more information about your rights
                under applicable British Virgin Islands data protection law by
                contacting the Office of the Information Commissioner. Please
                note that, as of September 2021, an Information Commissioner has
                not yet been appointed
              </p>
              <p><strong>Contacting the Company</strong></p>
              <p>
                If you would like further information on the collection, use,
                disclosure, transfer or processing of your personal data or the
                exercise of any of the rights listed above, please address
                questions and requests to
                <a href="mailto:marty@poolsuite.net">marty@poolsuite.net</a>).&nbsp;
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { OfferingBanner } from "@poolside-fm/offering-banner";

import MarqueeText from "vue-marquee-text-component";

import overviewPage from "./pages/Overview.vue";
import SignupForm from "./SignupForm.vue";

export default {
  name: "App",
  components: {
    // OfferingBanner,
    overviewPage,
    SignupForm,
    MarqueeText,
  },
  data() {
    return {
      showEmailForm: false,
      showPrivacy: false,
    };
  },
  mounted() {
    window.addEventListener("keyup", (event) => {
      if (event.code == "Escape") {
        this.showPrivacy = false;
      }
    });

    history.scrollRestoration = "manual";
    window.addEventListener("beforeunload", (event) => {
      window.scrollTop(0);
    });
  },

  methods: {
    togglePrivacy() {
      this.showPrivacy = !this.showPrivacy;
    },
    toggleEmail() {
      this.showEmailForm = !this.showEmailForm;
      if (this.showEmailForm) {
        setTimeout(() => {
          this.$refs.focusInput.focus();
        }, 200);
      }
    },
  },
};
</script>

<style scoped>
.noise-bg {
  background-image: url("./assets/noise-gif-square-anim.gif");
  background-size: 200px auto;
}
</style>
