// Required here?
import "regenerator-runtime/runtime.js";

import App from "./App.vue";
import "./main.css";

import { createApp } from "vue";

const app = createApp(App);

app.mount("#app");
