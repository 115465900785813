<template>
  <div>
    <section
      class="bg-green flex items-center justify-center relative lg:pb-20"
    >
      <div class="text-center py-10 lg:pb-32 lg:pt-16">
        <p class="text-5xl lg:text-8xl font-serif px-5 lg:px-10">
          <template v-if="submitted">
            Thank you for your submission.
          </template>
          <template v-else>
            Indisputably, <span class="italic">it is time</span>.
          </template>
        </p>

        <template v-if="!submitted">
          <p class="text-3xl lg:text-4xl italic font-serif px-5 lg:px-10 my-6">
            May we count on your support?
          </p>

          <p class="text-2xl lg:text-3xl font-serif px-5 lg:px-10">
            Or, <span class="italic">at least</span>, your curiosity?
          </p>
        </template>

        <div>
          <form
            v-if="!submitted"
            class="text-left mt-16 max-w-2xl mx-auto space-y-6 px-5 lg:px-10"
            @submit="submitForm"
          >
            <label class="block">
              <p class="uppercase mb-2 text-xl">First Name</p>
              <input
                v-model="modelName"
                type="text"
                placeholder="Your first name"
                class="focus:ring-2 ring-black outline-none appearance-none rounded-none w-full border border-black block p-3 bg-green font-mono placeholder-black focus:outline-none"
              >
            </label>

            <label class="block">
              <p class="uppercase mb-2 text-xl">Email</p>
              <input
                v-model="modelEmail"
                type="email"
                placeholder="Your email address"
                class="focus:ring-2 ring-black outline-none appearance-none rounded-none w-full border border-black block p-3 bg-green font-mono focus:outline-none placeholder-black"
              >
            </label>

            <label class="block">
              <p class="uppercase mb-2 text-xl">Interest level</p>
              <div class="relative">
                <input
                  v-if="modelInterestLevel == ''"
                  disabled
                  type="text"
                  placeholder="Select one"
                  class="add-select-arrow rounded-none w-full absolute pointer-events-none inset-0 border border-black block p-3 bg-green font-mono focus:outline-none placeholder-black"
                >
                <select
                  v-model="modelInterestLevel"
                  class="focus:ring-2 ring-black outline-none appearance-none rounded-none w-full border border-black block p-3 focus:outline-none bg-green font-mono placeholder-black"
                >
                  <option>
                    Follow along with the project and its progress
                  </option>
                  <option>Back the Manor to receive tokens & stays</option>
                  <option>Back the Manor from a DAO or corporation</option>
                  <option>Become a core contributor to the project</option>
                </select>
              </div>
            </label>

            <label
              v-if="
                modelInterestLevel == 'Become a core contributor to the project'
              "
              class="block"
            >
              <p class="uppercase mb-2 text-xl">
                what do you bring to the mix?
              </p>
              <input
                v-model="modelMix"
                type="text"
                placeholder="Go off..."
                class="focus:ring-2 ring-black outline-none appearance-none rounded-none w-full border border-black block p-3 focus:outline-none bg-green font-mono placeholder-black"
              >
            </label>

            <div class="content">
              <label class="block">
                <p class="uppercase mb-2 text-xl">
                  Do you hold a Poolsuite Member NFT?
                </p>
                <button
                  type="button"
                  class="toggle focus:ring-2 ring-black outline-none appearance-none rounded-none w-full border border-black block p-3 focus:outline-none bg-green font-mono placeholder-black"
                  :disabled="verifiedExecMemberAddress"
                  @click.self="decideAction"
                >
                  {{ connectWalletText }}
                </button>
              </label>
            </div>
            <p
              v-if="error"
              class="text-center mt-10 -mb-10 text-red-600 font-bold"
            >
              {{ error }}
            </p>
            <div class="text-center pt-6">
              <button
                type="submit"
                class="rounded-full font-sans uppercase text-white bg-black px-5 py-3 pb-2.5 mx-auto inline-block focus:ring-2 ring-black ring-offset-1 ring-offset-green outline-none"
              >
                <template v-if="submitting">
                  <svg
                    class="animate-spin h-5 w-5 text-white mb-1 ml-5 mr-5"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      class="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      stroke-width="4"
                    />
                    <path
                      class="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    />
                  </svg>
                </template><template v-else>
                  Submit
                </template>
              </button>
            </div>
          </form>
          <p
            v-else
            class="font-serif text-xl mt-10 px-4"
          >
            Receive timely updates via the communication pathways of one’s
            preference:<br>
            <a
              href="http://t.me/poolsuite"
              target="_blank"
              rel="noreferrer noopener"
              class="uppercase underline font-sans text-base"
            >Telegram</a>,
            <a
              href="http://instagram.com/poolsuite"
              target="_blank"
              rel="noreferrer noopener"
              class="uppercase underline font-sans text-base"
            >Instagram</a>,
            <a
              href="http://twitter.com/poolsuite"
              target="_blank"
              rel="noreferrer noopener"
              class="uppercase underline font-sans text-base"
            >Twitter</a>.
          </p>
          <div class="text-center pt-20 pb-20 lg:pb-0">
            <svg
              class="inline-block"
              width="108"
              height="101"
              viewBox="0 0 108 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M39.7465 85.464L18.2905 0.503998H0.8665V2.376H3.7465C8.6425 2.376 10.3705 3.96 10.3705 11.16V79.272C10.3705 94.104 8.7865 97.128 1.7305 97.128H0.8665V99H22.0345V97.128H21.6025C13.3945 97.128 12.6745 94.392 12.6745 79.272V7.55999H12.8185L36.8665 100.872H38.1625L61.9225 7.55999H62.2105V88.344C62.2105 95.832 60.4825 97.128 55.5865 97.128H52.7065V99H78.7705V97.128H75.7465C70.9945 97.128 69.2665 95.688 69.2665 88.344V11.016C69.2665 3.67199 71.2825 2.376 76.1785 2.376H78.7705V0.503998H61.3465L39.7465 85.464ZM99.1094 94.68C99.1094 91.368 96.6614 88.92 93.2054 88.92C89.8934 88.92 87.4454 91.368 87.4454 94.68C87.4454 98.136 89.8934 100.584 93.2054 100.584C96.6614 100.584 99.1094 98.136 99.1094 94.68Z"
                fill="black"
              />
              <path
                d="M82.1077 75H93.3017C101.19 75 107.396 68.446 107.396 55.048C107.396 41.824 101.538 35.328 93.5337 35.328H82.1077V36.198H82.9777C84.9497 36.198 85.6457 36.894 85.6457 39.62V70.65C85.6457 73.376 84.9497 74.13 82.9777 74.13H82.1077V75ZM92.6057 74.13C90.6337 74.13 89.9377 73.434 89.9377 70.708V39.62C89.9377 36.894 90.6917 36.198 92.6057 36.198H93.6497C100.262 36.198 102.698 42.868 102.698 55.28C102.698 67.46 99.8557 74.13 93.5337 74.13H92.6057Z"
                fill="black"
              />
            </svg>
          </div>

          <div
            class="absolute lg:block hidden bottom-16 left-0 w-full h-auto p-4 pointer-events-none"
          >
            <svg
              width="1406"
              height="385"
              viewBox="0 0 1406 385"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="w-full h-auto"
            >
              <path
                d="M6.02992 2.41382V2.81989C6.02992 3.74483 6.34609 4.06066 7.65595 4.06066H22.1096C23.4195 4.06066 23.7356 3.69971 23.7356 2.81989V2.41382H24.1421V7.87321C24.1421 10.8059 22.5613 12.8363 19.2414 12.8363C15.6958 12.8363 14.0472 10.5127 14.0472 7.57994V6.06845H7.65595C6.34609 6.06845 6.02992 6.4294 6.02992 7.39946V8.05369H5.646V2.41382H6.02992ZM14.4988 7.35434C14.4988 10.3548 17.2089 10.648 19.1511 10.648C21.3643 10.648 23.7582 10.3548 23.7582 7.53482V7.28666C23.7582 6.45196 23.4646 6.06845 22.1548 6.06845H14.4988V7.35434Z"
                fill="#231F20"
              />
              <path
                d="M11.3145 12.0242C14.9505 12.0242 17.3444 14.0094 17.3444 16.0398C17.3444 18.0927 14.9505 20.0553 11.3597 20.0553C7.81406 20.0553 5.2395 18.1378 5.2395 16.0398C5.2395 13.9417 7.81406 12.0242 11.3145 12.0242ZM11.292 17.9799C14.6569 17.9799 17.0057 17.5738 17.0057 16.0398C17.0057 14.4606 14.7021 14.0996 11.3371 14.0996C8.06248 14.0996 5.64601 14.438 5.64601 16.0623C5.64601 17.6866 8.01731 17.9799 11.292 17.9799Z"
                fill="#231F20"
              />
              <path
                d="M11.3145 20.7998C14.9505 20.7998 17.3444 22.785 17.3444 24.8154C17.3444 26.8683 14.9505 28.831 11.3597 28.831C7.81406 28.831 5.2395 26.9134 5.2395 24.8154C5.2395 22.7174 7.81406 20.7998 11.3145 20.7998ZM11.292 26.7555C14.6569 26.7555 17.0057 26.3494 17.0057 24.8154C17.0057 23.2362 14.7021 22.8753 11.3371 22.8753C8.06248 22.8753 5.62343 23.2362 5.62343 24.8605C5.62343 26.4848 8.01731 26.7555 11.292 26.7555Z"
                fill="#231F20"
              />
              <path
                d="M5.98475 29.3724V29.5529C5.98475 30.523 6.30093 30.6809 7.61079 30.6809H22.1773C23.4872 30.6809 23.8034 30.4553 23.8034 29.3724V29.1469H24.1647L24.6164 32.4405H7.61079C6.30093 32.4405 5.98475 32.5985 5.98475 33.5685V33.749H5.646V29.3048H5.98475V29.3724Z"
                fill="#231F20"
              />
              <path
                d="M6.25577 35.7117C6.25577 35.4184 5.98476 35.2154 5.39759 35.0575V34.7867H10.0047V35.08C7.36238 35.3958 5.75893 36.7269 5.75893 38.2835C5.75893 39.7273 6.75261 40.3138 7.81405 40.3138C9.01099 40.3138 9.57559 39.7724 10.3886 37.8548C11.179 36.0501 11.9017 34.877 14.0923 34.877C16.1249 34.877 17.2315 36.0952 17.2315 37.6292C17.2315 38.8926 16.5314 39.3438 16.5314 39.8852C16.5314 40.201 16.7572 40.3815 17.2089 40.4717V40.7424H13.0761V40.4492C15.2893 40.1559 16.8476 39.1407 16.8476 37.6292C16.8476 36.6592 16.1249 35.8696 14.9957 35.8696C13.7762 35.8696 13.3019 36.4561 12.5115 38.2609C11.6985 40.1785 10.8403 41.329 8.7174 41.329C6.70745 41.329 5.35242 40.1333 5.35242 38.2609C5.375 36.8397 6.25577 36.1854 6.25577 35.7117Z"
                fill="#231F20"
              />
              <path
                d="M9.01099 42.9533H15.086C16.3056 42.9533 16.6217 42.7728 16.6217 41.8704V41.6899H16.9379L17.1637 44.8032H8.7174C6.95587 44.8032 6.23319 45.119 6.23319 46.1116C6.23319 47.5103 8.37865 48.3224 10.6596 48.3224H15.086C16.3281 48.3224 16.6669 48.142 16.6669 47.2396V46.7884H16.9831L17.2089 50.1497H7.63338C6.41386 50.1497 6.05252 50.3979 6.05252 51.2777V51.4131H5.75893L5.35242 48.345L8.3109 48.4578V48.4127C6.57194 47.984 5.35242 46.9237 5.35242 45.4574C5.375 43.8105 6.41386 42.9533 9.01099 42.9533Z"
                fill="#231F20"
              />
              <path
                d="M5.98475 51.7289V51.9545C5.98475 52.8569 6.27834 53.0373 7.47528 53.0373H15.086C16.3507 53.0373 16.6669 52.8794 16.6669 51.9545V51.7289H16.9605L17.1863 54.8421H7.47528C6.27834 54.8421 5.98475 55 5.98475 55.9249V56.1505H5.646V51.7289H5.98475ZM21.6353 52.3605C22.358 52.3605 22.9678 52.9245 22.9678 53.6916C22.9678 54.3909 22.3806 55 21.6353 55C20.9126 55 20.3255 54.3909 20.3255 53.6916C20.3255 52.9697 20.9126 52.3605 21.6353 52.3605Z"
                fill="#231F20"
              />
              <path
                d="M8.73999 57.662H16.5314V56.2859H16.8476C17.1637 57.7297 18.3381 58.9479 21.8386 59.1284V59.5119H16.9605V62.0611H16.554V59.5119H8.64965C6.86553 59.5119 6.09769 59.7826 6.09769 60.5722C6.09769 61.4294 7.29463 61.9934 9.66593 62.0386V62.4221C6.70745 62.4221 5.28467 61.5648 5.28467 60.0984C5.28467 58.5418 6.39127 57.662 8.73999 57.662Z"
                fill="#231F20"
              />
              <path
                d="M11.2468 62.8958C14.928 62.8958 17.367 64.8133 17.367 66.8437C17.367 68.4679 15.6958 70.3404 12.2405 70.3404C11.9921 70.3404 11.563 70.3178 11.4275 70.2953V64.9712C8.33348 64.9487 5.96218 65.3773 5.96218 67.2949C5.96218 68.6484 7.02362 69.4831 8.42382 70.002L8.26573 70.3178C6.59453 69.7538 5.26208 68.7161 5.26208 66.9565C5.26208 64.7231 7.45271 62.8958 11.2468 62.8958ZM11.8566 67.34C11.8566 68.1972 12.1276 68.4003 13.0535 68.4003C15.1764 68.4003 17.0282 68.107 17.0282 66.7985C17.0282 65.445 14.9505 65.0163 11.8566 64.9487V67.34Z"
                fill="#231F20"
              />
              <path
                d="M18.2025 71.2428C19.4446 72.7768 20.89 73.4085 21.5901 73.4085C22.245 73.4085 21.7708 73.0926 21.7708 72.5512C21.7708 71.7616 22.2902 71.2202 23.0129 71.2202C23.7581 71.2202 24.3453 71.7391 24.3453 72.5512C24.3453 73.2731 23.7581 74.0401 22.1999 74.0401C20.7545 74.0401 19.3543 73.2731 17.8864 71.5135L18.2025 71.2428Z"
                fill="#231F20"
              />
              <path
                d="M6.25577 74.5365C6.25577 74.2432 5.98476 74.0402 5.39759 73.8823V73.6116H10.0047V73.9048C7.36238 74.2207 5.75893 75.5517 5.75893 77.1083C5.75893 78.5521 6.75261 79.1386 7.81405 79.1386C9.01099 79.1386 9.57559 78.5972 10.3886 76.6797C11.179 74.8749 11.9017 73.7018 14.0923 73.7018C16.1249 73.7018 17.2315 74.92 17.2315 76.4541C17.2315 77.7174 16.5314 78.1686 16.5314 78.71C16.5314 79.0258 16.7572 79.2063 17.2089 79.2966V79.5673H13.0761V79.274C15.2893 78.9807 16.8476 77.9655 16.8476 76.4541C16.8476 75.484 16.1249 74.6944 14.9957 74.6944C13.7762 74.6944 13.3019 75.281 12.5115 77.0857C11.6985 79.0033 10.8403 80.1313 8.7174 80.1313C6.70745 80.1313 5.35242 78.9356 5.35242 77.0857C5.375 75.6419 6.25577 74.9877 6.25577 74.5365Z"
                fill="#231F20"
              />
              <path
                d="M22.4935 88.952V88.9295H9.39492C6.70745 88.9295 6.02993 89.0874 6.02993 90.644V90.7342H5.64601V86.6284H6.05252V86.7638C6.05252 88.0948 6.70745 88.4106 9.4175 88.4106H22.1096C23.4195 88.4106 23.7582 88.0948 23.7582 87.1698V86.6284H24.1647V90.5538L9.48525 94.2986L24.1647 97.9307V101.788H23.7582V101.382C23.7582 100.48 23.442 100.074 22.1322 100.074H7.70113C6.39127 100.074 6.05252 100.435 6.05252 101.315V101.766H5.64601V96.2387H6.05252V96.8027C6.05252 97.7277 6.36869 98.0435 7.70113 98.0435H22.4935V97.9984L5.32983 93.7572V93.3963L22.4935 88.952Z"
                fill="#231F20"
              />
              <path
                d="M8.03989 102.578C10.3434 102.578 11.6985 103.819 12.6696 107.631H13.5729C16.1926 107.631 16.9153 107.067 16.9153 105.872C16.9153 104.789 16.2152 103.977 15.8313 103.954C15.6958 103.954 15.6732 104.067 15.6055 104.225C15.4925 104.676 15.1538 105.082 14.4311 105.082C13.7084 105.082 13.3019 104.586 13.3019 104.022C13.3019 103.435 13.7536 102.984 14.6118 102.984C15.9894 102.984 17.3218 104.089 17.3218 106.255C17.3218 107.879 16.5992 109.413 13.3923 109.413H7.76888C6.63969 109.413 6.27835 109.594 6.27835 109.955C6.27835 110.383 6.95587 110.519 7.94955 110.586V110.857C6.09768 110.857 5.42017 110.135 5.42017 109.278C5.42017 108.488 5.78151 107.654 7.63338 107.609V107.564C6.23318 107.112 5.375 106.323 5.375 104.924C5.42017 103.616 6.25577 102.578 8.03989 102.578ZM10.2079 107.631H12.2856C11.5855 104.879 10.0047 104.541 8.46898 104.541C7.06879 104.541 6.30094 104.902 6.30094 105.781C6.3461 106.571 7.36237 107.631 10.2079 107.631Z"
                fill="#231F20"
              />
              <path
                d="M5.98475 111.083V111.218C5.98475 112.121 6.27834 112.324 7.49787 112.324H15.086C16.3056 112.324 16.6217 112.098 16.6217 111.218V111.083H16.9379L17.1637 114.151L14.273 114.038V114.061C16.0571 114.467 17.1863 115.459 17.1863 116.813C17.1863 118.46 16.1926 119.498 13.5052 119.498H7.49787C6.25576 119.498 5.98475 119.723 5.98475 120.49V120.603H5.646V116.475H6.00734V116.633C6.00734 117.445 6.30093 117.67 7.52045 117.67H14.2278C15.899 117.67 16.3507 117.129 16.3507 116.317C16.3507 114.873 14.3633 114.151 12.105 114.151H7.49787C6.27834 114.151 5.98475 114.309 5.98475 115.144V115.302H5.646V111.083H5.98475Z"
                fill="#231F20"
              />
              <path
                d="M11.3145 121.077C14.9505 121.077 17.3444 123.062 17.3444 125.092C17.3444 127.145 14.9505 129.108 11.3597 129.108C7.81406 129.108 5.2395 127.19 5.2395 125.092C5.2395 122.994 7.81406 121.077 11.3145 121.077ZM11.292 127.01C14.6569 127.01 17.0057 126.604 17.0057 125.07C17.0057 123.491 14.7021 123.13 11.3371 123.13C8.06248 123.13 5.62343 123.491 5.62343 125.115C5.62343 126.739 8.01731 127.01 11.292 127.01Z"
                fill="#231F20"
              />
              <path
                d="M5.98475 129.604V129.74C5.98475 130.642 6.27834 130.845 7.49787 130.845H15.086C16.3056 130.845 16.6217 130.619 16.6217 129.74V129.604H16.9379L17.1637 132.672L12.6921 132.492V132.514C15.5377 132.92 17.2992 133.845 17.2992 135.199C17.2992 136.237 16.4411 136.801 15.4248 136.801C14.4988 136.801 13.8439 136.44 13.8439 135.808C13.8439 135.312 14.1601 134.838 14.9731 134.838C15.5151 134.838 15.8765 135.131 16.0571 135.334C16.1926 135.447 16.283 135.515 16.4185 135.515C16.6443 135.515 16.7121 135.334 16.7121 135.064C16.7121 133.597 13.2116 132.717 10.908 132.717H7.49787C6.27834 132.717 5.98475 132.898 5.98475 133.8V134.251H5.646V129.649H5.98475V129.604Z"
                fill="#231F20"
              />
              <path
                d="M6.02992 142.892V143.298C6.02992 144.223 6.39126 144.539 7.65596 144.539H22.1548C23.4195 144.539 23.7582 144.223 23.7582 143.298V142.892H24.1647V148.216C24.1647 151.961 21.1385 154.69 14.9505 154.69C8.69481 154.69 5.62341 151.803 5.62341 148.126V142.892H6.02992ZM6.02992 148.216C6.02992 151.171 9.14649 152.48 14.8376 152.48C20.6416 152.48 23.7582 151.352 23.7582 148.261V147.765C23.7582 146.862 23.442 146.524 22.1548 146.524H7.63337C6.36868 146.524 6.02992 146.84 6.02992 147.765V148.216Z"
                fill="#231F20"
              />
              <path
                d="M6.02992 154.284C6.12026 155.367 7.09136 155.796 9.03357 156.337L24.5261 160.646V161.097L7.94954 165.609C6.43643 166.015 6.0525 166.557 6.02992 167.459H5.646V161.864H6.0525V162.428C6.0525 163.218 6.50418 163.624 7.04619 163.646C7.54304 163.646 8.24313 163.511 9.19165 163.24L11.721 162.564V157.646L10.095 157.217C8.9884 156.946 8.08505 156.721 7.40753 156.721C6.4816 156.721 6.0525 157.127 6.0525 157.894V158.277H5.646V154.307H6.02992V154.284ZM12.1275 162.451L20.9804 160.127L12.1275 157.736V162.451Z"
                fill="#231F20"
              />
              <path
                d="M14.8376 167.211C20.5513 167.211 24.5261 169.76 24.5261 172.896C24.5261 176.009 20.5739 178.558 14.928 178.558C9.282 178.558 5.2395 176.032 5.2395 172.873C5.26209 169.76 9.1465 167.211 14.8376 167.211ZM14.8376 176.325C19.8512 176.325 24.2099 175.625 24.2099 172.851C24.2099 170.053 19.9641 169.422 14.9505 169.422C9.93693 169.422 5.57826 170.121 5.57826 172.896C5.57826 175.671 9.82401 176.325 14.8376 176.325Z"
                fill="#231F20"
              />
              <path
                d="M5.98475 185.168V185.394C5.98475 186.296 6.27834 186.477 7.47528 186.477H15.086C16.3507 186.477 16.6669 186.319 16.6669 185.394V185.168H16.9605L17.1863 188.281H7.47528C6.27834 188.281 5.98475 188.439 5.98475 189.364V189.59H5.646V185.191H5.98475V185.168ZM21.6353 185.822C22.358 185.822 22.9678 186.386 22.9678 187.153C22.9678 187.853 22.3806 188.462 21.6353 188.462C20.9126 188.462 20.3255 187.853 20.3255 187.153C20.3255 186.432 20.9126 185.822 21.6353 185.822Z"
                fill="#231F20"
              />
              <path
                d="M6.25577 191.349C6.25577 191.056 5.98476 190.853 5.39759 190.695V190.425H10.0047V190.718C7.36238 191.034 5.75893 192.365 5.75893 193.921C5.75893 195.365 6.75261 195.952 7.81405 195.952C9.01099 195.952 9.57559 195.41 10.3886 193.493C11.179 191.688 11.9017 190.515 14.0923 190.515C16.1249 190.515 17.2315 191.733 17.2315 193.267C17.2315 194.53 16.5314 194.982 16.5314 195.523C16.5314 195.839 16.7572 196.019 17.2089 196.11V196.38H13.0761V196.087C15.2893 195.794 16.8476 194.779 16.8476 193.267C16.8476 192.297 16.1249 191.507 14.9957 191.507C13.7762 191.507 13.3019 192.094 12.5115 193.899C11.6985 195.816 10.8403 196.944 8.7174 196.944C6.70745 196.944 5.35242 195.749 5.35242 193.899C5.375 192.477 6.25577 191.823 6.25577 191.349Z"
                fill="#231F20"
              />
              <path
                d="M8.03989 203.757C10.3434 203.757 11.6985 204.998 12.6696 208.811H13.5729C16.1926 208.811 16.9153 208.247 16.9153 207.051C16.9153 205.968 16.2152 205.156 15.8313 205.133C15.6958 205.133 15.6732 205.246 15.6055 205.404C15.4925 205.855 15.1538 206.261 14.4311 206.261C13.7084 206.261 13.3019 205.765 13.3019 205.201C13.3019 204.614 13.7536 204.163 14.6118 204.163C15.9894 204.163 17.3218 205.269 17.3218 207.434C17.3218 209.059 16.5992 210.593 13.3923 210.593H7.76888C6.63969 210.593 6.27835 210.773 6.27835 211.134C6.27835 211.563 6.95587 211.698 7.94955 211.766V212.059C6.09768 212.059 5.42017 211.337 5.42017 210.48C5.42017 209.69 5.78151 208.856 7.63338 208.811V208.765C6.23318 208.314 5.375 207.525 5.375 206.126C5.42017 204.818 6.25577 203.757 8.03989 203.757ZM10.2079 208.811H12.2856C11.5855 206.058 10.0047 205.72 8.46898 205.72C7.06879 205.72 6.30094 206.081 6.30094 206.961C6.3461 207.75 7.36237 208.811 10.2079 208.811Z"
                fill="#231F20"
              />
              <path
                d="M0.451672 217.992V218.15C0.451672 219.052 0.745262 219.233 1.98737 219.233H15.0634C16.3055 219.233 16.5991 219.052 16.5991 218.15V217.992H16.9153L17.1411 221.06L14.2278 220.947V220.993C15.9893 221.376 17.254 222.256 17.254 223.564C17.254 225.685 14.6569 227.061 11.3145 227.061C8.17533 227.061 5.35236 225.482 5.35236 223.497C5.35236 221.963 6.5493 221.376 7.79141 221.06V221.015C7.79141 221.015 5.30719 221.06 4.83293 221.06H2.00995C0.745262 221.06 0.474256 221.308 0.474256 222.053V222.482H0.112915V217.992H0.451672ZM11.2693 225.008C14.2278 225.008 16.6668 224.76 16.6668 223.271C16.6668 222.098 14.6343 221.015 11.2241 221.015C7.97208 221.015 5.8492 221.85 5.8492 223.181C5.8492 224.67 8.24308 225.008 11.2693 225.008Z"
                fill="#231F20"
              />
              <path
                d="M5.98475 227.783V227.963C5.98475 228.933 6.30093 229.091 7.61079 229.091H22.1773C23.4872 229.091 23.8034 228.866 23.8034 227.783V227.557H24.1647L24.6164 230.851H7.61079C6.30093 230.851 5.98475 231.009 5.98475 231.979V232.16H5.646V227.715H5.98475V227.783Z"
                fill="#231F20"
              />
              <path
                d="M8.03989 232.927C10.3434 232.927 11.6985 234.167 12.6696 237.98H13.5729C16.1926 237.98 16.9153 237.416 16.9153 236.22C16.9153 235.137 16.2152 234.325 15.8313 234.303C15.6958 234.303 15.6732 234.415 15.6055 234.573C15.4925 235.025 15.1538 235.431 14.4311 235.431C13.7084 235.431 13.3019 234.934 13.3019 234.37C13.3019 233.784 13.7536 233.333 14.6118 233.333C15.9894 233.333 17.3218 234.438 17.3218 236.604C17.3218 238.228 16.5992 239.762 13.3923 239.762H7.76888C6.63969 239.762 6.27835 239.943 6.27835 240.303C6.27835 240.732 6.95587 240.867 7.94955 240.935V241.206C6.09768 241.206 5.42017 240.484 5.42017 239.627C5.42017 238.837 5.78151 238.002 7.63338 237.957V237.912C6.23318 237.461 5.375 236.671 5.375 235.273C5.42017 233.987 6.25577 232.927 8.03989 232.927ZM10.2079 237.98H12.2856C11.5855 235.228 10.0047 234.889 8.46898 234.889C7.06879 234.889 6.30094 235.25 6.30094 236.13C6.3461 236.92 7.36237 237.98 10.2079 237.98Z"
                fill="#231F20"
              />
              <path
                d="M1.85187 240.665C2.77781 240.665 3.25207 241.161 3.25207 241.702C3.25207 242.199 2.82298 242.559 2.19063 242.559C1.6712 242.559 1.35503 242.334 1.15177 242.131C1.03885 241.996 0.925936 241.883 0.790433 241.883C0.542011 241.883 0.47426 242.063 0.47426 242.424C0.47426 243.349 1.6712 244.026 4.06508 244.748L4.92327 245.018L14.7472 241.996C15.9668 241.612 16.5088 241.341 16.5991 240.507H16.9153V244.861H16.6217C16.5313 244.048 16.1926 243.8 15.7635 243.778C15.4699 243.778 14.9279 243.868 14.3859 244.026L7.99467 245.943L13.6858 247.568C14.3181 247.748 15.0634 247.974 15.5828 247.974C16.2829 247.974 16.5539 247.613 16.6443 246.868H16.9604V249.891H16.6217C16.5313 248.989 15.899 248.808 14.7698 248.448L4.06508 245.176C0.993687 244.229 0 243.394 0 242.311C0 241.183 0.925936 240.665 1.85187 240.665Z"
                fill="#231F20"
              />
              <path
                d="M2.8455 249.192C4.22311 249.192 5.42006 250.41 5.53298 252.35C5.69106 250.861 6.23307 249.891 7.4526 249.891C8.40112 249.891 9.10122 250.523 9.64323 251.335C10.3659 250.433 11.6984 249.824 13.1437 249.824C15.6279 249.824 17.3669 251.358 17.3669 253.298C17.3669 254.335 16.9604 255.17 15.9893 255.87C16.712 256.185 17.3669 256.637 17.3669 257.313C17.3669 257.945 16.8475 258.396 16.0344 258.396C15.3795 258.396 14.9504 258.035 14.9504 257.629C14.9504 257.223 15.2214 256.93 16.0119 256.907C16.3732 256.907 16.5539 256.885 16.5539 256.727C16.5539 256.546 16.2377 256.321 15.6957 256.095C14.8601 256.546 14.0245 256.727 13.1437 256.727C10.6821 256.727 8.89796 255.193 8.89796 253.298C8.89796 252.734 9.05605 252.102 9.43997 251.628C9.14638 251.177 8.73988 250.906 8.22045 250.906C7.5881 250.906 7.18159 251.47 7.11384 253.523C7.06867 255.373 6.59441 257.765 3.83919 257.765C1.53564 257.765 0.0676946 255.712 0.0676946 253.275C0.0451108 251.019 0.903295 249.192 2.8455 249.192ZM3.16168 256.862C4.42637 256.862 5.3523 255.915 5.3523 253.365C5.3523 251.019 4.01986 250.297 2.82292 250.297C1.15172 250.297 0.406452 251.673 0.406452 253.32C0.429036 255.351 1.67114 256.862 3.16168 256.862ZM13.1437 254.832C14.8601 254.832 17.0507 254.674 17.0507 253.253C17.0507 251.831 14.973 251.651 13.1437 251.651C11.2918 251.651 9.21414 251.831 9.21414 253.253C9.21414 254.651 11.2918 254.832 13.1437 254.832Z"
                fill="#231F20"
              />
              <path
                d="M5.98475 258.261V258.396C5.98475 259.299 6.27834 259.502 7.49787 259.502H15.086C16.3056 259.502 16.6217 259.276 16.6217 258.396V258.261H16.9379L17.1637 261.329L12.6921 261.148V261.171C15.5377 261.577 17.2992 262.502 17.2992 263.856C17.2992 264.893 16.4411 265.457 15.4248 265.457C14.4988 265.457 13.8439 265.096 13.8439 264.465C13.8439 263.968 14.1601 263.495 14.9731 263.495C15.5151 263.495 15.8765 263.788 16.0571 263.991C16.1926 264.104 16.283 264.171 16.4185 264.171C16.6443 264.171 16.7121 263.991 16.7121 263.72C16.7121 262.254 13.2116 261.374 10.908 261.374H7.49787C6.27834 261.374 5.98475 261.555 5.98475 262.457V262.908H5.646V258.306H5.98475V258.261Z"
                fill="#231F20"
              />
              <path
                d="M11.3145 265.57C14.9505 265.57 17.3444 267.555 17.3444 269.586C17.3444 271.639 14.9505 273.601 11.3597 273.601C7.81406 273.601 5.2395 271.684 5.2395 269.586C5.2395 267.488 7.81406 265.57 11.3145 265.57ZM11.292 271.526C14.6569 271.526 17.0057 271.12 17.0057 269.586C17.0057 268.006 14.7021 267.646 11.3371 267.646C8.06248 267.646 5.62343 268.006 5.62343 269.631C5.62343 271.255 8.01731 271.526 11.292 271.526Z"
                fill="#231F20"
              />
              <path
                d="M9.01099 275.113H15.086C16.3056 275.113 16.6217 274.932 16.6217 274.03V273.849H16.9379L17.1637 276.963H8.7174C6.95587 276.963 6.23319 277.279 6.23319 278.271C6.23319 279.67 8.37865 280.482 10.6596 280.482H15.086C16.3281 280.482 16.6669 280.302 16.6669 279.399V278.948H16.9831L17.2089 282.309H7.63338C6.41386 282.309 6.05252 282.557 6.05252 283.437V283.573H5.75893L5.35242 280.505L8.3109 280.617V280.572C6.57194 280.144 5.35242 279.083 5.35242 277.617C5.375 275.993 6.41386 275.113 9.01099 275.113Z"
                fill="#231F20"
              />
              <path
                d="M5.917 284.723V284.858C5.98476 285.738 6.30093 285.941 7.52045 285.851L15.086 285.265C16.3056 285.174 16.5991 284.926 16.5314 284.046V283.911L16.8476 283.888L17.2992 286.911L14.4085 287.024V287.047C16.2152 287.317 17.4347 288.22 17.5477 289.573C17.6832 291.22 16.7572 292.326 14.0923 292.529L8.08505 293.002C6.84294 293.093 6.59452 293.341 6.63969 294.108V294.221L6.27834 294.243L5.96217 290.115L6.32351 290.092L6.3461 290.25C6.41385 291.062 6.73002 291.243 7.94955 291.153L14.6569 290.634C16.3281 290.498 16.7346 289.934 16.6669 289.122C16.554 287.701 14.5214 287.114 12.2631 287.295L7.70112 287.656C6.4816 287.746 6.21059 287.927 6.27834 288.784V288.942L5.917 288.964L5.57825 284.746L5.917 284.723Z"
                fill="#231F20"
              />
              <path
                d="M12.1275 295.01C15.4248 294.379 18.3155 295.552 18.6768 297.492C18.9027 298.687 18.0445 299.657 16.4636 300.357L16.4862 300.447C17.2315 300.267 18.8801 299.928 19.7383 299.77L24.0744 298.936C25.2939 298.687 25.5649 298.462 25.3842 297.559L25.2939 297.063L25.6101 296.995L26.6941 300.289L10.0047 303.515C8.76254 303.763 8.55929 303.899 8.64963 304.643L8.69479 305.027L8.37862 305.094C8.19795 304.598 7.38493 302.139 7.38493 302.139L10.1853 301.665L10.1627 301.575C8.53671 301.53 7.22685 301.034 6.95584 299.68C6.61708 297.808 8.71738 295.687 12.1275 295.01ZM13.2116 300.988C17.0508 300.244 18.451 298.823 18.2477 297.808C17.9542 296.296 15.357 296.522 12.5566 297.063C9.50781 297.65 7.31718 298.417 7.61077 299.861C7.90436 301.372 10.8177 301.44 13.2116 300.988Z"
                fill="#231F20"
              />
              <path
                d="M16.0571 311.23C19.4447 309.922 22.4032 310.892 23.1484 312.787C23.8937 314.705 22.3806 317.389 19.0156 318.698C15.6958 319.984 12.6018 319.126 11.8565 317.186C11.1113 315.246 12.7825 312.494 16.0571 311.23ZM18.2026 316.78C21.3417 315.562 23.3743 314.321 22.8322 312.9C22.2677 311.433 19.9867 311.907 16.8475 313.125C13.7987 314.321 11.6533 315.517 12.2404 317.028C12.8276 318.54 15.1538 317.976 18.2026 316.78Z"
                fill="#231F20"
              />
              <path
                d="M14.7246 322.104L14.7924 322.262C15.1763 323.074 15.5376 323.097 16.5991 322.578L24.7518 318.585L24.2098 317.457L24.5712 317.276L25.1132 318.404L26.8973 317.547C29.8106 316.126 32.5658 316.374 33.5144 318.292C34.0564 319.374 33.4918 320.39 32.5432 320.841C31.8206 321.202 31.2334 320.954 31.0527 320.57C30.8269 320.096 30.9624 319.645 31.5721 319.352C31.9787 319.149 32.3852 319.171 32.6562 319.284C32.8594 319.374 32.9272 319.42 33.0401 319.374C33.3111 319.239 33.424 318.856 33.1982 318.427C32.6336 317.254 30.6462 317.75 28.2297 318.923L25.9488 320.051L26.8747 321.924L26.5134 322.104L25.5874 320.232L17.4347 324.225C16.3732 324.744 16.1926 325.082 16.5765 325.872L16.7346 326.21L16.4184 326.368L14.4084 322.285L14.7246 322.104Z"
                fill="#231F20"
              />
              <path
                d="M25.3842 330.857C28.4104 328.759 31.527 328.962 32.6788 330.632C33.6047 331.963 33.2885 334.467 30.443 336.407C30.2397 336.542 29.8784 336.768 29.7429 336.813L26.7166 332.436C24.1647 334.174 22.4483 335.888 23.5323 337.467C24.3002 338.573 25.6552 338.663 27.1006 338.302L27.1457 338.663C25.4519 339.159 23.7582 339.046 22.7645 337.603C21.4998 335.753 22.2676 333 25.3842 330.857ZM28.433 334.151C28.9299 334.873 29.2686 334.873 30.0139 334.331C31.7528 333.136 33.1304 331.827 32.3852 330.767C31.6173 329.662 29.6525 330.474 27.078 332.188L28.433 334.151Z"
                fill="#231F20"
              />
              <path
                d="M29.0202 340.174L35.0727 335.234L34.1919 334.174L34.4404 333.97C35.5921 334.873 37.2859 335.076 40.1089 333.023L40.3573 333.316L36.5632 336.429L38.1667 338.392L37.8505 338.64L36.2471 336.678L30.1268 341.686C28.7492 342.814 28.3201 343.513 28.817 344.122C29.359 344.799 30.6463 344.483 32.5207 342.994L32.7692 343.288C30.4882 345.16 28.817 345.386 27.8911 344.258C26.9199 343.062 27.191 341.663 29.0202 340.174Z"
                fill="#231F20"
              />
              <path
                d="M34.6435 343.084C37.2858 340.535 40.3798 340.219 41.8026 341.663C42.9318 342.836 43.0221 345.34 40.5605 347.732C40.3798 347.912 40.0636 348.183 39.9281 348.251L36.2244 344.415C33.9886 346.536 32.5658 348.521 33.9208 349.897C34.8694 350.867 36.2018 350.732 37.5794 350.123L37.6923 350.461C36.0889 351.228 34.4177 351.409 33.1982 350.146C31.6173 348.566 31.9335 345.724 34.6435 343.084ZM38.1892 345.837C38.7989 346.468 39.1151 346.401 39.7701 345.769C41.2832 344.303 42.4349 342.791 41.5316 341.844C40.5831 340.874 38.7989 342.002 36.5406 344.122L38.1892 345.837Z"
                fill="#231F20"
              />
              <path
                d="M37.1504 353.078L37.2407 353.169C37.9182 353.755 38.2796 353.665 39.07 352.74L44.0385 347.01C44.8289 346.085 44.8966 345.701 44.2191 345.115L44.1062 345.025L44.3095 344.776L46.7711 346.626L43.6997 349.875L43.7223 349.897C45.8903 348.025 47.7422 347.281 48.7811 348.16C49.5489 348.837 49.4134 349.852 48.7585 350.619C48.1487 351.319 47.4712 351.567 46.9744 351.161C46.613 350.845 46.4549 350.281 46.9744 349.672C47.3357 349.266 47.7874 349.198 48.0584 349.176C48.239 349.153 48.352 349.13 48.4423 349.04C48.5778 348.882 48.4875 348.702 48.2842 348.521C47.1776 347.574 44.2191 349.627 42.706 351.364L40.4476 353.958C39.6572 354.883 39.5894 355.222 40.267 355.808L40.6057 356.101L40.3799 356.372L36.902 353.372L37.1504 353.078Z"
                fill="#231F20"
              />
              <path
                d="M43.3835 358.493L43.4965 358.56C44.2192 359.079 44.5805 358.966 45.2806 357.974L49.6618 351.792C50.3619 350.8 50.3845 350.416 49.6393 349.897L49.5263 349.807L49.707 349.536L52.3267 351.116L50.5652 353.417L50.5878 353.439C51.9428 352.221 53.4333 351.86 54.54 352.65C55.895 353.597 56.1434 355.018 54.6077 357.207L51.1298 362.102C50.4071 363.117 50.4297 363.456 51.062 363.907L51.1524 363.975L50.9491 364.268L47.5616 361.877L47.7648 361.583L47.9003 361.674C48.5552 362.147 48.9166 362.034 49.6167 361.019L53.5011 355.537C54.4722 354.161 54.2689 353.507 53.614 353.033C52.4397 352.199 50.7007 353.394 49.3908 355.244L46.7485 358.989C46.0484 359.982 46.0033 360.32 46.6808 360.816L46.8163 360.906L46.613 361.2L43.1577 358.763L43.3835 358.493Z"
                fill="#231F20"
              />
              <path
                d="M53.6817 363.072C54.8109 361.064 56.5498 360.478 60.3665 361.493L60.7956 360.703C62.0828 358.402 61.9248 357.523 60.8859 356.936C59.9374 356.417 58.8759 356.62 58.6727 356.936C58.6049 357.049 58.6953 357.139 58.8082 357.252C59.1695 357.568 59.3502 358.087 58.9889 358.696C58.6275 359.327 58.0178 359.44 57.5209 359.169C57.0015 358.876 56.8434 358.267 57.2499 357.523C57.9274 356.327 59.5309 355.695 61.4279 356.756C62.8507 357.545 63.8444 358.944 62.2861 361.719L59.5535 366.614C58.9889 367.607 58.9889 368.013 59.305 368.171C59.689 368.374 60.1406 367.855 60.6601 366.998L60.8859 367.133C59.9826 368.735 59.0115 368.983 58.2888 368.577C57.6113 368.193 57.0467 367.471 57.9048 365.824L57.8597 365.802C56.7757 366.817 55.6691 367.155 54.4495 366.479C53.3203 365.892 52.8009 364.629 53.6817 363.072ZM59.147 363.659L60.1632 361.831C57.408 361.11 56.3466 362.305 55.6013 363.659C54.9238 364.9 54.856 365.734 55.6239 366.163C56.3466 366.524 57.7693 366.14 59.147 363.659Z"
                fill="#231F20"
              />
              <path
                d="M61.0441 369.389L61.2247 369.479C62.1055 369.885 62.3991 369.66 62.9411 368.464L68.9936 355.221C69.5356 354.048 69.4678 353.665 68.4967 353.214L68.2935 353.123L68.429 352.808L71.6359 353.755L64.5671 369.208C64.0251 370.381 64.0477 370.742 64.9285 371.148L65.1092 371.239L64.9737 371.555L60.9312 369.705L61.0441 369.389Z"
                fill="#231F20"
              />
              <path
                d="M74.0749 374.284C73.7813 374.194 73.5329 374.397 73.2167 374.938L72.9457 374.871L74.233 370.449L74.5265 370.539C74.0975 373.179 74.9105 375.074 76.401 375.525C77.7786 375.931 78.6368 375.142 78.9304 374.126C79.2691 372.976 78.9078 372.276 77.2818 370.968C75.7687 369.705 74.8427 368.667 75.4525 366.569C76.0171 364.629 77.5076 363.907 78.9981 364.336C80.2177 364.696 80.4435 365.486 80.9629 365.644C81.2791 365.734 81.4824 365.576 81.7308 365.17L82.0018 365.238L80.85 369.186L80.5564 369.096C80.8952 366.885 80.3757 365.125 78.9078 364.696C77.9593 364.426 77.0108 364.9 76.6946 365.96C76.3558 367.133 76.7849 367.765 78.2755 369.028C79.9015 370.336 80.7371 371.487 80.1499 373.517C79.5853 375.435 78.0722 376.405 76.2655 375.886C74.8879 375.457 74.5265 374.42 74.0749 374.284Z"
                fill="#231F20"
              />
              <path
                d="M82.5438 373.788L83.6504 367.832C83.8762 366.637 83.7407 366.276 82.86 366.118L82.6793 366.073L82.747 365.757L85.841 366.118L84.3053 374.42C83.9892 376.157 84.1698 376.924 85.1409 377.104C86.5185 377.352 87.7155 375.412 88.122 373.179L88.935 368.847C89.1609 367.629 89.0253 367.268 88.1446 367.11L87.6929 367.02L87.7607 366.704L91.1031 367.11L89.3641 376.473C89.1383 377.668 89.3189 378.052 90.1997 378.232L90.3352 378.255L90.29 378.548L87.1961 378.39L87.851 375.503H87.8058C87.0606 377.127 85.7959 378.142 84.3731 377.871C82.7471 377.533 82.0695 376.337 82.5438 373.788Z"
                fill="#231F20"
              />
              <path
                d="M92.2097 378.683H92.3678C93.2034 378.706 93.4518 378.435 93.497 377.217L93.7906 369.637C93.8358 368.419 93.6325 368.08 92.7969 368.058H92.6162L92.6388 367.742L95.7102 367.652L95.4844 370.539H95.507C95.9586 368.644 97.0427 367.674 98.2622 367.719C99.9334 367.787 100.746 368.893 100.792 370.765C101.108 368.847 102.305 367.877 103.615 367.922C105.263 367.99 106.144 369.096 106.054 371.735L105.828 377.713C105.783 378.931 105.963 379.225 106.821 379.27H106.912L106.889 379.631L102.756 379.473L102.779 379.112H102.937C103.773 379.135 103.976 378.864 104.021 377.646L104.269 371.081C104.337 369.344 103.886 368.825 103.073 368.78C101.605 368.735 100.837 370.72 100.724 373.156L100.566 377.51C100.521 378.728 100.633 379.022 101.424 379.067H101.605L101.627 379.383L97.5395 379.225L97.5621 378.864H97.6976C98.5332 378.886 98.7364 378.616 98.7816 377.397L99.03 371.036C99.0978 369.141 98.6009 368.554 97.7653 368.532C96.3651 368.486 95.5973 370.517 95.507 372.75L95.3263 377.262C95.2811 378.48 95.4618 378.774 96.2522 378.819H96.4329L96.4103 379.18L92.1871 379.022L92.2097 378.683Z"
                fill="#231F20"
              />
              <path
                d="M107.567 379.022H107.725C108.56 379.022 108.809 378.728 108.809 377.51V369.93C108.809 368.712 108.583 368.396 107.747 368.396H107.567V368.08L110.638 367.855L110.525 370.742H110.548C110.932 368.825 111.993 367.832 113.213 367.832C114.884 367.832 115.742 368.915 115.855 370.788C116.103 368.87 117.255 367.832 118.565 367.832C120.214 367.832 121.14 368.915 121.14 371.532V377.51C121.14 378.728 121.32 379.022 122.201 379.022H122.291V379.383H118.158V379.022H118.317C119.152 379.022 119.355 378.728 119.355 377.51V370.923C119.355 369.186 118.904 368.667 118.091 368.667C116.623 368.667 115.923 370.697 115.923 373.134V377.488C115.923 378.706 116.058 378.999 116.849 378.999H117.029V379.36H112.942V378.999H113.077C113.913 378.999 114.116 378.706 114.116 377.488V371.126C114.116 369.231 113.597 368.667 112.761 368.667C111.361 368.667 110.683 370.72 110.683 372.976V377.488C110.683 378.706 110.864 378.999 111.654 378.999H111.835V379.36H107.612V379.022H107.567Z"
                fill="#231F20"
              />
              <path
                d="M122.833 373.788C122.833 370.111 124.753 367.674 126.786 367.674C128.412 367.674 130.286 369.344 130.286 372.795C130.286 373.043 130.263 373.472 130.241 373.607H124.889C124.866 376.698 125.295 379.067 127.215 379.067C128.57 379.067 129.405 378.007 129.925 376.608L130.241 376.766C129.676 378.435 128.637 379.766 126.876 379.766C124.663 379.766 122.833 377.555 122.833 373.788ZM127.282 373.156C128.141 373.156 128.344 372.886 128.344 371.961C128.344 369.84 128.05 367.99 126.74 367.99C125.385 367.99 124.956 370.066 124.889 373.156H127.282Z"
                fill="#231F20"
              />
              <path
                d="M130.805 379.022H130.941C131.844 379.022 132.048 378.728 132.048 377.51V369.93C132.048 368.712 131.822 368.396 130.941 368.396H130.805V368.08L133.877 367.855L133.696 372.322H133.719C134.125 369.479 135.051 367.719 136.406 367.719C137.445 367.719 138.01 368.577 138.01 369.592C138.01 370.517 137.648 371.171 137.016 371.171C136.519 371.171 136.045 370.855 136.045 370.043C136.045 369.502 136.338 369.141 136.542 368.96C136.655 368.825 136.722 368.735 136.722 368.599C136.722 368.374 136.542 368.306 136.271 368.306C134.803 368.306 133.922 371.803 133.922 374.104V377.533C133.922 378.751 134.103 379.044 135.006 379.044H135.458V379.405H130.851V379.022H130.805Z"
                fill="#231F20"
              />
              <path
                d="M144.04 373.698C144.04 370.066 146.027 367.674 148.059 367.674C150.115 367.674 152.079 370.066 152.079 373.653C152.079 377.194 150.16 379.766 148.059 379.766C145.959 379.766 144.04 377.217 144.04 373.698ZM150.002 373.72C150.002 370.359 149.595 368.013 148.059 368.013C146.479 368.013 146.117 370.314 146.117 373.675C146.117 376.946 146.479 379.383 148.105 379.383C149.731 379.383 150.002 376.991 150.002 373.72Z"
                fill="#231F20"
              />
              <path
                d="M152.847 379.022H153.005C153.909 379.022 154.089 378.728 154.089 377.533V368.464H152.847V368.058H154.089V366.073C154.089 362.824 155.535 360.478 157.658 360.478C158.877 360.478 159.532 361.425 159.532 362.486C159.532 363.298 159.08 363.704 158.629 363.704C158.109 363.704 157.77 363.388 157.77 362.711C157.77 362.26 157.951 361.899 158.177 361.719C158.335 361.583 158.425 361.538 158.425 361.425C158.425 361.132 158.132 360.861 157.635 360.861C156.325 360.861 155.896 362.869 155.896 365.554V368.103H157.974V368.509H155.896V377.578C155.896 378.774 156.122 379.067 157.003 379.067H157.387V379.428H152.825V379.022H152.847Z"
                fill="#231F20"
              />
              <path
                d="M165.381 370.201C165.065 369.005 164.794 368.441 163.913 368.396V368.08H168.114V368.396C167.436 368.441 167.143 368.689 167.143 369.118C167.143 369.479 167.233 370.02 167.391 370.562L169.062 376.518L170.553 371.758L170.056 369.885C169.83 369.05 169.627 368.441 168.882 368.396V368.08H172.834V368.396C172.111 368.441 171.795 368.689 171.795 369.118C171.795 369.479 171.908 369.953 172.066 370.584L173.511 376.292L174.866 371.261C175.047 370.539 175.183 369.817 175.183 369.321C175.183 368.667 174.866 368.464 174.144 368.396V368.08H177.012V368.396C176.199 368.486 175.928 369.118 175.634 370.201L172.947 379.744H172.54L170.688 372.502L168.498 379.744H168.091L165.381 370.201Z"
                fill="#231F20"
              />
              <path
                d="M176.56 379.022H176.718C177.622 379.022 177.802 378.728 177.802 377.51V362.801C177.802 361.493 177.576 361.222 176.696 361.222H176.515V360.861L179.609 360.41C179.609 360.41 179.609 367.381 179.609 368.419L179.519 370.742H179.541C179.948 368.96 180.941 367.832 182.342 367.832C183.99 367.832 185.007 368.825 185.007 371.509V377.51C185.007 378.751 185.255 379.022 186 379.022H186.136V379.383H181.958V379.022H182.138C182.951 379.022 183.177 378.728 183.177 377.51V370.81C183.177 369.141 182.613 368.689 181.822 368.689C180.377 368.689 179.632 370.675 179.632 372.931V377.51C179.632 378.728 179.812 379.022 180.625 379.022H180.783V379.383H176.56V379.022Z"
                fill="#231F20"
              />
              <path
                d="M186.362 379.022H186.587C187.491 379.022 187.671 378.728 187.671 377.533V369.93C187.671 368.667 187.513 368.351 186.587 368.351H186.362V368.058L189.478 367.832V377.51C189.478 378.706 189.636 378.999 190.562 378.999H190.788V379.36H186.384V379.022H186.362ZM187.017 363.388C187.017 362.666 187.581 362.057 188.349 362.057C189.049 362.057 189.659 362.644 189.659 363.388C189.659 364.11 189.072 364.719 188.349 364.719C187.604 364.719 187.017 364.11 187.017 363.388Z"
                fill="#231F20"
              />
              <path
                d="M191.285 373.788C191.285 370.156 193.182 367.674 195.576 367.674C197.382 367.674 198.376 369.141 198.376 370.697C198.376 371.735 197.857 372.186 197.337 372.186C196.818 372.186 196.344 371.825 196.344 371.013C196.344 370.291 196.75 369.84 197.247 369.705C197.473 369.614 197.676 369.66 197.631 369.434C197.495 368.802 196.84 368.058 195.689 368.058C193.837 368.058 193.385 370.201 193.385 373.63C193.385 377.059 193.95 379.022 195.734 379.022C196.84 379.022 197.608 378.052 198.15 376.721L198.444 376.879C197.857 378.525 196.84 379.744 195.237 379.744C193.024 379.766 191.285 377.465 191.285 373.788Z"
                fill="#231F20"
              />
              <path
                d="M198.241 379.022H198.399C199.302 379.022 199.483 378.728 199.483 377.51V362.801C199.483 361.493 199.257 361.222 198.376 361.222H198.196V360.861L201.29 360.41C201.29 360.41 201.29 367.381 201.29 368.419L201.199 370.742H201.222C201.628 368.96 202.622 367.832 204.022 367.832C205.671 367.832 206.687 368.825 206.687 371.509V377.51C206.687 378.751 206.935 379.022 207.681 379.022H207.816V379.383H203.638V379.022H203.819C204.632 379.022 204.858 378.728 204.858 377.51V370.81C204.858 369.141 204.293 368.689 203.503 368.689C202.057 368.689 201.312 370.675 201.312 372.931V377.51C201.312 378.728 201.493 379.022 202.306 379.022H202.464V379.383H198.241V379.022Z"
                fill="#231F20"
              />
              <path
                d="M215.495 378.751C215.201 378.751 214.998 379.022 214.84 379.608H214.569V375.006H214.862C215.178 377.646 216.511 379.247 218.069 379.247C219.515 379.247 220.102 378.255 220.102 377.194C220.102 375.999 219.56 375.435 217.64 374.623C215.833 373.833 214.659 373.111 214.659 370.923C214.659 368.893 215.879 367.787 217.414 367.787C218.679 367.787 219.131 368.486 219.673 368.486C219.989 368.486 220.17 368.261 220.26 367.81H220.531V371.916H220.237C219.944 369.705 218.927 368.148 217.414 368.148C216.443 368.148 215.653 368.87 215.653 369.998C215.653 371.216 216.24 371.69 218.047 372.479C219.966 373.292 221.095 374.149 221.095 376.269C221.095 378.277 219.899 379.631 218.047 379.631C216.601 379.653 215.969 378.751 215.495 378.751Z"
                fill="#231F20"
              />
              <path
                d="M222.834 376.27V368.487H221.457V368.171C222.902 367.855 224.122 366.682 224.302 363.185H224.686V368.08H227.238V368.487H224.686V376.382C224.686 378.165 224.957 378.932 225.748 378.932C226.606 378.932 227.171 377.736 227.216 375.367H227.6C227.6 378.322 226.741 379.744 225.273 379.744C223.715 379.721 222.834 378.616 222.834 376.27Z"
                fill="#231F20"
              />
              <path
                d="M228.164 373.698C228.164 370.066 230.152 367.674 232.184 367.674C234.239 367.674 236.204 370.066 236.204 373.653C236.204 377.194 234.284 379.766 232.184 379.766C230.084 379.766 228.164 377.217 228.164 373.698ZM234.104 373.72C234.104 370.359 233.697 368.013 232.162 368.013C230.581 368.013 230.219 370.314 230.219 373.675C230.219 376.946 230.581 379.383 232.207 379.383C233.833 379.383 234.104 376.991 234.104 373.72Z"
                fill="#231F20"
              />
              <path
                d="M236.678 379.022H236.814C237.717 379.022 237.92 378.728 237.92 377.51V369.93C237.92 368.712 237.694 368.396 236.814 368.396H236.678V368.08L239.75 367.855L239.569 372.322H239.592C239.998 369.479 240.924 367.719 242.279 367.719C243.318 367.719 243.882 368.577 243.882 369.592C243.882 370.517 243.521 371.171 242.889 371.171C242.392 371.171 241.918 370.855 241.918 370.043C241.918 369.502 242.211 369.141 242.414 368.96C242.527 368.825 242.595 368.735 242.595 368.599C242.595 368.374 242.415 368.306 242.144 368.306C240.676 368.306 239.795 371.803 239.795 374.104V377.533C239.795 378.751 239.975 379.044 240.879 379.044H241.33V379.405H236.723V379.022H236.678Z"
                fill="#231F20"
              />
              <path
                d="M243.973 379.022H244.199C245.102 379.022 245.283 378.728 245.283 377.533V369.93C245.283 368.667 245.125 368.351 244.199 368.351H243.973V368.058L247.089 367.832V377.51C247.089 378.706 247.248 378.999 248.173 378.999H248.399V379.36H243.995V379.022H243.973ZM244.605 363.388C244.605 362.666 245.17 362.057 245.938 362.057C246.638 362.057 247.248 362.644 247.248 363.388C247.248 364.11 246.66 364.719 245.938 364.719C245.215 364.719 244.605 364.11 244.605 363.388Z"
                fill="#231F20"
              />
              <path
                d="M248.896 373.788C248.896 370.111 250.816 367.674 252.848 367.674C254.474 367.674 256.349 369.344 256.349 372.795C256.349 373.043 256.326 373.472 256.304 373.607H250.974C250.951 376.698 251.38 379.067 253.3 379.067C254.655 379.067 255.491 378.007 256.01 376.608L256.326 376.766C255.762 378.435 254.723 379.766 252.961 379.766C250.748 379.766 248.896 377.555 248.896 373.788ZM253.345 373.156C254.203 373.156 254.407 372.886 254.407 371.961C254.407 369.84 254.113 367.99 252.803 367.99C251.448 367.99 251.019 370.066 250.951 373.156H253.345Z"
                fill="#231F20"
              />
              <path
                d="M258.314 378.751C258.02 378.751 257.817 379.022 257.659 379.608H257.388V375.006H257.681C257.997 377.646 259.33 379.247 260.888 379.247C262.333 379.247 262.921 378.255 262.921 377.194C262.921 375.999 262.379 375.435 260.459 374.623C258.652 373.833 257.478 373.111 257.478 370.923C257.478 368.893 258.697 367.787 260.233 367.787C261.498 367.787 261.95 368.486 262.492 368.486C262.808 368.486 262.988 368.261 263.079 367.81H263.35V371.916H263.056C262.763 369.705 261.746 368.148 260.233 368.148C259.262 368.148 258.472 368.87 258.472 369.998C258.472 371.216 259.059 371.69 260.866 372.479C262.785 373.292 263.914 374.149 263.914 376.269C263.914 378.277 262.717 379.631 260.866 379.631C259.42 379.653 258.765 378.751 258.314 378.751Z"
                fill="#231F20"
              />
              <path
                d="M270.486 379.022H270.644C271.48 379.022 271.728 378.728 271.728 377.51V369.93C271.728 368.712 271.502 368.396 270.667 368.396H270.486V368.08L273.558 367.855L273.445 370.742H273.467C273.851 368.825 274.913 367.832 276.132 367.832C277.803 367.832 278.662 368.915 278.774 370.788C279.023 368.87 280.175 367.832 281.485 367.832C283.133 367.832 284.059 368.915 284.059 371.532V377.51C284.059 378.728 284.24 379.022 285.121 379.022H285.211V379.383H281.078V379.022H281.236C282.072 379.022 282.275 378.728 282.275 377.51V370.923C282.275 369.186 281.823 368.667 281.01 368.667C279.542 368.667 278.842 370.697 278.842 373.134V377.488C278.842 378.706 278.978 378.999 279.768 378.999H279.949V379.36H275.861V378.999H275.997C276.832 378.999 277.036 378.706 277.036 377.488V371.126C277.036 369.231 276.516 368.667 275.68 368.667C274.28 368.667 273.603 370.72 273.603 372.976V377.488C273.603 378.706 273.783 378.999 274.574 378.999H274.755V379.36H270.531V379.022H270.486Z"
                fill="#231F20"
              />
              <path
                d="M285.708 376.969C285.708 374.668 286.95 373.314 290.767 372.344V371.442C290.767 368.825 290.202 368.103 289.005 368.103C287.921 368.103 287.108 368.802 287.085 369.186C287.085 369.321 287.198 369.344 287.356 369.411C287.808 369.524 288.215 369.863 288.215 370.585C288.215 371.306 287.718 371.712 287.153 371.712C286.566 371.712 286.114 371.261 286.114 370.404C286.114 369.028 287.221 367.697 289.389 367.697C291.015 367.697 292.551 368.419 292.551 371.622V377.217C292.551 378.345 292.731 378.706 293.093 378.706C293.522 378.706 293.657 378.029 293.725 377.037H293.996C293.996 378.886 293.273 379.563 292.415 379.563C291.625 379.563 290.789 379.202 290.744 377.352H290.699C290.247 378.751 289.457 379.608 288.056 379.608C286.747 379.586 285.708 378.751 285.708 376.969ZM290.767 374.803V372.728C288.011 373.427 287.673 375.006 287.673 376.54C287.673 377.939 288.034 378.706 288.915 378.706C289.705 378.683 290.767 377.646 290.767 374.803Z"
                fill="#231F20"
              />
              <path
                d="M293.431 383.173C293.431 382.248 293.928 381.774 294.47 381.774C294.967 381.774 295.328 382.203 295.328 382.834C295.328 383.353 295.103 383.669 294.899 383.872C294.764 383.985 294.651 384.098 294.651 384.233C294.651 384.481 294.832 384.549 295.193 384.549C296.119 384.549 296.796 383.353 297.519 380.962L297.79 380.105L294.764 370.291C294.38 369.073 294.109 368.532 293.273 368.441V368.125H297.632V368.441C296.819 368.532 296.571 368.87 296.548 369.299C296.548 369.592 296.638 370.133 296.796 370.675L298.716 377.059L300.342 371.374C300.523 370.742 300.749 369.998 300.749 369.479C300.749 368.78 300.387 368.509 299.642 368.419V368.103H302.668V368.419C301.765 368.509 301.584 369.141 301.223 370.269L297.948 380.962C297 384.052 296.164 385.045 295.08 385.045C293.951 385 293.431 384.075 293.431 383.173Z"
                fill="#231F20"
              />
              <path
                d="M306.733 382.925C306.733 382.067 307.185 381.616 307.682 381.616C308.201 381.616 308.517 382.022 308.517 382.721C308.517 383.082 308.359 383.376 308.156 383.556C307.953 383.737 307.795 383.827 307.795 384.007C307.795 384.391 308.111 384.571 308.517 384.571C309.195 384.571 309.895 384.21 309.895 381.052V369.908C309.895 368.644 309.714 368.329 308.811 368.329H308.517V368.035L311.724 367.81V380.375C311.724 383.082 310.369 384.887 308.608 384.887C307.591 384.932 306.733 384.165 306.733 382.925ZM309.24 363.388C309.24 362.666 309.827 362.057 310.573 362.057C311.295 362.057 311.905 362.644 311.905 363.388C311.905 364.11 311.318 364.719 310.573 364.719C309.827 364.719 309.24 364.11 309.24 363.388Z"
                fill="#231F20"
              />
              <path
                d="M314.366 375.999V369.93C314.366 368.712 314.186 368.396 313.282 368.396H313.102V368.08L316.218 367.855V376.292C316.218 378.052 316.535 378.774 317.528 378.774C318.928 378.774 319.741 376.63 319.741 374.352V369.93C319.741 368.69 319.561 368.351 318.657 368.351H318.206V368.035L321.571 367.81V377.33C321.571 378.548 321.819 378.909 322.7 378.909H322.835V379.202L319.764 379.608L319.877 376.653H319.832C319.403 378.39 318.341 379.608 316.873 379.608C315.225 379.653 314.366 378.593 314.366 375.999Z"
                fill="#231F20"
              />
              <path
                d="M324.552 378.751C324.258 378.751 324.055 379.022 323.897 379.608H323.626V375.006H323.92C324.236 377.646 325.568 379.247 327.126 379.247C328.572 379.247 329.159 378.255 329.159 377.194C329.159 375.999 328.617 375.435 326.697 374.623C324.891 373.833 323.716 373.111 323.716 370.923C323.716 368.893 324.936 367.787 326.472 367.787C327.736 367.787 328.188 368.486 328.73 368.486C329.046 368.486 329.227 368.261 329.317 367.81H329.588V371.916H329.294C329.001 369.705 327.985 368.148 326.472 368.148C325.5 368.148 324.71 368.87 324.71 369.998C324.71 371.216 325.297 371.69 327.104 372.479C329.023 373.292 330.175 374.149 330.175 376.269C330.175 378.277 328.978 379.631 327.104 379.631C325.659 379.653 325.004 378.751 324.552 378.751Z"
                fill="#231F20"
              />
              <path
                d="M331.892 376.27V368.487H330.514V368.171C331.959 367.855 333.179 366.682 333.36 363.185H333.743V368.08H336.295V368.487H333.743V376.382C333.743 378.165 334.014 378.932 334.805 378.932C335.663 378.932 336.228 377.736 336.273 375.367H336.657C336.657 378.322 335.799 379.744 334.331 379.744C332.795 379.721 331.892 378.616 331.892 376.27Z"
                fill="#231F20"
              />
              <path
                d="M343.635 374.781V362.734C343.635 361.493 343.5 361.155 342.551 361.155H342.19V360.861C343.025 360.771 345.442 360.433 345.442 360.433V367.9C345.442 368.329 345.329 370.81 345.329 370.81H345.374C345.735 369.141 346.548 367.742 347.971 367.742C349.913 367.742 351.449 370.178 351.449 373.653C351.449 376.856 349.823 379.631 347.745 379.631C346.368 379.631 345.555 378.548 345.058 377.104H345.035L343.771 379.698H343.387C343.59 378.187 343.635 376.427 343.635 374.781ZM349.417 373.743C349.417 370.855 349.168 368.351 347.655 368.351C346.526 368.351 345.397 370.291 345.397 373.698C345.397 376.969 346.119 379.225 347.565 379.225C349.146 379.247 349.417 376.585 349.417 373.743Z"
                fill="#231F20"
              />
              <path
                d="M352.398 373.788C352.398 370.111 354.317 367.674 356.35 367.674C357.976 367.674 359.85 369.344 359.85 372.795C359.85 373.043 359.828 373.472 359.805 373.607H354.475C354.453 376.698 354.882 379.067 356.802 379.067C358.157 379.067 358.992 378.007 359.512 376.608L359.828 376.766C359.263 378.435 358.224 379.766 356.463 379.766C354.25 379.766 352.398 377.555 352.398 373.788ZM356.847 373.156C357.705 373.156 357.908 372.886 357.908 371.961C357.908 369.84 357.615 367.99 356.305 367.99C354.95 367.99 354.521 370.066 354.453 373.156H356.847Z"
                fill="#231F20"
              />
              <path
                d="M360.596 373.788C360.596 370.156 362.493 367.674 364.887 367.674C366.693 367.674 367.687 369.141 367.687 370.697C367.687 371.735 367.168 372.186 366.648 372.186C366.129 372.186 365.654 371.825 365.654 371.013C365.654 370.291 366.061 369.84 366.558 369.705C366.784 369.614 366.987 369.66 366.942 369.434C366.806 368.802 366.151 368.058 364.999 368.058C363.148 368.058 362.696 370.201 362.696 373.63C362.696 377.059 363.26 379.022 365.045 379.022C366.151 379.022 366.919 378.052 367.461 376.721L367.755 376.879C367.167 378.525 366.151 379.744 364.548 379.744C362.335 379.766 360.596 377.465 360.596 373.788Z"
                fill="#231F20"
              />
              <path
                d="M368.139 373.698C368.139 370.066 370.126 367.674 372.159 367.674C374.214 367.674 376.179 370.066 376.179 373.653C376.179 377.194 374.259 379.766 372.159 379.766C370.058 379.766 368.139 377.217 368.139 373.698ZM374.101 373.72C374.101 370.359 373.694 368.013 372.159 368.013C370.578 368.013 370.216 370.314 370.216 373.675C370.216 376.946 370.578 379.383 372.204 379.383C373.83 379.383 374.101 376.991 374.101 373.72Z"
                fill="#231F20"
              />
              <path
                d="M376.675 379.022H376.833C377.669 379.022 377.917 378.728 377.917 377.51V369.93C377.917 368.712 377.692 368.396 376.856 368.396H376.675V368.08L379.747 367.855L379.634 370.742H379.656C380.04 368.825 381.102 367.832 382.321 367.832C383.992 367.832 384.851 368.915 384.964 370.788C385.212 368.87 386.364 367.832 387.674 367.832C389.322 367.832 390.248 368.915 390.248 371.532V377.51C390.248 378.728 390.429 379.022 391.31 379.022H391.4V379.383H387.267V379.022H387.425C388.261 379.022 388.464 378.728 388.464 377.51V370.923C388.464 369.186 388.012 368.667 387.199 368.667C385.731 368.667 385.031 370.697 385.031 373.134V377.488C385.031 378.706 385.167 378.999 385.957 378.999H386.138V379.36H382.05V378.999H382.186C383.021 378.999 383.225 378.706 383.225 377.488V371.126C383.225 369.231 382.705 368.667 381.87 368.667C380.469 368.667 379.792 370.72 379.792 372.976V377.488C379.792 378.706 379.973 378.999 380.763 378.999H380.944V379.36H376.72V379.022H376.675Z"
                fill="#231F20"
              />
              <path
                d="M391.852 373.788C391.852 370.111 393.771 367.674 395.804 367.674C397.43 367.674 399.304 369.344 399.304 372.795C399.304 373.043 399.282 373.472 399.259 373.607H393.929C393.907 376.698 394.336 379.067 396.255 379.067C397.61 379.067 398.446 378.007 398.965 376.608L399.282 376.766C398.717 378.435 397.678 379.766 395.917 379.766C393.703 379.766 391.852 377.555 391.852 373.788ZM396.301 373.156C397.159 373.156 397.362 372.886 397.362 371.961C397.362 369.84 397.068 367.99 395.759 367.99C394.404 367.99 393.974 370.066 393.907 373.156H396.301Z"
                fill="#231F20"
              />
              <path
                d="M406.915 376.27V368.487H405.537V368.171C406.983 367.855 408.202 366.682 408.383 363.185H408.767V368.08H411.319V368.487H408.767V376.382C408.767 378.165 409.038 378.932 409.828 378.932C410.686 378.932 411.251 377.736 411.296 375.367H411.68C411.68 378.322 410.822 379.744 409.354 379.744C407.818 379.721 406.915 378.616 406.915 376.27Z"
                fill="#231F20"
              />
              <path
                d="M411.658 379.022H411.816C412.719 379.022 412.9 378.728 412.9 377.51V362.801C412.9 361.493 412.674 361.222 411.793 361.222H411.612V360.861L414.706 360.41C414.706 360.41 414.706 367.381 414.706 368.419L414.616 370.742H414.639C415.045 368.96 416.039 367.832 417.439 367.832C419.088 367.832 420.104 368.825 420.104 371.509V377.51C420.104 378.751 420.352 379.022 421.098 379.022H421.233V379.383H417.055V379.022H417.236C418.049 379.022 418.275 378.728 418.275 377.51V370.81C418.275 369.141 417.71 368.689 416.92 368.689C415.474 368.689 414.729 370.675 414.729 372.931V377.51C414.729 378.728 414.91 379.022 415.723 379.022H415.881V379.383H411.658V379.022Z"
                fill="#231F20"
              />
              <path
                d="M421.82 373.788C421.82 370.111 423.74 367.674 425.772 367.674C427.399 367.674 429.273 369.344 429.273 372.795C429.273 373.043 429.25 373.472 429.228 373.607H423.898C423.875 376.698 424.305 379.067 426.224 379.067C427.579 379.067 428.415 378.007 428.934 376.608L429.25 376.766C428.686 378.435 427.647 379.766 425.885 379.766C423.65 379.766 421.82 377.555 421.82 373.788ZM426.269 373.156C427.128 373.156 427.331 372.886 427.331 371.961C427.331 369.84 427.037 367.99 425.727 367.99C424.372 367.99 423.943 370.066 423.875 373.156H426.269Z"
                fill="#231F20"
              />
              <path
                d="M435.642 379.022H435.8C436.635 379.022 436.884 378.728 436.884 377.51V369.93C436.884 368.712 436.658 368.396 435.822 368.396H435.642V368.08L438.713 367.855L438.6 370.742H438.623C439.007 368.825 440.068 367.832 441.288 367.832C442.959 367.832 443.817 368.915 443.93 370.788C444.178 368.87 445.33 367.832 446.64 367.832C448.289 367.832 449.214 368.915 449.214 371.532V377.51C449.214 378.728 449.395 379.022 450.276 379.022H450.366V379.383H446.233V379.022H446.392C447.227 379.022 447.43 378.728 447.43 377.51V370.923C447.43 369.186 446.979 368.667 446.166 368.667C444.698 368.667 443.998 370.697 443.998 373.134V377.488C443.998 378.706 444.133 378.999 444.924 378.999H445.104V379.36H441.017V378.999H441.152C441.988 378.999 442.191 378.706 442.191 377.488V371.126C442.191 369.231 441.671 368.667 440.836 368.667C439.436 368.667 438.758 370.72 438.758 372.976V377.488C438.758 378.706 438.939 378.999 439.729 378.999H439.91V379.36H435.687V379.022H435.642Z"
                fill="#231F20"
              />
              <path
                d="M450.841 373.698C450.841 370.066 452.828 367.674 454.86 367.674C456.916 367.674 458.88 370.066 458.88 373.653C458.88 377.194 456.961 379.766 454.86 379.766C452.76 379.766 450.841 377.217 450.841 373.698ZM456.78 373.72C456.78 370.359 456.374 368.013 454.838 368.013C453.257 368.013 452.896 370.314 452.896 373.675C452.896 376.946 453.257 379.383 454.883 379.383C456.509 379.383 456.78 376.991 456.78 373.72Z"
                fill="#231F20"
              />
              <path
                d="M460.845 378.751C460.552 378.751 460.348 379.022 460.19 379.608H459.919V375.006H460.213C460.529 377.646 461.861 379.247 463.42 379.247C464.865 379.247 465.452 378.255 465.452 377.194C465.452 375.999 464.91 375.435 462.991 374.623C461.184 373.833 460.01 373.111 460.01 370.923C460.01 368.893 461.229 367.787 462.765 367.787C464.029 367.787 464.481 368.486 465.023 368.486C465.339 368.486 465.52 368.261 465.61 367.81H465.881V371.916H465.588C465.294 369.705 464.278 368.148 462.765 368.148C461.794 368.148 461.003 368.87 461.003 369.998C461.003 371.216 461.59 371.69 463.397 372.479C465.317 373.292 466.469 374.149 466.469 376.269C466.469 378.277 465.272 379.631 463.397 379.631C461.974 379.653 461.319 378.751 460.845 378.751Z"
                fill="#231F20"
              />
              <path
                d="M468.14 376.27V368.487H466.762V368.171C468.207 367.855 469.427 366.682 469.608 363.185H469.991V368.08H472.543V368.487H469.991V376.382C469.991 378.165 470.262 378.932 471.053 378.932C471.911 378.932 472.476 377.736 472.521 375.367H472.905C472.905 378.322 472.047 379.744 470.579 379.744C469.043 379.721 468.14 378.616 468.14 376.27Z"
                fill="#231F20"
              />
              <path
                d="M479.319 373.788C479.319 370.111 481.238 367.674 483.271 367.674C484.897 367.674 486.771 369.344 486.771 372.795C486.771 373.043 486.749 373.472 486.726 373.607H481.396C481.374 376.698 481.803 379.067 483.722 379.067C485.077 379.067 485.913 378.007 486.433 376.608L486.749 376.766C486.184 378.435 485.145 379.766 483.384 379.766C481.17 379.766 479.319 377.555 479.319 373.788ZM483.768 373.156C484.626 373.156 484.829 372.886 484.829 371.961C484.829 369.84 484.535 367.99 483.226 367.99C481.871 367.99 481.441 370.066 481.374 373.156H483.768Z"
                fill="#231F20"
              />
              <path
                d="M487.358 379.022H487.539C488.51 379.022 488.668 378.706 488.668 377.397V362.847C488.668 361.538 488.442 361.222 487.358 361.222H487.133V360.861L490.43 360.41V377.397C490.43 378.706 490.588 379.022 491.559 379.022H491.74V379.383H487.291V379.022H487.358Z"
                fill="#231F20"
              />
              <path
                d="M492.35 373.788C492.35 370.111 494.269 367.674 496.302 367.674C497.928 367.674 499.802 369.344 499.802 372.795C499.802 373.043 499.78 373.472 499.757 373.607H494.427C494.405 376.698 494.834 379.067 496.753 379.067C498.109 379.067 498.944 378.007 499.464 376.608L499.78 376.766C499.215 378.435 498.176 379.766 496.415 379.766C494.201 379.766 492.35 377.555 492.35 373.788ZM496.799 373.156C497.657 373.156 497.86 372.886 497.86 371.961C497.86 369.84 497.567 367.99 496.257 367.99C494.902 367.99 494.473 370.066 494.405 373.156H496.799Z"
                fill="#231F20"
              />
              <path
                d="M500.66 373.788C500.66 370.156 502.557 367.674 504.951 367.674C506.758 367.674 507.752 369.141 507.752 370.697C507.752 371.735 507.232 372.186 506.713 372.186C506.193 372.186 505.719 371.825 505.719 371.013C505.719 370.291 506.126 369.84 506.623 369.705C506.848 369.614 507.052 369.66 507.006 369.434C506.871 368.802 506.216 368.058 505.064 368.058C503.212 368.058 502.761 370.201 502.761 373.63C502.761 377.059 503.325 379.022 505.109 379.022C506.216 379.022 506.984 378.052 507.526 376.721L507.819 376.879C507.232 378.525 506.216 379.744 504.613 379.744C502.377 379.766 500.66 377.465 500.66 373.788Z"
                fill="#231F20"
              />
              <path
                d="M509.084 376.27V368.487H507.707V368.171C509.152 367.855 510.371 366.682 510.552 363.185H510.936V368.08H513.488V368.487H510.936V376.382C510.936 378.165 511.207 378.932 511.998 378.932C512.856 378.932 513.42 377.736 513.465 375.367H513.849C513.849 378.322 512.991 379.744 511.523 379.744C509.988 379.721 509.084 378.616 509.084 376.27Z"
                fill="#231F20"
              />
              <path
                d="M514.12 379.022H514.256C515.159 379.022 515.362 378.728 515.362 377.51V369.93C515.362 368.712 515.137 368.396 514.256 368.396H514.12V368.08L517.192 367.855L517.011 372.322H517.034C517.44 369.479 518.366 367.719 519.721 367.719C520.76 367.719 521.325 368.577 521.325 369.592C521.325 370.517 520.963 371.171 520.331 371.171C519.834 371.171 519.36 370.855 519.36 370.043C519.36 369.502 519.653 369.141 519.857 368.96C519.97 368.825 520.037 368.735 520.037 368.599C520.037 368.374 519.857 368.306 519.586 368.306C518.118 368.306 517.237 371.803 517.237 374.104V377.533C517.237 378.751 517.418 379.044 518.321 379.044H518.773V379.405H514.166V379.022H514.12Z"
                fill="#231F20"
              />
              <path
                d="M521.37 379.022H521.595C522.499 379.022 522.679 378.728 522.679 377.533V369.93C522.679 368.667 522.521 368.351 521.595 368.351H521.37V368.058L524.486 367.832V377.51C524.486 378.706 524.644 378.999 525.57 378.999H525.796V379.36H521.392V379.022H521.37ZM522.025 363.388C522.025 362.666 522.589 362.057 523.357 362.057C524.057 362.057 524.667 362.644 524.667 363.388C524.667 364.11 524.08 364.719 523.357 364.719C522.612 364.719 522.025 364.11 522.025 363.388Z"
                fill="#231F20"
              />
              <path
                d="M526.271 373.788C526.271 370.156 528.168 367.674 530.561 367.674C532.368 367.674 533.362 369.141 533.362 370.697C533.362 371.735 532.842 372.186 532.323 372.186C531.804 372.186 531.329 371.825 531.329 371.013C531.329 370.291 531.736 369.84 532.233 369.705C532.458 369.614 532.662 369.66 532.617 369.434C532.481 368.802 531.826 368.058 530.674 368.058C528.822 368.058 528.371 370.201 528.371 373.63C528.371 377.059 528.935 379.022 530.72 379.022C531.826 379.022 532.594 378.052 533.136 376.721L533.43 376.879C532.842 378.525 531.826 379.744 530.223 379.744C528.009 379.766 526.271 377.465 526.271 373.788Z"
                fill="#231F20"
              />
              <path
                d="M539.73 373.788C539.73 370.156 541.628 367.674 544.021 367.674C545.828 367.674 546.822 369.141 546.822 370.697C546.822 371.735 546.302 372.186 545.783 372.186C545.264 372.186 544.789 371.825 544.789 371.013C544.789 370.291 545.196 369.84 545.693 369.705C545.918 369.614 546.122 369.66 546.077 369.434C545.941 368.802 545.286 368.058 544.134 368.058C542.282 368.058 541.831 370.201 541.831 373.63C541.831 377.059 542.395 379.022 544.18 379.022C545.286 379.022 546.054 378.052 546.596 376.721L546.89 376.879C546.302 378.525 545.286 379.744 543.683 379.744C541.447 379.766 539.73 377.465 539.73 373.788Z"
                fill="#231F20"
              />
              <path
                d="M546.731 379.022H546.89C547.793 379.022 547.974 378.728 547.974 377.51V362.801C547.974 361.493 547.748 361.222 546.867 361.222H546.686V360.861L549.78 360.41C549.78 360.41 549.78 367.381 549.78 368.419L549.69 370.742H549.712C550.119 368.96 551.113 367.832 552.513 367.832C554.161 367.832 555.178 368.825 555.178 371.509V377.51C555.178 378.751 555.426 379.022 556.171 379.022H556.307V379.383H552.129V379.022H552.31C553.123 379.022 553.348 378.728 553.348 377.51V370.81C553.348 369.141 552.784 368.689 551.993 368.689C550.548 368.689 549.803 370.675 549.803 372.931V377.51C549.803 378.728 549.983 379.022 550.797 379.022H550.955V379.383H546.731V379.022Z"
                fill="#231F20"
              />
              <path
                d="M556.849 376.969C556.849 374.668 558.091 373.314 561.908 372.344V371.442C561.908 368.825 561.343 368.103 560.146 368.103C559.062 368.103 558.249 368.802 558.226 369.186C558.226 369.321 558.339 369.344 558.498 369.411C558.949 369.524 559.356 369.863 559.356 370.585C559.356 371.306 558.859 371.712 558.294 371.712C557.707 371.712 557.255 371.261 557.255 370.404C557.255 369.028 558.362 367.697 560.53 367.697C562.156 367.697 563.692 368.419 563.692 371.622V377.217C563.692 378.345 563.872 378.706 564.234 378.706C564.663 378.706 564.798 378.029 564.866 377.037H565.137C565.137 378.886 564.414 379.563 563.556 379.563C562.766 379.563 561.93 379.202 561.885 377.352H561.84C561.388 378.751 560.598 379.608 559.198 379.608C557.91 379.586 556.849 378.751 556.849 376.969ZM561.908 374.803V372.728C559.152 373.427 558.814 375.006 558.814 376.54C558.814 377.939 559.175 378.706 560.056 378.706C560.869 378.683 561.908 377.646 561.908 374.803Z"
                fill="#231F20"
              />
              <path
                d="M565.047 384.549H565.205C566.108 384.549 566.289 384.256 566.289 383.015V369.953C566.289 368.712 566.108 368.419 565.205 368.419H565.047V368.103L568.118 367.877L568.005 370.833H568.051C568.434 369.073 569.315 367.81 570.625 367.81C572.748 367.81 574.126 370.404 574.126 373.743C574.126 376.879 572.545 379.699 570.557 379.699C569.022 379.699 568.434 378.503 568.118 377.262H568.073C568.073 377.262 568.118 379.744 568.118 380.217V383.06C568.118 384.323 568.367 384.594 569.112 384.594H569.541V384.955H565.047V384.549ZM572.048 373.743C572.048 370.788 571.799 368.351 570.309 368.351C569.135 368.351 568.051 370.381 568.051 373.788C568.051 377.037 568.886 379.157 570.219 379.157C571.732 379.157 572.048 376.788 572.048 373.743Z"
                fill="#231F20"
              />
              <path
                d="M575.842 376.27V368.487H574.464V368.171C575.91 367.855 577.129 366.682 577.31 363.185H577.694V368.08H580.246V368.487H577.694V376.382C577.694 378.165 577.965 378.932 578.755 378.932C579.613 378.932 580.178 377.736 580.223 375.367H580.607C580.607 378.322 579.749 379.744 578.281 379.744C576.723 379.721 575.842 378.616 575.842 376.27Z"
                fill="#231F20"
              />
              <path
                d="M581.217 373.788C581.217 370.111 583.136 367.674 585.169 367.674C586.795 367.674 588.669 369.344 588.669 372.795C588.669 373.043 588.647 373.472 588.624 373.607H583.295C583.272 376.698 583.701 379.067 585.621 379.067C586.976 379.067 587.811 378.007 588.331 376.608L588.647 376.766C588.082 378.435 587.043 379.766 585.282 379.766C583.069 379.766 581.217 377.555 581.217 373.788ZM585.666 373.156C586.524 373.156 586.727 372.886 586.727 371.961C586.727 369.84 586.434 367.99 585.124 367.99C583.769 367.99 583.34 370.066 583.272 373.156H585.666Z"
                fill="#231F20"
              />
              <path
                d="M589.144 379.022H589.279C590.183 379.022 590.386 378.728 590.386 377.51V369.93C590.386 368.712 590.16 368.396 589.279 368.396H589.144V368.08L592.215 367.855L592.035 372.322H592.057C592.464 369.479 593.39 367.719 594.745 367.719C595.783 367.719 596.348 368.577 596.348 369.592C596.348 370.517 595.987 371.171 595.354 371.171C594.857 371.171 594.383 370.855 594.383 370.043C594.383 369.502 594.677 369.141 594.88 368.96C594.993 368.825 595.061 368.735 595.061 368.599C595.061 368.374 594.88 368.306 594.609 368.306C593.141 368.306 592.26 371.803 592.26 374.104V377.533C592.26 378.751 592.441 379.044 593.344 379.044H593.796V379.405H589.189V379.022H589.144Z"
                fill="#231F20"
              />
              <path
                d="M602.423 373.698C602.423 370.066 604.41 367.674 606.443 367.674C608.498 367.674 610.463 370.066 610.463 373.653C610.463 377.194 608.543 379.766 606.443 379.766C604.343 379.766 602.423 377.217 602.423 373.698ZM608.385 373.72C608.385 370.359 607.979 368.013 606.443 368.013C604.862 368.013 604.501 370.314 604.501 373.675C604.501 376.946 604.862 379.383 606.488 379.383C608.114 379.383 608.385 376.991 608.385 373.72Z"
                fill="#231F20"
              />
              <path
                d="M611.231 379.022H611.389C612.292 379.022 612.473 378.728 612.473 377.533V368.464H611.231V368.058H612.473V366.073C612.473 362.824 613.918 360.478 616.041 360.478C617.26 360.478 617.915 361.425 617.915 362.486C617.915 363.298 617.464 363.704 617.012 363.704C616.493 363.704 616.154 363.388 616.154 362.711C616.154 362.26 616.335 361.899 616.56 361.719C616.718 361.583 616.809 361.538 616.809 361.425C616.809 361.132 616.515 360.861 616.018 360.861C614.708 360.861 614.279 362.869 614.279 365.554V368.103H616.357V368.509H614.279V377.578C614.279 378.774 614.505 379.067 615.386 379.067H615.77V379.428H611.208V379.022H611.231Z"
                fill="#231F20"
              />
              <path
                d="M622.613 379.022H622.771C623.607 379.022 623.855 378.728 623.855 377.51V369.93C623.855 368.712 623.629 368.396 622.793 368.396H622.613V368.08L625.684 367.855L625.571 370.742H625.594C625.978 368.825 627.039 367.832 628.259 367.832C629.93 367.832 630.788 368.915 630.901 370.788C631.149 368.87 632.301 367.832 633.611 367.832C635.26 367.832 636.186 368.915 636.186 371.532V377.51C636.186 378.728 636.366 379.022 637.247 379.022H637.337V379.383H633.205V379.022H633.363C634.198 379.022 634.402 378.728 634.402 377.51V370.923C634.402 369.186 633.95 368.667 633.137 368.667C631.669 368.667 630.969 370.697 630.969 373.134V377.488C630.969 378.706 631.104 378.999 631.895 378.999H632.075V379.36H627.988V378.999H628.123C628.959 378.999 629.162 378.706 629.162 377.488V371.126C629.162 369.231 628.643 368.667 627.807 368.667C626.407 368.667 625.729 370.72 625.729 372.976V377.488C625.729 378.706 625.91 378.999 626.7 378.999H626.881V379.36H622.658V379.022H622.613Z"
                fill="#231F20"
              />
              <path
                d="M637.88 376.969C637.88 374.668 639.122 373.314 642.938 372.344V371.442C642.938 368.825 642.374 368.103 641.177 368.103C640.093 368.103 639.28 368.802 639.257 369.186C639.257 369.321 639.37 369.344 639.528 369.411C639.98 369.524 640.386 369.863 640.386 370.585C640.386 371.306 639.89 371.712 639.325 371.712C638.738 371.712 638.286 371.261 638.286 370.404C638.286 369.028 639.393 367.697 641.561 367.697C643.187 367.697 644.723 368.419 644.723 371.622V377.217C644.723 378.345 644.903 378.706 645.265 378.706C645.694 378.706 645.829 378.029 645.897 377.037H646.168C646.168 378.886 645.445 379.563 644.587 379.563C643.797 379.563 642.961 379.202 642.916 377.352H642.871C642.419 378.751 641.628 379.608 640.228 379.608C638.941 379.586 637.88 378.751 637.88 376.969ZM642.961 374.803V372.728C640.206 373.427 639.867 375.006 639.867 376.54C639.867 377.939 640.228 378.706 641.109 378.706C641.9 378.683 642.961 377.646 642.961 374.803Z"
                fill="#231F20"
              />
              <path
                d="M646.394 379.022H646.529C647.432 379.022 647.636 378.728 647.636 377.51V369.93C647.636 368.712 647.41 368.396 646.529 368.396H646.394V368.08L649.465 367.855L649.352 370.742H649.375C649.781 368.96 650.775 367.832 652.13 367.832C653.778 367.832 654.817 368.825 654.817 371.509V377.51C654.817 378.751 655.043 379.022 655.811 379.022H655.924V379.383H651.791V379.022H651.949C652.762 379.022 652.988 378.728 652.988 377.51V370.81C652.988 369.141 652.446 368.69 651.633 368.69C650.188 368.69 649.465 370.675 649.465 372.931V377.51C649.465 378.728 649.623 379.022 650.459 379.022H650.617V379.383H646.394V379.022Z"
                fill="#231F20"
              />
              <path
                d="M655.563 383.173C655.563 382.248 656.059 381.774 656.601 381.774C657.098 381.774 657.46 382.203 657.46 382.834C657.46 383.353 657.234 383.669 657.031 383.872C656.895 383.985 656.782 384.098 656.782 384.233C656.782 384.481 656.963 384.549 657.324 384.549C658.25 384.549 658.928 383.353 659.65 380.962L659.921 380.105L656.895 370.291C656.511 369.073 656.24 368.532 655.405 368.441V368.125H659.763V368.441C658.95 368.532 658.702 368.87 658.679 369.299C658.679 369.592 658.77 370.133 658.928 370.675L660.847 377.059L662.473 371.374C662.654 370.742 662.88 369.998 662.88 369.479C662.88 368.78 662.518 368.509 661.773 368.419V368.103H664.799V368.419C663.896 368.509 663.715 369.141 663.354 370.269L660.079 380.962C659.131 384.052 658.295 385.045 657.211 385.045C656.06 385 655.563 384.075 655.563 383.173Z"
                fill="#231F20"
              />
              <path
                d="M671.078 376.969C671.078 374.668 672.32 373.314 676.137 372.344V371.442C676.137 368.825 675.572 368.103 674.375 368.103C673.291 368.103 672.478 368.802 672.455 369.186C672.455 369.321 672.568 369.344 672.726 369.411C673.178 369.524 673.585 369.863 673.585 370.585C673.585 371.306 673.088 371.712 672.523 371.712C671.936 371.712 671.484 371.261 671.484 370.404C671.484 369.028 672.591 367.697 674.759 367.697C676.385 367.697 677.921 368.419 677.921 371.622V377.217C677.921 378.345 678.101 378.706 678.463 378.706C678.892 378.706 679.027 378.029 679.095 377.037H679.366C679.366 378.886 678.643 379.563 677.785 379.563C676.995 379.563 676.159 379.202 676.114 377.352H676.069C675.617 378.751 674.827 379.608 673.426 379.608C672.139 379.586 671.078 378.751 671.078 376.969ZM676.137 374.803V372.728C673.381 373.427 673.042 375.006 673.042 376.54C673.042 377.939 673.404 378.706 674.285 378.706C675.098 378.683 676.137 377.646 676.137 374.803Z"
                fill="#231F20"
              />
              <path
                d="M685.599 379.022H685.757C686.593 379.022 686.841 378.728 686.841 377.51V369.93C686.841 368.712 686.615 368.396 685.78 368.396H685.599V368.08L688.671 367.855L688.558 370.742H688.58C688.964 368.825 690.026 367.832 691.245 367.832C692.916 367.832 693.775 368.915 693.887 370.788C694.136 368.87 695.288 367.832 696.598 367.832C698.246 367.832 699.172 368.915 699.172 371.532V377.51C699.172 378.728 699.353 379.022 700.234 379.022H700.324V379.383H696.191V379.022H696.349C697.185 379.022 697.388 378.728 697.388 377.51V370.923C697.388 369.186 696.936 368.667 696.123 368.667C694.655 368.667 693.955 370.697 693.955 373.134V377.488C693.955 378.706 694.091 378.999 694.881 378.999H695.062V379.36H690.974V378.999H691.11C691.945 378.999 692.149 378.706 692.149 377.488V371.126C692.149 369.231 691.629 368.667 690.793 368.667C689.393 368.667 688.716 370.72 688.716 372.976V377.488C688.716 378.706 688.896 378.999 689.687 378.999H689.868V379.36H685.644V379.022H685.599Z"
                fill="#231F20"
              />
              <path
                d="M700.798 373.698C700.798 370.066 702.785 367.674 704.818 367.674C706.873 367.674 708.838 370.066 708.838 373.653C708.838 377.194 706.918 379.766 704.818 379.766C702.718 379.766 700.798 377.217 700.798 373.698ZM706.76 373.72C706.76 370.359 706.354 368.013 704.818 368.013C703.237 368.013 702.876 370.314 702.876 373.675C702.876 376.946 703.237 379.383 704.863 379.383C706.489 379.383 706.76 376.991 706.76 373.72Z"
                fill="#231F20"
              />
              <path
                d="M709.583 373.72C709.583 370.359 711.277 367.765 713.264 367.765C714.484 367.765 715.274 368.802 715.658 370.472H715.748C715.726 369.727 715.703 368.035 715.703 367.155V362.734C715.703 361.493 715.523 361.2 714.597 361.2H714.077V360.884L717.51 360.455V377.42C717.51 378.683 717.623 378.909 718.346 378.954L718.729 378.977V379.293C718.21 379.383 715.635 379.699 715.635 379.699L715.726 376.856H715.635C715.274 378.458 714.551 379.653 713.174 379.653C711.254 379.653 709.583 377.194 709.583 373.72ZM715.635 373.811C715.635 369.908 714.506 368.261 713.467 368.261C711.932 368.261 711.661 370.855 711.661 373.698C711.661 376.811 711.977 379.089 713.467 379.089C715.026 379.089 715.635 376.247 715.635 373.811Z"
                fill="#231F20"
              />
              <path
                d="M719.023 373.788C719.023 370.111 720.943 367.674 722.975 367.674C724.601 367.674 726.476 369.344 726.476 372.795C726.476 373.043 726.453 373.472 726.431 373.607H721.101C721.078 376.698 721.507 379.067 723.427 379.067C724.782 379.067 725.618 378.007 726.137 376.608L726.453 376.766C725.889 378.435 724.85 379.766 723.088 379.766C720.875 379.766 719.023 377.555 719.023 373.788ZM723.472 373.156C724.33 373.156 724.534 372.886 724.534 371.961C724.534 369.84 724.24 367.99 722.93 367.99C721.575 367.99 721.146 370.066 721.078 373.156H723.472Z"
                fill="#231F20"
              />
              <path
                d="M726.95 379.022H727.086C727.989 379.022 728.192 378.728 728.192 377.51V369.93C728.192 368.712 727.966 368.396 727.086 368.396H726.95V368.08L730.022 367.855L729.841 372.322H729.863C730.27 369.479 731.196 367.719 732.551 367.719C733.59 367.719 734.154 368.577 734.154 369.592C734.154 370.517 733.793 371.171 733.161 371.171C732.664 371.171 732.19 370.855 732.19 370.043C732.19 369.502 732.483 369.141 732.686 368.96C732.799 368.825 732.867 368.735 732.867 368.599C732.867 368.374 732.686 368.306 732.415 368.306C730.948 368.306 730.067 371.803 730.067 374.104V377.533C730.067 378.751 730.247 379.044 731.151 379.044H731.602V379.405H726.995V379.022H726.95Z"
                fill="#231F20"
              />
              <path
                d="M734.064 379.022H734.199C735.103 379.022 735.306 378.728 735.306 377.51V369.93C735.306 368.712 735.08 368.396 734.199 368.396H734.064V368.08L737.135 367.855L737.022 370.742H737.045C737.451 368.96 738.445 367.832 739.8 367.832C741.449 367.832 742.488 368.825 742.488 371.509V377.51C742.488 378.751 742.713 379.022 743.481 379.022H743.594V379.383H739.461V379.022H739.62C740.433 379.022 740.658 378.728 740.658 377.51V370.81C740.658 369.141 740.116 368.69 739.303 368.69C737.858 368.69 737.135 370.675 737.135 372.931V377.51C737.135 378.728 737.293 379.022 738.129 379.022H738.287V379.383H734.064V379.022Z"
                fill="#231F20"
              />
              <path
                d="M749.918 379.022H750.144C751.047 379.022 751.228 378.728 751.228 377.533V369.93C751.228 368.667 751.07 368.351 750.144 368.351H749.918V368.058L753.035 367.832V377.51C753.035 378.706 753.193 378.999 754.119 378.999H754.344V379.36H749.918V379.022ZM750.573 363.388C750.573 362.666 751.138 362.057 751.905 362.057C752.605 362.057 753.215 362.644 753.215 363.388C753.215 364.11 752.628 364.719 751.905 364.719C751.16 364.719 750.573 364.11 750.573 363.388Z"
                fill="#231F20"
              />
              <path
                d="M754.841 373.788C754.841 370.156 756.738 367.674 759.132 367.674C760.939 367.674 761.932 369.141 761.932 370.697C761.932 371.735 761.413 372.186 760.894 372.186C760.374 372.186 759.9 371.825 759.9 371.013C759.9 370.291 760.306 369.84 760.803 369.705C761.029 369.614 761.232 369.66 761.187 369.434C761.052 368.802 760.397 368.058 759.245 368.058C757.393 368.058 756.941 370.201 756.941 373.63C756.941 377.059 757.506 379.022 759.29 379.022C760.397 379.022 761.165 378.052 761.707 376.721L762 376.879C761.413 378.525 760.397 379.744 758.793 379.744C756.557 379.766 754.841 377.465 754.841 373.788Z"
                fill="#231F20"
              />
              <path
                d="M762.294 373.698C762.294 370.066 764.281 367.674 766.314 367.674C768.369 367.674 770.334 370.066 770.334 373.653C770.334 377.194 768.414 379.766 766.314 379.766C764.213 379.766 762.294 377.217 762.294 373.698ZM768.256 373.72C768.256 370.359 767.849 368.013 766.314 368.013C764.733 368.013 764.371 370.314 764.371 373.675C764.371 376.946 764.733 379.383 766.359 379.383C767.985 379.383 768.256 376.991 768.256 373.72Z"
                fill="#231F20"
              />
              <path
                d="M770.831 379.022H770.966C771.869 379.022 772.073 378.728 772.073 377.51V369.93C772.073 368.712 771.847 368.396 770.966 368.396H770.831V368.08L773.902 367.855L773.789 370.742H773.812C774.218 368.96 775.212 367.832 776.567 367.832C778.215 367.832 779.254 368.825 779.254 371.509V377.51C779.254 378.751 779.48 379.022 780.248 379.022H780.361V379.383H776.228V379.022H776.386C777.199 379.022 777.425 378.728 777.425 377.51V370.81C777.425 369.141 776.883 368.69 776.07 368.69C774.625 368.69 773.902 370.675 773.902 372.931V377.51C773.902 378.728 774.06 379.022 774.896 379.022H775.054V379.383H770.831V379.022Z"
                fill="#231F20"
              />
              <path
                d="M780.609 366.817C782.145 365.576 782.777 364.133 782.777 363.433C782.777 362.779 782.461 363.253 781.919 363.253C781.129 363.253 780.587 362.734 780.587 362.012C780.587 361.267 781.106 360.681 781.919 360.681C782.642 360.681 783.41 361.267 783.41 362.824C783.41 364.268 782.642 365.667 780.88 367.133L780.609 366.817Z"
                fill="#231F20"
              />
              <path
                d="M783.861 378.751C783.568 378.751 783.365 379.022 783.207 379.608H782.936V375.006H783.229C783.545 377.646 784.878 379.247 786.436 379.247C787.881 379.247 788.469 378.255 788.469 377.194C788.469 375.999 787.927 375.435 786.007 374.623C784.2 373.833 783.026 373.111 783.026 370.923C783.026 368.893 784.245 367.787 785.781 367.787C787.046 367.787 787.497 368.486 788.039 368.486C788.356 368.486 788.536 368.261 788.627 367.81H788.898V371.916H788.604C788.31 369.705 787.294 368.148 785.781 368.148C784.81 368.148 784.02 368.87 784.02 369.998C784.02 371.216 784.607 371.69 786.413 372.479C788.333 373.292 789.485 374.149 789.485 376.269C789.485 378.277 788.288 379.631 786.413 379.631C784.968 379.653 784.313 378.751 783.861 378.751Z"
                fill="#231F20"
              />
              <path
                d="M797.163 376.27V368.487H795.786V368.171C797.231 367.855 798.451 366.682 798.631 363.185H799.015V368.08H801.567V368.487H799.015V376.382C799.015 378.165 799.286 378.932 800.077 378.932C800.935 378.932 801.499 377.736 801.545 375.367H801.928C801.928 378.322 801.07 379.744 799.602 379.744C798.044 379.721 797.163 378.616 797.163 376.27Z"
                fill="#231F20"
              />
              <path
                d="M802.493 373.788C802.493 370.111 804.413 367.674 806.445 367.674C808.071 367.674 809.946 369.344 809.946 372.795C809.946 373.043 809.923 373.472 809.9 373.607H804.571C804.548 376.698 804.977 379.067 806.897 379.067C808.252 379.067 809.087 378.007 809.607 376.608L809.923 376.766C809.358 378.435 808.32 379.766 806.558 379.766C804.322 379.766 802.493 377.555 802.493 373.788ZM806.942 373.156C807.8 373.156 808.003 372.886 808.003 371.961C808.003 369.84 807.71 367.99 806.4 367.99C805.045 367.99 804.616 370.066 804.548 373.156H806.942Z"
                fill="#231F20"
              />
              <path
                d="M810.51 379.022H810.691C811.662 379.022 811.82 378.706 811.82 377.397V362.847C811.82 361.538 811.594 361.222 810.51 361.222H810.284V360.861L813.582 360.41V377.397C813.582 378.706 813.74 379.022 814.711 379.022H814.891V379.383H810.442V379.022H810.51Z"
                fill="#231F20"
              />
              <path
                d="M815.479 379.022H815.659C816.631 379.022 816.789 378.706 816.789 377.397V362.847C816.789 361.538 816.563 361.222 815.479 361.222H815.253V360.861L818.55 360.41V377.397C818.55 378.706 818.708 379.022 819.679 379.022H819.86V379.383H815.411V379.022H815.479Z"
                fill="#231F20"
              />
              <path
                d="M820.56 372.502H825.28V373.878H820.56V372.502Z"
                fill="#231F20"
              />
              <path
                d="M826.161 376.969C826.161 374.668 827.403 373.314 831.22 372.344V371.442C831.22 368.825 830.655 368.103 829.458 368.103C828.374 368.103 827.561 368.802 827.538 369.186C827.538 369.321 827.651 369.344 827.809 369.411C828.261 369.524 828.668 369.863 828.668 370.585C828.668 371.306 828.171 371.712 827.606 371.712C827.019 371.712 826.567 371.261 826.567 370.404C826.567 369.028 827.674 367.697 829.842 367.697C831.468 367.697 833.004 368.419 833.004 371.622V377.217C833.004 378.345 833.184 378.706 833.546 378.706C833.975 378.706 834.11 378.029 834.178 377.037H834.449C834.449 378.886 833.726 379.563 832.868 379.563C832.078 379.563 831.242 379.202 831.197 377.352H831.152C830.7 378.751 829.91 379.608 828.51 379.608C827.222 379.586 826.161 378.751 826.161 376.969ZM831.242 374.803V372.728C828.487 373.427 828.148 375.006 828.148 376.54C828.148 377.939 828.51 378.706 829.39 378.706C830.181 378.683 831.242 377.646 831.242 374.803Z"
                fill="#231F20"
              />
              <path
                d="M834.765 379.022H834.946C835.917 379.022 836.075 378.706 836.075 377.397V362.847C836.075 361.538 835.849 361.222 834.765 361.222H834.539V360.861L837.837 360.41V377.397C837.837 378.706 837.995 379.022 838.966 379.022H839.146V379.383H834.697V379.022H834.765Z"
                fill="#231F20"
              />
              <path
                d="M839.711 379.022H839.892C840.863 379.022 841.021 378.706 841.021 377.397V362.847C841.021 361.538 840.795 361.222 839.711 361.222H839.485V360.861L842.783 360.41V377.397C842.783 378.706 842.941 379.022 843.912 379.022H844.093V379.383H839.643V379.022H839.711Z"
                fill="#231F20"
              />
              <path
                d="M851.387 374.781V362.734C851.387 361.493 851.252 361.155 850.303 361.155H849.942V360.861C850.777 360.771 853.194 360.433 853.194 360.433V367.9C853.194 368.329 853.081 370.81 853.081 370.81H853.126C853.487 369.141 854.3 367.742 855.723 367.742C857.665 367.742 859.201 370.178 859.201 373.653C859.201 376.856 857.575 379.631 855.497 379.631C854.12 379.631 853.307 378.548 852.81 377.104H852.787L851.522 379.698H851.139C851.342 378.187 851.387 376.427 851.387 374.781ZM857.168 373.743C857.168 370.855 856.92 368.351 855.407 368.351C854.278 368.351 853.148 370.291 853.148 373.698C853.148 376.969 853.871 379.225 855.317 379.225C856.897 379.247 857.168 376.585 857.168 373.743Z"
                fill="#231F20"
              />
              <path
                d="M859.969 379.022H860.195C861.098 379.022 861.279 378.728 861.279 377.533V369.93C861.279 368.667 861.121 368.351 860.195 368.351H859.969V368.058L863.085 367.832V377.51C863.085 378.706 863.243 378.999 864.169 378.999H864.395V379.36H859.969V379.022ZM860.601 363.388C860.601 362.666 861.166 362.057 861.934 362.057C862.634 362.057 863.243 362.644 863.243 363.388C863.243 364.11 862.656 364.719 861.934 364.719C861.211 364.719 860.601 364.11 860.601 363.388Z"
                fill="#231F20"
              />
              <path
                d="M864.802 373.698C864.802 370.066 866.789 367.674 868.822 367.674C870.877 367.674 872.842 370.066 872.842 373.653C872.842 377.194 870.922 379.766 868.822 379.766C866.721 379.766 864.802 377.217 864.802 373.698ZM870.764 373.72C870.764 370.359 870.357 368.013 868.822 368.013C867.241 368.013 866.88 370.314 866.88 373.675C866.88 376.946 867.241 379.383 868.867 379.383C870.493 379.383 870.764 376.991 870.764 373.72Z"
                fill="#231F20"
              />
              <path
                d="M873.316 382.157C873.316 380.781 874.535 379.586 876.478 379.473C874.987 379.315 874.016 378.774 874.016 377.555C874.016 376.608 874.648 375.909 875.461 375.367C874.558 374.645 873.948 373.314 873.948 371.87C873.948 369.389 875.484 367.652 877.426 367.652C878.465 367.652 879.301 368.058 880.001 369.028C880.317 368.306 880.769 367.652 881.446 367.652C882.078 367.652 882.53 368.171 882.53 368.983C882.53 369.637 882.169 370.066 881.762 370.066C881.356 370.066 881.062 369.795 881.04 369.005C881.04 368.644 881.017 368.464 880.859 368.464C880.678 368.464 880.452 368.78 880.227 369.321C880.678 370.156 880.859 370.991 880.859 371.87C880.859 374.329 879.323 376.112 877.426 376.112C876.862 376.112 876.229 375.954 875.755 375.57C875.303 375.863 875.032 376.269 875.032 376.788C875.032 377.42 875.597 377.826 877.652 377.894C879.504 377.939 881.898 378.413 881.898 381.165C881.898 383.466 879.843 384.932 877.404 384.932C875.168 384.955 873.316 384.12 873.316 382.157ZM881.017 381.842C881.017 380.578 880.068 379.653 877.516 379.653C875.168 379.653 874.445 380.984 874.445 382.18C874.445 383.849 875.823 384.594 877.471 384.594C879.504 384.571 881.017 383.331 881.017 381.842ZM878.984 371.893C878.984 370.178 878.826 367.99 877.404 367.99C875.981 367.99 875.8 370.066 875.8 371.893C875.8 373.743 875.981 375.818 877.404 375.818C878.781 375.796 878.984 373.72 878.984 371.893Z"
                fill="#231F20"
              />
              <path
                d="M882.507 379.022H882.643C883.546 379.022 883.749 378.728 883.749 377.51V369.93C883.749 368.712 883.524 368.396 882.643 368.396H882.507V368.08L885.579 367.855L885.398 372.322H885.421C885.827 369.479 886.753 367.719 888.108 367.719C889.147 367.719 889.712 368.577 889.712 369.592C889.712 370.517 889.35 371.171 888.718 371.171C888.221 371.171 887.747 370.855 887.747 370.043C887.747 369.502 888.04 369.141 888.244 368.96C888.357 368.825 888.424 368.735 888.424 368.599C888.424 368.374 888.244 368.306 887.973 368.306C886.505 368.306 885.624 371.803 885.624 374.104V377.533C885.624 378.751 885.805 379.044 886.708 379.044H887.16V379.405H882.553V379.022H882.507Z"
                fill="#231F20"
              />
              <path
                d="M889.712 376.969C889.712 374.668 890.954 373.314 894.77 372.344V371.442C894.77 368.825 894.206 368.103 893.009 368.103C891.925 368.103 891.112 368.802 891.089 369.186C891.089 369.321 891.202 369.344 891.36 369.411C891.812 369.524 892.219 369.863 892.219 370.585C892.219 371.306 891.722 371.712 891.157 371.712C890.57 371.712 890.118 371.261 890.118 370.404C890.118 369.028 891.225 367.697 893.393 367.697C895.019 367.697 896.555 368.419 896.555 371.622V377.217C896.555 378.345 896.735 378.706 897.097 378.706C897.526 378.706 897.661 378.029 897.729 377.037H898C898 378.886 897.277 379.563 896.419 379.563C895.629 379.563 894.793 379.202 894.748 377.352H894.703C894.251 378.751 893.461 379.608 892.06 379.608C890.773 379.586 889.712 378.751 889.712 376.969ZM894.793 374.803V372.728C892.038 373.427 891.699 375.006 891.699 376.54C891.699 377.939 892.06 378.706 892.941 378.706C893.732 378.683 894.793 377.646 894.793 374.803Z"
                fill="#231F20"
              />
              <path
                d="M898 384.549H898.158C899.061 384.549 899.242 384.256 899.242 383.015V369.953C899.242 368.712 899.061 368.419 898.158 368.419H898V368.103L901.071 367.877L900.958 370.833H901.004C901.388 369.073 902.268 367.81 903.578 367.81C905.701 367.81 907.079 370.404 907.079 373.743C907.079 376.879 905.498 379.699 903.51 379.699C901.975 379.699 901.388 378.503 901.071 377.262H901.026C901.026 377.262 901.071 379.744 901.071 380.217V383.06C901.071 384.323 901.32 384.594 902.065 384.594H902.494V384.955H898V384.549ZM905.024 373.743C905.024 370.788 904.775 368.351 903.285 368.351C902.11 368.351 901.026 370.381 901.026 373.788C901.026 377.037 901.862 379.157 903.194 379.157C904.685 379.157 905.024 376.788 905.024 373.743Z"
                fill="#231F20"
              />
              <path
                d="M907.553 379.022H907.711C908.614 379.022 908.795 378.728 908.795 377.51V362.801C908.795 361.493 908.569 361.222 907.688 361.222H907.508V360.861L910.602 360.41C910.602 360.41 910.602 367.381 910.602 368.419L910.511 370.742H910.534C910.94 368.96 911.934 367.832 913.334 367.832C914.983 367.832 915.999 368.825 915.999 371.509V377.51C915.999 378.751 916.248 379.022 916.993 379.022H917.128V379.383H912.95V379.022H913.131C913.944 379.022 914.17 378.728 914.17 377.51V370.81C914.17 369.141 913.605 368.689 912.815 368.689C911.369 368.689 910.624 370.675 910.624 372.931V377.51C910.624 378.728 910.805 379.022 911.618 379.022H911.776V379.383H907.553V379.022Z"
                fill="#231F20"
              />
              <path
                d="M916.722 383.173C916.722 382.248 917.219 381.774 917.761 381.774C918.257 381.774 918.619 382.203 918.619 382.834C918.619 383.353 918.393 383.669 918.19 383.872C918.054 383.985 917.941 384.098 917.941 384.233C917.941 384.481 918.122 384.549 918.483 384.549C919.409 384.549 920.087 383.353 920.809 380.962L921.08 380.105L918.054 370.291C917.67 369.073 917.399 368.532 916.564 368.441V368.125H920.922V368.441C920.109 368.532 919.861 368.87 919.838 369.299C919.838 369.592 919.929 370.133 920.087 370.675L922.006 377.059L923.632 371.374C923.813 370.742 924.039 369.998 924.039 369.479C924.039 368.78 923.678 368.509 922.932 368.419V368.103H925.936V368.419C925.033 368.509 924.852 369.141 924.491 370.269L921.216 380.962C920.267 384.052 919.432 385.045 918.348 385.045C917.219 385 916.722 384.075 916.722 383.173Z"
                fill="#231F20"
              />
              <path
                d="M932.011 379.022H932.237C933.14 379.022 933.321 378.728 933.321 377.533V369.93C933.321 368.667 933.163 368.351 932.237 368.351H932.011V368.058L935.128 367.832V377.51C935.128 378.706 935.286 378.999 936.212 378.999H936.437V379.36H932.011V379.022ZM932.643 363.388C932.643 362.666 933.208 362.057 933.976 362.057C934.676 362.057 935.286 362.644 935.286 363.388C935.286 364.11 934.698 364.719 933.976 364.719C933.253 364.719 932.643 364.11 932.643 363.388Z"
                fill="#231F20"
              />
              <path
                d="M936.663 379.022H936.799C937.702 379.022 937.905 378.728 937.905 377.51V369.93C937.905 368.712 937.68 368.396 936.799 368.396H936.663V368.08L939.735 367.855L939.622 370.742H939.644C940.051 368.96 941.045 367.832 942.4 367.832C944.048 367.832 945.087 368.825 945.087 371.509V377.51C945.087 378.751 945.313 379.022 946.081 379.022H946.194V379.383H942.061V379.022H942.219C943.032 379.022 943.258 378.728 943.258 377.51V370.81C943.258 369.141 942.716 368.69 941.903 368.69C940.457 368.69 939.735 370.675 939.735 372.931V377.51C939.735 378.728 939.893 379.022 940.728 379.022H940.887V379.383H936.663V379.022Z"
                fill="#231F20"
              />
              <path
                d="M952.359 383.173C952.359 382.248 952.856 381.774 953.398 381.774C953.895 381.774 954.256 382.203 954.256 382.834C954.256 383.353 954.03 383.669 953.827 383.872C953.692 383.985 953.579 384.098 953.579 384.233C953.579 384.481 953.759 384.549 954.121 384.549C955.047 384.549 955.724 383.353 956.447 380.962L956.718 380.105L953.692 370.291C953.308 369.073 953.037 368.532 952.201 368.441V368.125H956.56V368.441C955.747 368.532 955.498 368.87 955.476 369.299C955.476 369.592 955.566 370.133 955.724 370.675L957.644 377.059L959.27 371.374C959.451 370.742 959.676 369.998 959.676 369.479C959.676 368.78 959.315 368.509 958.57 368.419V368.103H961.596V368.419C960.693 368.509 960.512 369.141 960.151 370.269L956.876 380.962C955.927 384.052 955.092 385.045 954.008 385.045C952.879 385 952.359 384.075 952.359 383.173Z"
                fill="#231F20"
              />
              <path
                d="M961.347 373.788C961.347 370.111 963.267 367.674 965.3 367.674C966.926 367.674 968.8 369.344 968.8 372.795C968.8 373.043 968.777 373.472 968.755 373.607H963.425C963.403 376.698 963.832 379.067 965.751 379.067C967.106 379.067 967.942 378.007 968.461 376.608L968.778 376.766C968.213 378.435 967.174 379.766 965.413 379.766C963.177 379.766 961.347 377.555 961.347 373.788ZM965.774 373.156C966.632 373.156 966.835 372.886 966.835 371.961C966.835 369.84 966.542 367.99 965.232 367.99C963.877 367.99 963.448 370.066 963.38 373.156H965.774Z"
                fill="#231F20"
              />
              <path
                d="M969.613 376.969C969.613 374.668 970.855 373.314 974.672 372.344V371.442C974.672 368.825 974.107 368.103 972.91 368.103C971.826 368.103 971.013 368.802 970.991 369.186C970.991 369.321 971.104 369.344 971.262 369.411C971.713 369.524 972.12 369.863 972.12 370.585C972.12 371.306 971.623 371.712 971.058 371.712C970.471 371.712 970.02 371.261 970.02 370.404C970.02 369.028 971.126 367.697 973.294 367.697C974.92 367.697 976.456 368.419 976.456 371.622V377.217C976.456 378.345 976.637 378.706 976.998 378.706C977.427 378.706 977.563 378.029 977.63 377.037H977.901C977.901 378.886 977.179 379.563 976.32 379.563C975.53 379.563 974.694 379.202 974.649 377.352H974.604C974.152 378.751 973.362 379.608 971.962 379.608C970.674 379.586 969.613 378.751 969.613 376.969ZM974.672 374.803V372.728C971.917 373.427 971.578 375.006 971.578 376.54C971.578 377.939 971.939 378.706 972.82 378.706C973.61 378.683 974.672 377.646 974.672 374.803Z"
                fill="#231F20"
              />
              <path
                d="M978.15 379.022H978.285C979.189 379.022 979.392 378.728 979.392 377.51V369.93C979.392 368.712 979.166 368.396 978.285 368.396H978.15V368.08L981.221 367.855L981.04 372.322H981.063C981.469 369.479 982.395 367.719 983.75 367.719C984.789 367.719 985.354 368.577 985.354 369.592C985.354 370.517 984.993 371.171 984.36 371.171C983.863 371.171 983.389 370.855 983.389 370.043C983.389 369.502 983.683 369.141 983.886 368.96C983.999 368.825 984.067 368.735 984.067 368.599C984.067 368.374 983.886 368.306 983.615 368.306C982.147 368.306 981.266 371.803 981.266 374.104V377.533C981.266 378.751 981.447 379.044 982.35 379.044H982.802V379.405H978.195V379.022H978.15Z"
                fill="#231F20"
              />
              <path
                d="M986.664 378.751C986.37 378.751 986.167 379.022 986.009 379.608H985.738V375.006H986.032C986.348 377.646 987.68 379.247 989.238 379.247C990.684 379.247 991.271 378.255 991.271 377.194C991.271 375.999 990.729 375.435 988.809 374.623C987.003 373.833 985.828 373.111 985.828 370.923C985.828 368.893 987.048 367.787 988.584 367.787C989.848 367.787 990.3 368.486 990.842 368.486C991.158 368.486 991.339 368.261 991.429 367.81H991.7V371.916H991.407C991.113 369.705 990.097 368.148 988.584 368.148C987.612 368.148 986.822 368.87 986.822 369.998C986.822 371.216 987.409 371.69 989.216 372.479C991.136 373.292 992.265 374.149 992.265 376.269C992.265 378.277 991.068 379.631 989.216 379.631C987.771 379.653 987.116 378.751 986.664 378.751Z"
                fill="#231F20"
              />
              <path
                d="M999.966 376.27V368.487H998.588V368.171C1000.03 367.855 1001.25 366.682 1001.43 363.185H1001.82V368.08H1004.37V368.487H1001.82V376.382C1001.82 378.165 1002.09 378.932 1002.88 378.932C1003.74 378.932 1004.3 377.736 1004.35 375.367H1004.73C1004.73 378.322 1003.87 379.744 1002.4 379.744C1000.87 379.721 999.966 378.616 999.966 376.27Z"
                fill="#231F20"
              />
              <path
                d="M1005.21 373.698C1005.21 370.066 1007.19 367.674 1009.23 367.674C1011.28 367.674 1013.25 370.066 1013.25 373.653C1013.25 377.194 1011.33 379.766 1009.23 379.766C1007.12 379.766 1005.21 377.217 1005.21 373.698ZM1011.17 373.72C1011.17 370.359 1010.76 368.013 1009.23 368.013C1007.64 368.013 1007.28 370.314 1007.28 373.675C1007.28 376.946 1007.64 379.383 1009.27 379.383C1010.9 379.383 1011.17 376.991 1011.17 373.72Z"
                fill="#231F20"
              />
              <path
                d="M1019.93 373.788C1019.93 370.156 1021.83 367.674 1024.22 367.674C1026.03 367.674 1027.02 369.141 1027.02 370.697C1027.02 371.735 1026.5 372.186 1025.98 372.186C1025.46 372.186 1024.99 371.825 1024.99 371.013C1024.99 370.291 1025.39 369.84 1025.89 369.705C1026.12 369.614 1026.32 369.66 1026.28 369.434C1026.14 368.802 1025.49 368.058 1024.33 368.058C1022.48 368.058 1022.03 370.201 1022.03 373.63C1022.03 377.059 1022.59 379.022 1024.38 379.022C1025.49 379.022 1026.25 378.052 1026.8 376.721L1027.09 376.879C1026.5 378.525 1025.49 379.744 1023.88 379.744C1021.67 379.766 1019.93 377.465 1019.93 373.788Z"
                fill="#231F20"
              />
              <path
                d="M1027.41 373.698C1027.41 370.066 1029.39 367.674 1031.42 367.674C1033.48 367.674 1035.44 370.066 1035.44 373.653C1035.44 377.194 1033.53 379.766 1031.42 379.766C1029.32 379.766 1027.41 377.217 1027.41 373.698ZM1033.34 373.72C1033.34 370.359 1032.94 368.013 1031.4 368.013C1029.82 368.013 1029.46 370.314 1029.46 373.675C1029.46 376.946 1029.82 379.383 1031.45 379.383C1033.07 379.383 1033.34 376.991 1033.34 373.72Z"
                fill="#231F20"
              />
              <path
                d="M1035.99 379.022H1036.14C1036.98 379.022 1037.23 378.728 1037.23 377.51V369.93C1037.23 368.712 1037 368.396 1036.17 368.396H1035.99V368.08L1039.06 367.855L1038.95 370.742H1038.97C1039.35 368.825 1040.41 367.832 1041.63 367.832C1043.3 367.832 1044.16 368.915 1044.27 370.788C1044.52 368.87 1045.68 367.832 1046.99 367.832C1048.63 367.832 1049.56 368.915 1049.56 371.532V377.51C1049.56 378.728 1049.74 379.022 1050.62 379.022H1050.71V379.383H1046.58V379.022H1046.74C1047.57 379.022 1047.78 378.728 1047.78 377.51V370.923C1047.78 369.186 1047.32 368.667 1046.51 368.667C1045.04 368.667 1044.34 370.697 1044.34 373.134V377.488C1044.34 378.706 1044.48 378.999 1045.27 378.999H1045.45V379.36H1041.36V378.999H1041.5C1042.33 378.999 1042.54 378.706 1042.54 377.488V371.126C1042.54 369.231 1042.02 368.667 1041.18 368.667C1039.78 368.667 1039.1 370.72 1039.1 372.976V377.488C1039.1 378.706 1039.28 378.999 1040.07 378.999H1040.26V379.36H1036.03V379.022H1035.99Z"
                fill="#231F20"
              />
              <path
                d="M1051.19 373.788C1051.19 370.111 1053.11 367.674 1055.14 367.674C1056.76 367.674 1058.64 369.344 1058.64 372.795C1058.64 373.043 1058.62 373.472 1058.59 373.607H1053.26C1053.24 376.698 1053.67 379.067 1055.59 379.067C1056.94 379.067 1057.78 378.007 1058.3 376.608L1058.62 376.766C1058.05 378.435 1057.01 379.766 1055.25 379.766C1053.02 379.766 1051.19 377.555 1051.19 373.788ZM1055.64 373.156C1056.49 373.156 1056.7 372.886 1056.7 371.961C1056.7 369.84 1056.4 367.99 1055.09 367.99C1053.74 367.99 1053.31 370.066 1053.24 373.156H1055.64Z"
                fill="#231F20"
              />
              <path
                d="M1059.79 378.368C1059.79 377.646 1060.33 377.104 1061.05 377.104C1061.82 377.104 1062.36 377.646 1062.36 378.368C1062.36 379.112 1061.82 379.676 1061.05 379.676C1060.33 379.676 1059.79 379.135 1059.79 378.368Z"
                fill="#231F20"
              />
              <path
                d="M1069.86 370.178C1069.86 364.471 1072.41 360.5 1075.55 360.5C1078.67 360.5 1081.22 364.448 1081.22 370.088C1081.22 375.728 1078.69 379.766 1075.53 379.766C1072.41 379.766 1069.86 375.863 1069.86 370.178ZM1078.99 370.178C1078.99 365.17 1078.29 360.816 1075.51 360.816C1072.71 360.816 1072.08 365.057 1072.08 370.066C1072.08 375.074 1072.78 379.428 1075.55 379.428C1078.33 379.428 1078.99 375.187 1078.99 370.178Z"
                fill="#231F20"
              />
              <path
                d="M1081.92 379.022H1082.06C1082.96 379.022 1083.16 378.728 1083.16 377.51V369.93C1083.16 368.712 1082.94 368.396 1082.06 368.396H1081.92V368.08L1084.99 367.855L1084.88 370.742H1084.9C1085.31 368.96 1086.3 367.832 1087.66 367.832C1089.31 367.832 1090.35 368.825 1090.35 371.509V377.51C1090.35 378.751 1090.57 379.022 1091.34 379.022H1091.45V379.383H1087.32V379.022H1087.48C1088.29 379.022 1088.52 378.728 1088.52 377.51V370.81C1088.52 369.141 1087.97 368.69 1087.16 368.69C1085.72 368.69 1084.99 370.675 1084.99 372.931V377.51C1084.99 378.728 1085.15 379.022 1085.99 379.022H1086.15V379.383H1081.92V379.022Z"
                fill="#231F20"
              />
              <path
                d="M1092.04 373.788C1092.04 370.111 1093.96 367.674 1095.99 367.674C1097.62 367.674 1099.49 369.344 1099.49 372.795C1099.49 373.043 1099.47 373.472 1099.45 373.607H1094.12C1094.1 376.698 1094.52 379.067 1096.44 379.067C1097.8 379.067 1098.63 378.007 1099.15 376.608L1099.47 376.766C1098.91 378.435 1097.87 379.766 1096.11 379.766C1093.89 379.766 1092.04 377.555 1092.04 373.788ZM1096.49 373.156C1097.35 373.156 1097.55 372.886 1097.55 371.961C1097.55 369.84 1097.26 367.99 1095.95 367.99C1094.59 367.99 1094.16 370.066 1094.1 373.156H1096.49Z"
                fill="#231F20"
              />
              <path
                d="M1106.18 373.72C1106.18 370.359 1107.87 367.765 1109.86 367.765C1111.08 367.765 1111.87 368.802 1112.25 370.472H1112.34C1112.32 369.727 1112.3 368.035 1112.3 367.155V362.734C1112.3 361.493 1112.12 361.2 1111.19 361.2H1110.67V360.884L1114.1 360.455V377.42C1114.1 378.683 1114.22 378.909 1114.94 378.954L1115.32 378.977V379.293C1114.8 379.383 1112.23 379.699 1112.23 379.699L1112.32 376.856H1112.23C1111.87 378.458 1111.15 379.653 1109.77 379.653C1107.85 379.653 1106.18 377.194 1106.18 373.72ZM1112.25 373.811C1112.25 369.908 1111.12 368.261 1110.08 368.261C1108.55 368.261 1108.28 370.855 1108.28 373.698C1108.28 376.811 1108.59 379.089 1110.08 379.089C1111.62 379.089 1112.25 376.247 1112.25 373.811Z"
                fill="#231F20"
              />
              <path
                d="M1115.53 373.698C1115.53 370.066 1117.51 367.674 1119.55 367.674C1121.6 367.674 1123.57 370.066 1123.57 373.653C1123.57 377.194 1121.65 379.766 1119.55 379.766C1117.45 379.766 1115.53 377.217 1115.53 373.698ZM1121.49 373.72C1121.49 370.359 1121.08 368.013 1119.55 368.013C1117.97 368.013 1117.6 370.314 1117.6 373.675C1117.6 376.946 1117.97 379.383 1119.59 379.383C1121.22 379.383 1121.49 376.991 1121.49 373.72Z"
                fill="#231F20"
              />
              <path
                d="M1124.36 373.788C1124.36 370.111 1126.28 367.674 1128.31 367.674C1129.94 367.674 1131.81 369.344 1131.81 372.795C1131.81 373.043 1131.79 373.472 1131.77 373.607H1126.44C1126.41 376.698 1126.84 379.067 1128.76 379.067C1130.12 379.067 1130.95 378.007 1131.47 376.608L1131.79 376.766C1131.22 378.435 1130.18 379.766 1128.42 379.766C1126.21 379.766 1124.36 377.555 1124.36 373.788ZM1128.81 373.156C1129.66 373.156 1129.87 372.886 1129.87 371.961C1129.87 369.84 1129.57 367.99 1128.26 367.99C1126.91 367.99 1126.48 370.066 1126.41 373.156H1128.81Z"
                fill="#231F20"
              />
              <path
                d="M1133.8 378.751C1133.5 378.751 1133.3 379.022 1133.14 379.608H1132.87V375.006H1133.17C1133.48 377.646 1134.81 379.247 1136.37 379.247C1137.82 379.247 1138.4 378.255 1138.4 377.194C1138.4 375.999 1137.86 375.435 1135.94 374.623C1134.14 373.833 1132.96 373.111 1132.96 370.923C1132.96 368.893 1134.18 367.787 1135.72 367.787C1136.98 367.787 1137.43 368.486 1137.98 368.486C1138.29 368.486 1138.47 368.261 1138.56 367.81H1138.83V371.916H1138.54C1138.25 369.705 1137.23 368.148 1135.72 368.148C1134.75 368.148 1133.96 368.87 1133.96 369.998C1133.96 371.216 1134.54 371.69 1136.35 372.479C1138.27 373.292 1139.4 374.149 1139.4 376.269C1139.4 378.277 1138.2 379.631 1136.35 379.631C1134.9 379.653 1134.25 378.751 1133.8 378.751Z"
                fill="#231F20"
              />
              <path
                d="M1145.88 379.022H1146.02C1146.92 379.022 1147.12 378.728 1147.12 377.51V369.93C1147.12 368.712 1146.9 368.396 1146.02 368.396H1145.88V368.08L1148.95 367.855L1148.84 370.742H1148.86C1149.27 368.96 1150.26 367.832 1151.62 367.832C1153.26 367.832 1154.3 368.825 1154.3 371.509V377.51C1154.3 378.751 1154.53 379.022 1155.3 379.022H1155.41V379.383H1151.28V379.022H1151.44C1152.25 379.022 1152.47 378.728 1152.47 377.51V370.81C1152.47 369.141 1151.93 368.69 1151.12 368.69C1149.67 368.69 1148.95 370.675 1148.95 372.931V377.51C1148.95 378.728 1149.11 379.022 1149.94 379.022H1150.1V379.383H1145.88V379.022Z"
                fill="#231F20"
              />
              <path
                d="M1155.95 373.698C1155.95 370.066 1157.94 367.674 1159.97 367.674C1162.03 367.674 1163.99 370.066 1163.99 373.653C1163.99 377.194 1162.07 379.766 1159.97 379.766C1157.87 379.766 1155.95 377.217 1155.95 373.698ZM1161.91 373.72C1161.91 370.359 1161.51 368.013 1159.97 368.013C1158.39 368.013 1158.03 370.314 1158.03 373.675C1158.03 376.946 1158.39 379.383 1160.02 379.383C1161.64 379.383 1161.91 376.991 1161.91 373.72Z"
                fill="#231F20"
              />
              <path
                d="M1165.57 376.27V368.487H1164.2V368.171C1165.64 367.855 1166.86 366.682 1167.04 363.185H1167.42V368.08H1169.98V368.487H1167.42V376.382C1167.42 378.165 1167.7 378.932 1168.49 378.932C1169.34 378.932 1169.91 377.736 1169.95 375.367H1170.34C1170.34 378.322 1169.48 379.744 1168.01 379.744C1166.45 379.721 1165.57 378.616 1165.57 376.27Z"
                fill="#231F20"
              />
              <path
                d="M1177.93 378.751C1177.63 378.751 1177.43 379.022 1177.27 379.608H1177V375.006H1177.29C1177.61 377.646 1178.94 379.247 1180.5 379.247C1181.95 379.247 1182.53 378.255 1182.53 377.194C1182.53 375.999 1181.99 375.435 1180.07 374.623C1178.27 373.833 1177.09 373.111 1177.09 370.923C1177.09 368.893 1178.31 367.787 1179.85 367.787C1181.11 367.787 1181.56 368.486 1182.1 368.486C1182.42 368.486 1182.6 368.261 1182.69 367.81H1182.96V371.916H1182.67C1182.38 369.705 1181.36 368.148 1179.85 368.148C1178.87 368.148 1178.08 368.87 1178.08 369.998C1178.08 371.216 1178.67 371.69 1180.48 372.479C1182.4 373.292 1183.55 374.149 1183.55 376.269C1183.55 378.277 1182.35 379.631 1180.48 379.631C1179.06 379.653 1178.4 378.751 1177.93 378.751Z"
                fill="#231F20"
              />
              <path
                d="M1184.18 379.022H1184.41C1185.31 379.022 1185.49 378.728 1185.49 377.533V369.93C1185.49 368.667 1185.33 368.351 1184.41 368.351H1184.18V368.058L1187.3 367.832V377.51C1187.3 378.706 1187.46 378.999 1188.38 378.999H1188.61V379.36H1184.2V379.022H1184.18ZM1184.84 363.388C1184.84 362.666 1185.4 362.057 1186.17 362.057C1186.87 362.057 1187.48 362.644 1187.48 363.388C1187.48 364.11 1186.89 364.719 1186.17 364.719C1185.42 364.719 1184.84 364.11 1184.84 363.388Z"
                fill="#231F20"
              />
              <path
                d="M1188.83 379.022H1188.99C1189.83 379.022 1190.08 378.728 1190.08 377.51V369.93C1190.08 368.712 1189.85 368.396 1189.01 368.396H1188.83V368.08L1191.91 367.855L1191.79 370.742H1191.82C1192.2 368.825 1193.26 367.832 1194.48 367.832C1196.15 367.832 1197.01 368.915 1197.12 370.788C1197.37 368.87 1198.52 367.832 1199.83 367.832C1201.48 367.832 1202.41 368.915 1202.41 371.532V377.51C1202.41 378.728 1202.59 379.022 1203.47 379.022H1203.56V379.383H1199.43V379.022H1199.58C1200.42 379.022 1200.62 378.728 1200.62 377.51V370.923C1200.62 369.186 1200.17 368.667 1199.36 368.667C1197.89 368.667 1197.19 370.697 1197.19 373.134V377.488C1197.19 378.706 1197.33 378.999 1198.12 378.999H1198.3V379.36H1194.21V378.999H1194.34C1195.18 378.999 1195.38 378.706 1195.38 377.488V371.126C1195.38 369.231 1194.86 368.667 1194.03 368.667C1192.63 368.667 1191.95 370.72 1191.95 372.976V377.488C1191.95 378.706 1192.13 378.999 1192.92 378.999H1193.1V379.36H1188.88V379.022H1188.83Z"
                fill="#231F20"
              />
              <path
                d="M1203.72 384.549H1203.87C1204.78 384.549 1204.96 384.256 1204.96 383.015V369.953C1204.96 368.712 1204.78 368.419 1203.87 368.419H1203.72V368.103L1206.79 367.877L1206.68 370.833H1206.72C1207.1 369.073 1207.99 367.81 1209.29 367.81C1211.42 367.81 1212.8 370.404 1212.8 373.743C1212.8 376.879 1211.21 379.699 1209.23 379.699C1207.69 379.699 1207.1 378.503 1206.79 377.262H1206.74C1206.74 377.262 1206.79 379.744 1206.79 380.217V383.06C1206.79 384.323 1207.04 384.594 1207.78 384.594H1208.21V384.955H1203.72V384.549ZM1210.72 373.743C1210.72 370.788 1210.47 368.351 1208.98 368.351C1207.8 368.351 1206.72 370.381 1206.72 373.788C1206.72 377.037 1207.56 379.157 1208.89 379.157C1210.4 379.157 1210.72 376.788 1210.72 373.743Z"
                fill="#231F20"
              />
              <path
                d="M1213.52 379.022H1213.7C1214.67 379.022 1214.83 378.706 1214.83 377.397V362.847C1214.83 361.538 1214.6 361.222 1213.52 361.222H1213.29V360.861L1216.59 360.41V377.397C1216.59 378.706 1216.75 379.022 1217.72 379.022H1217.9V379.383H1213.45V379.022H1213.52Z"
                fill="#231F20"
              />
              <path
                d="M1218.01 383.173C1218.01 382.248 1218.51 381.774 1219.05 381.774C1219.55 381.774 1219.91 382.203 1219.91 382.834C1219.91 383.353 1219.68 383.669 1219.48 383.872C1219.34 383.985 1219.23 384.098 1219.23 384.233C1219.23 384.481 1219.41 384.549 1219.77 384.549C1220.7 384.549 1221.38 383.353 1222.1 380.962L1222.37 380.105L1219.34 370.291C1218.96 369.073 1218.69 368.532 1217.85 368.441V368.125H1222.21V368.441C1221.4 368.532 1221.15 368.87 1221.13 369.299C1221.13 369.592 1221.22 370.133 1221.38 370.675L1223.3 377.059L1224.92 371.374C1225.1 370.742 1225.33 369.998 1225.33 369.479C1225.33 368.78 1224.97 368.509 1224.22 368.419V368.103H1227.25V368.419C1226.35 368.509 1226.17 369.141 1225.8 370.269L1222.53 380.962C1221.58 384.052 1220.74 385.045 1219.66 385.045C1218.53 385 1218.01 384.075 1218.01 383.173Z"
                fill="#231F20"
              />
              <path
                d="M1234.68 378.751C1234.39 378.751 1234.18 379.022 1234.02 379.608H1233.75V375.006H1234.05C1234.36 377.646 1235.7 379.247 1237.25 379.247C1238.7 379.247 1239.29 378.255 1239.29 377.194C1239.29 375.999 1238.74 375.435 1236.82 374.623C1235.02 373.833 1233.84 373.111 1233.84 370.923C1233.84 368.893 1235.06 367.787 1236.6 367.787C1237.86 367.787 1238.32 368.486 1238.86 368.486C1239.17 368.486 1239.35 368.261 1239.44 367.81H1239.72V371.916H1239.42C1239.13 369.705 1238.11 368.148 1236.6 368.148C1235.63 368.148 1234.84 368.87 1234.84 369.998C1234.84 371.216 1235.42 371.69 1237.23 372.479C1239.15 373.292 1240.28 374.149 1240.28 376.269C1240.28 378.277 1239.08 379.631 1237.23 379.631C1235.81 379.653 1235.15 378.751 1234.68 378.751Z"
                fill="#231F20"
              />
              <path
                d="M1241.95 376.27V368.487H1240.57V368.171C1242.02 367.855 1243.24 366.682 1243.42 363.185H1243.8V368.08H1246.36V368.487H1243.8V376.382C1243.8 378.165 1244.07 378.932 1244.86 378.932C1245.72 378.932 1246.29 377.736 1246.33 375.367H1246.72C1246.72 378.322 1245.86 379.744 1244.39 379.744C1242.85 379.721 1241.95 378.616 1241.95 376.27Z"
                fill="#231F20"
              />
              <path
                d="M1247.26 376.969C1247.26 374.668 1248.5 373.314 1252.32 372.344V371.442C1252.32 368.825 1251.75 368.103 1250.56 368.103C1249.47 368.103 1248.66 368.802 1248.64 369.186C1248.64 369.321 1248.75 369.344 1248.91 369.411C1249.36 369.524 1249.77 369.863 1249.77 370.585C1249.77 371.306 1249.27 371.712 1248.7 371.712C1248.12 371.712 1247.66 371.261 1247.66 370.404C1247.66 369.028 1248.77 367.697 1250.94 367.697C1252.57 367.697 1254.1 368.419 1254.1 371.622V377.217C1254.1 378.345 1254.28 378.706 1254.64 378.706C1255.07 378.706 1255.21 378.029 1255.28 377.037H1255.55C1255.55 378.886 1254.82 379.563 1253.97 379.563C1253.18 379.563 1252.34 379.202 1252.29 377.352H1252.25C1251.8 378.751 1251.01 379.608 1249.61 379.608C1248.32 379.586 1247.26 378.751 1247.26 376.969ZM1252.34 374.803V372.728C1249.58 373.427 1249.25 375.006 1249.25 376.54C1249.25 377.939 1249.61 378.706 1250.49 378.706C1251.28 378.683 1252.34 377.646 1252.34 374.803Z"
                fill="#231F20"
              />
              <path
                d="M1254.96 383.173C1254.96 382.248 1255.46 381.774 1256 381.774C1256.5 381.774 1256.86 382.203 1256.86 382.834C1256.86 383.353 1256.63 383.669 1256.43 383.872C1256.29 383.985 1256.18 384.098 1256.18 384.233C1256.18 384.481 1256.36 384.549 1256.72 384.549C1257.65 384.549 1258.32 383.353 1259.05 380.962L1259.32 380.105L1256.29 370.291C1255.91 369.073 1255.64 368.532 1254.8 368.441V368.125H1259.16V368.441C1258.35 368.532 1258.1 368.87 1258.08 369.299C1258.08 369.592 1258.17 370.133 1258.32 370.675L1260.24 377.059L1261.87 371.374C1262.05 370.742 1262.28 369.998 1262.28 369.479C1262.28 368.78 1261.92 368.509 1261.17 368.419V368.103H1264.2V368.419C1263.29 368.509 1263.11 369.141 1262.75 370.269L1259.48 380.962C1258.53 384.052 1257.69 385.045 1256.61 385.045C1255.46 385 1254.96 384.075 1254.96 383.173Z"
                fill="#231F20"
              />
              <path
                d="M1270.52 376.969C1270.52 374.668 1271.76 373.314 1275.58 372.344V371.442C1275.58 368.825 1275.01 368.103 1273.82 368.103C1272.73 368.103 1271.92 368.802 1271.9 369.186C1271.9 369.321 1272.01 369.344 1272.17 369.411C1272.62 369.524 1273.03 369.863 1273.03 370.585C1273.03 371.306 1272.53 371.712 1271.97 371.712C1271.38 371.712 1270.93 371.261 1270.93 370.404C1270.93 369.028 1272.03 367.697 1274.2 367.697C1275.83 367.697 1277.36 368.419 1277.36 371.622V377.217C1277.36 378.345 1277.54 378.706 1277.9 378.706C1278.33 378.706 1278.47 378.029 1278.54 377.037H1278.81C1278.81 378.886 1278.09 379.563 1277.23 379.563C1276.44 379.563 1275.6 379.202 1275.56 377.352H1275.51C1275.06 378.751 1274.27 379.608 1272.87 379.608C1271.56 379.586 1270.52 378.751 1270.52 376.969ZM1275.58 374.803V372.728C1272.82 373.427 1272.48 375.006 1272.48 376.54C1272.48 377.939 1272.85 378.706 1273.73 378.706C1274.52 378.683 1275.58 377.646 1275.58 374.803Z"
                fill="#231F20"
              />
              <path
                d="M1280.07 376.27V368.487H1278.7V368.171C1280.14 367.855 1281.36 366.682 1281.54 363.185H1281.92V368.08H1284.48V368.487H1281.92V376.382C1281.92 378.165 1282.2 378.932 1282.99 378.932C1283.84 378.932 1284.41 377.736 1284.45 375.367H1284.84C1284.84 378.322 1283.98 379.744 1282.51 379.744C1280.95 379.721 1280.07 378.616 1280.07 376.27Z"
                fill="#231F20"
              />
              <path
                d="M1292.18 376.27V368.487H1290.8V368.171C1292.25 367.855 1293.46 366.682 1293.65 363.185H1294.03V368.08H1296.58V368.487H1294.03V376.382C1294.03 378.165 1294.3 378.932 1295.09 378.932C1295.95 378.932 1296.51 377.736 1296.56 375.367H1296.94C1296.94 378.322 1296.08 379.744 1294.62 379.744C1293.08 379.721 1292.18 378.616 1292.18 376.27Z"
                fill="#231F20"
              />
              <path
                d="M1296.99 379.022H1297.15C1298.05 379.022 1298.23 378.728 1298.23 377.51V362.801C1298.23 361.493 1298 361.222 1297.12 361.222H1296.94V360.861L1300.04 360.41C1300.04 360.41 1300.04 367.381 1300.04 368.419L1299.95 370.742H1299.97C1300.38 368.96 1301.37 367.832 1302.77 367.832C1304.42 367.832 1305.43 368.825 1305.43 371.509V377.51C1305.43 378.751 1305.68 379.022 1306.43 379.022H1306.56V379.383H1302.39V379.022H1302.57C1303.38 379.022 1303.6 378.728 1303.6 377.51V370.81C1303.6 369.141 1303.04 368.689 1302.25 368.689C1300.8 368.689 1300.06 370.675 1300.06 372.931V377.51C1300.06 378.728 1300.24 379.022 1301.05 379.022H1301.21V379.383H1296.99V379.022Z"
                fill="#231F20"
              />
              <path
                d="M1307.51 373.743C1307.17 370.088 1308.87 367.471 1310.9 367.291C1312.53 367.155 1314.54 368.644 1314.85 372.073C1314.87 372.322 1314.87 372.75 1314.87 372.886L1309.57 373.359C1309.82 376.427 1310.47 378.774 1312.39 378.593C1313.75 378.48 1314.47 377.352 1314.87 375.886L1315.21 376.021C1314.81 377.736 1313.9 379.157 1312.14 379.315C1309.88 379.518 1307.85 377.51 1307.51 373.743ZM1311.87 372.705C1312.73 372.615 1312.91 372.344 1312.82 371.419C1312.64 369.321 1312.16 367.494 1310.88 367.607C1309.52 367.719 1309.27 369.84 1309.5 372.931L1311.87 372.705Z"
                fill="#231F20"
              />
              <path
                d="M1321.47 360.546H1321.45L1325.31 373.021C1326.1 375.57 1326.46 376.179 1327.93 375.728L1328 375.705L1328.11 376.089L1324.18 377.307L1324.07 376.924L1324.2 376.879C1325.47 376.495 1325.58 375.773 1324.79 373.179L1321.04 361.042C1320.66 359.801 1320.25 359.553 1319.37 359.824L1318.87 359.982L1318.76 359.598L1322.51 358.447L1330.41 371.351L1329.55 356.282L1333.26 355.131L1333.37 355.515L1332.99 355.628C1332.13 355.898 1331.83 356.327 1332.22 357.568L1336.49 371.351C1336.87 372.592 1337.32 372.818 1338.16 372.57L1338.61 372.434L1338.72 372.818L1333.44 374.442L1333.33 374.059L1333.87 373.901C1334.75 373.63 1334.97 373.224 1334.57 371.961L1330.21 357.838L1330.16 357.861L1331.18 375.502L1330.82 375.615L1321.47 360.546Z"
                fill="#231F20"
              />
              <path
                d="M1339.78 369.569C1338.79 367.494 1339.33 365.734 1342.38 363.208L1342 362.395C1340.87 360.027 1340.06 359.621 1338.97 360.139C1338 360.613 1337.55 361.583 1337.71 361.944C1337.77 362.057 1337.86 362.034 1338.05 362.034C1338.52 361.944 1339.02 362.08 1339.33 362.711C1339.65 363.365 1339.38 363.952 1338.86 364.2C1338.32 364.448 1337.73 364.223 1337.37 363.456C1336.78 362.215 1337.21 360.523 1339.17 359.598C1340.64 358.899 1342.36 358.899 1343.71 361.786L1346.11 366.862C1346.6 367.9 1346.92 368.125 1347.24 367.968C1347.62 367.787 1347.46 367.11 1347.08 366.185L1347.33 366.073C1348.12 367.742 1347.76 368.667 1346.99 369.028C1346.27 369.366 1345.36 369.411 1344.53 367.742L1344.48 367.765C1344.66 369.231 1344.32 370.336 1343.04 370.923C1341.88 371.487 1340.55 371.171 1339.78 369.569ZM1343.44 365.441L1342.54 363.568C1340.35 365.396 1340.71 366.952 1341.39 368.351C1342 369.614 1342.63 370.156 1343.44 369.772C1344.14 369.389 1344.66 368.013 1343.44 365.441Z"
                fill="#231F20"
              />
              <path
                d="M1349.52 367.178L1349.63 367.11C1350.4 366.637 1350.42 366.276 1349.79 365.238L1345.81 358.786C1345.18 357.748 1344.82 357.59 1344.05 358.064L1343.94 358.132L1343.76 357.861L1346.27 356.079L1347.69 358.605L1347.71 358.583C1347.12 356.846 1347.37 355.357 1348.52 354.635C1349.92 353.778 1351.32 354.094 1352.75 356.372L1355.91 361.493C1356.56 362.553 1356.88 362.666 1357.56 362.26L1357.65 362.192L1357.83 362.486L1354.31 364.651L1354.12 364.358L1354.26 364.268C1354.96 363.839 1354.98 363.478 1354.35 362.441L1350.83 356.71C1349.95 355.289 1349.27 355.199 1348.57 355.628C1347.35 356.372 1347.76 358.447 1348.95 360.388L1351.35 364.29C1351.98 365.328 1352.27 365.509 1353 365.057L1353.13 364.967L1353.31 365.26L1349.72 367.471L1349.52 367.178Z"
                fill="#231F20"
              />
              <path
                d="M1355.73 356.959C1353.52 354.093 1353.61 350.98 1355.21 349.739C1356.84 348.476 1359.84 349.175 1362.05 352.018C1364.22 354.815 1364.29 358.041 1362.62 359.305C1360.97 360.591 1357.87 359.756 1355.73 356.959ZM1360.47 353.349C1358.42 350.687 1356.65 349.085 1355.43 350.033C1354.19 351.003 1355.3 353.033 1357.35 355.695C1359.36 358.29 1361.13 360.004 1362.41 359.011C1363.7 358.019 1362.46 355.943 1360.47 353.349Z"
                fill="#231F20"
              />
              <path
                d="M1366.5 355.267L1366.59 355.176C1367.25 354.567 1367.2 354.206 1366.37 353.304L1361.19 347.754C1360.36 346.852 1359.97 346.762 1359.32 347.371L1359.23 347.461L1359 347.213L1361.1 344.98L1364.02 348.386L1364.04 348.363C1362.39 345.995 1361.87 344.077 1362.86 343.175C1363.61 342.475 1364.63 342.724 1365.3 343.446C1365.94 344.122 1366.12 344.844 1365.64 345.273C1365.28 345.611 1364.69 345.701 1364.15 345.115C1363.79 344.709 1363.77 344.258 1363.77 343.987C1363.75 343.806 1363.77 343.694 1363.68 343.603C1363.52 343.446 1363.34 343.513 1363.14 343.694C1362.07 344.686 1363.79 347.845 1365.37 349.537L1367.72 352.063C1368.56 352.966 1368.89 353.056 1369.55 352.447L1369.89 352.131L1370.14 352.379L1366.77 355.515L1366.5 355.267Z"
                fill="#231F20"
              />
              <path
                d="M1374.18 354.116C1374.36 351.995 1373.82 350.394 1373.32 349.897C1372.85 349.446 1372.96 349.988 1372.58 350.371C1372.03 350.935 1371.29 350.935 1370.77 350.416C1370.23 349.875 1370.18 349.13 1370.72 348.566C1371.27 348.025 1372.21 347.912 1373.3 348.995C1374.31 350.033 1374.74 351.747 1374.56 354.116H1374.18Z"
                fill="#231F20"
              />
              <path
                d="M1375.96 339.227L1366.21 332.143C1365.19 331.399 1364.85 331.331 1364.29 332.098L1364.08 332.391L1363.84 332.211C1364.27 331.489 1365.39 329.323 1365.39 329.323L1371.45 333.722C1371.81 333.97 1373.75 335.527 1373.75 335.527L1373.77 335.482C1372.62 334.219 1371.99 332.73 1372.82 331.579C1373.98 330 1376.84 330.203 1379.64 332.256C1382.24 334.128 1383.53 337.084 1382.31 338.776C1381.5 339.881 1380.14 339.904 1378.7 339.452L1378.67 339.475L1380.03 342.024L1379.8 342.34C1378.72 341.257 1377.3 340.197 1375.96 339.227ZM1378.54 333.925C1376.19 332.211 1374.04 330.948 1373.14 332.188C1372.46 333.113 1373.39 335.144 1376.14 337.151C1378.79 339.069 1381.04 339.813 1381.88 338.663C1382.83 337.377 1380.84 335.595 1378.54 333.925Z"
                fill="#231F20"
              />
              <path
                d="M1382.92 331.444L1377.66 328.443C1376.6 327.834 1376.21 327.834 1375.78 328.624L1375.69 328.782L1375.42 328.624L1376.78 325.804L1384.12 330C1385.65 330.88 1386.44 330.947 1386.92 330.113C1387.62 328.895 1386.15 327.112 1384.18 325.984L1380.34 323.796C1379.26 323.187 1378.88 323.187 1378.45 323.954L1378.22 324.36L1377.95 324.202L1379.42 321.179L1387.71 325.894C1388.77 326.503 1389.2 326.458 1389.63 325.691L1389.69 325.578L1389.97 325.736L1388.79 328.601L1386.28 327.045L1386.26 327.09C1387.55 328.331 1388.09 329.842 1387.37 331.128C1386.53 332.504 1385.18 332.73 1382.92 331.444Z"
                fill="#231F20"
              />
              <path
                d="M1388.27 322.149L1381.16 318.923L1380.59 320.187L1380.3 320.051C1380.59 318.607 1380.03 317.028 1376.93 315.404L1377.09 315.066L1381.56 317.096L1382.63 314.772L1382.99 314.93L1381.93 317.254L1389.13 320.525C1390.76 321.269 1391.57 321.337 1391.89 320.615C1392.25 319.826 1391.39 318.81 1389.24 317.818L1389.4 317.479C1392.09 318.698 1393.04 320.074 1392.43 321.405C1391.79 322.758 1390.42 323.119 1388.27 322.149Z"
                fill="#231F20"
              />
              <path
                d="M1380.57 306.628C1379.87 306.425 1379.46 305.703 1379.69 304.982C1379.89 304.305 1380.64 303.899 1381.34 304.124C1382.04 304.327 1382.45 305.072 1382.24 305.749C1382.02 306.448 1381.27 306.854 1380.57 306.628ZM1395.36 311.772L1395.43 311.569C1395.68 310.712 1395.45 310.441 1394.32 310.103L1387.03 307.914C1385.81 307.553 1385.47 307.621 1385.22 308.501L1385.16 308.704L1384.86 308.614L1385.56 305.568L1394.84 308.365C1396 308.704 1396.31 308.636 1396.58 307.756L1396.65 307.553L1396.99 307.644L1395.72 311.862L1395.36 311.772Z"
                fill="#231F20"
              />
              <path
                d="M1397.06 306.358L1397.08 306.222C1397.26 305.342 1397.01 305.072 1395.81 304.824L1388.38 303.267C1387.19 303.019 1386.83 303.154 1386.65 304.034L1386.62 304.169L1386.31 304.102L1386.71 301.056L1389.54 301.755V301.733C1387.87 300.966 1386.96 299.748 1387.23 298.439C1387.57 296.815 1388.77 296.025 1391.39 296.567L1397.28 297.785C1398.5 298.033 1398.82 297.875 1398.98 297.108L1399 296.995L1399.34 297.063L1398.48 301.124L1398.14 301.056L1398.16 300.898C1398.32 300.109 1398.07 299.838 1396.9 299.59L1390.33 298.214C1388.68 297.875 1388.14 298.304 1387.98 299.116C1387.68 300.515 1389.47 301.643 1391.7 302.116L1396.2 303.064C1397.4 303.312 1397.71 303.222 1397.89 302.387L1397.91 302.229L1398.25 302.297L1397.4 306.425L1397.06 306.358Z"
                fill="#231F20"
              />
              <path
                d="M1399.07 294.108C1399.04 294.401 1399.29 294.627 1399.86 294.852L1399.83 295.123L1395.25 294.649L1395.27 294.356C1397.94 294.311 1399.68 293.16 1399.83 291.626C1399.99 290.205 1399.04 289.506 1398.01 289.393C1396.81 289.28 1396.2 289.754 1395.18 291.581C1394.21 293.296 1393.38 294.379 1391.19 294.153C1389.15 293.95 1388.18 292.619 1388.34 291.085C1388.47 289.822 1389.22 289.461 1389.27 288.919C1389.29 288.603 1389.11 288.423 1388.66 288.265L1388.68 287.994L1392.77 288.423L1392.74 288.716C1390.51 288.784 1388.86 289.619 1388.7 291.13C1388.61 292.1 1389.24 292.957 1390.35 293.07C1391.57 293.206 1392.11 292.642 1393.08 290.95C1394.1 289.122 1395.07 288.084 1397.17 288.288C1399.16 288.491 1400.4 289.822 1400.2 291.671C1400.06 293.093 1399.11 293.657 1399.07 294.108Z"
                fill="#231F20"
              />
              <path
                d="M1397.24 285.919L1389.45 285.738L1389.42 287.114H1389.11C1388.81 285.671 1387.68 284.43 1384.18 284.159V283.776L1389.08 283.888L1389.15 281.339H1389.56L1389.49 283.888L1397.4 284.069C1399.18 284.114 1399.95 283.866 1399.97 283.076C1399.99 282.219 1398.82 281.61 1396.42 281.52V281.136C1399.38 281.204 1400.78 282.106 1400.76 283.573C1400.74 285.107 1399.61 285.964 1397.24 285.919Z"
                fill="#231F20"
              />
              <path
                d="M1394.82 280.392C1391.14 280.392 1388.7 278.474 1388.7 276.444C1388.7 274.819 1390.37 272.947 1393.83 272.947C1394.08 272.947 1394.5 272.97 1394.64 272.992V278.316C1397.73 278.339 1400.11 277.91 1400.11 275.993C1400.11 274.639 1399.04 273.804 1397.64 273.285L1397.8 272.97C1399.47 273.534 1400.81 274.571 1400.81 276.331C1400.81 278.564 1398.61 280.392 1394.82 280.392ZM1394.19 275.97C1394.19 275.113 1393.92 274.91 1392.99 274.91C1390.87 274.91 1389.02 275.203 1389.02 276.511C1389.02 277.865 1391.09 278.294 1394.19 278.361V275.97Z"
                fill="#231F20"
              />
              <path
                d="M1398.03 272.112C1395.72 272.112 1394.37 270.872 1393.4 267.059H1392.49C1389.88 267.059 1389.15 267.623 1389.15 268.819C1389.15 269.902 1389.85 270.714 1390.24 270.736C1390.37 270.736 1390.39 270.623 1390.46 270.466C1390.58 270.014 1390.91 269.608 1391.64 269.608C1392.36 269.608 1392.77 270.105 1392.77 270.669C1392.77 271.255 1392.31 271.706 1391.46 271.706C1390.08 271.706 1388.75 270.601 1388.75 268.435C1388.75 266.811 1389.47 265.277 1392.68 265.277H1398.28C1399.41 265.277 1399.77 265.096 1399.77 264.735C1399.77 264.307 1399.09 264.171 1398.1 264.104V263.833C1399.95 263.833 1400.62 264.555 1400.62 265.412C1400.62 266.202 1400.26 267.036 1398.41 267.082V267.127C1399.81 267.578 1400.67 268.367 1400.67 269.766C1400.65 271.052 1399.81 272.112 1398.03 272.112ZM1395.86 267.059H1393.78C1394.48 269.811 1396.06 270.15 1397.6 270.15C1399 270.15 1399.77 269.789 1399.77 268.909C1399.72 268.097 1398.71 267.059 1395.86 267.059Z"
                fill="#231F20"
              />
              <path
                d="M1394.78 263.517C1391.41 263.517 1388.81 261.825 1388.81 259.84C1388.81 258.622 1389.85 257.832 1391.52 257.449V257.358C1390.78 257.381 1389.08 257.404 1388.2 257.404H1383.78C1382.54 257.404 1382.24 257.584 1382.24 258.509V259.028H1381.93L1381.5 255.599H1398.48C1399.74 255.599 1399.97 255.486 1400.02 254.764L1400.04 254.381H1400.35C1400.44 254.899 1400.76 257.471 1400.76 257.471L1397.92 257.381V257.471C1399.52 257.832 1400.72 258.554 1400.72 259.93C1400.69 261.848 1398.23 263.517 1394.78 263.517ZM1394.84 257.449C1390.94 257.449 1389.29 258.577 1389.29 259.614C1389.29 261.148 1391.89 261.419 1394.73 261.419C1397.85 261.419 1400.13 261.103 1400.13 259.614C1400.13 258.08 1397.28 257.449 1394.84 257.449Z"
                fill="#231F20"
              />
              <path
                d="M1404.49 253.433C1403.13 251.786 1401.62 251.019 1400.92 251.019C1400.26 251.019 1400.74 251.335 1400.74 251.877C1400.74 252.666 1400.22 253.185 1399.5 253.185C1398.73 253.185 1398.16 252.666 1398.16 251.877C1398.16 251.109 1398.75 250.365 1400.31 250.388C1401.75 250.41 1403.27 251.335 1404.8 253.14L1404.49 253.433Z"
                fill="#231F20"
              />
              <path
                d="M1398.03 243.123C1395.72 243.123 1394.37 241.883 1393.4 238.07H1392.49C1389.88 238.07 1389.15 238.634 1389.15 239.83C1389.15 240.913 1389.85 241.725 1390.24 241.747C1390.37 241.747 1390.39 241.635 1390.46 241.477C1390.58 241.025 1390.91 240.619 1391.64 240.619C1392.36 240.619 1392.77 241.116 1392.77 241.68C1392.77 242.266 1392.31 242.717 1391.46 242.717C1390.08 242.717 1388.75 241.612 1388.75 239.446C1388.75 237.822 1389.47 236.288 1392.68 236.288H1398.28C1399.41 236.288 1399.77 236.107 1399.77 235.746C1399.77 235.318 1399.09 235.183 1398.1 235.115V234.844C1399.95 234.844 1400.62 235.566 1400.62 236.423C1400.62 237.213 1400.26 238.048 1398.41 238.093V238.138C1399.81 238.589 1400.67 239.379 1400.67 240.777C1400.65 242.063 1399.81 243.123 1398.03 243.123ZM1395.86 238.07H1393.78C1394.48 240.822 1396.06 241.161 1397.6 241.161C1399 241.161 1399.77 240.8 1399.77 239.92C1399.72 239.13 1398.71 238.07 1395.86 238.07Z"
                fill="#231F20"
              />
              <path
                d="M1394.78 234.528C1391.41 234.528 1388.81 232.836 1388.81 230.851C1388.81 229.633 1389.85 228.843 1391.52 228.46V228.37C1390.78 228.392 1389.08 228.415 1388.2 228.415H1383.78C1382.54 228.415 1382.24 228.595 1382.24 229.52V230.039H1381.93L1381.5 226.61H1398.48C1399.74 226.61 1399.97 226.497 1400.02 225.775L1400.04 225.392H1400.35C1400.44 225.911 1400.76 228.482 1400.76 228.482L1397.92 228.392V228.482C1399.52 228.843 1400.72 229.565 1400.72 230.941C1400.69 232.859 1398.23 234.528 1394.78 234.528ZM1394.84 228.46C1390.94 228.46 1389.29 229.588 1389.29 230.626C1389.29 232.16 1391.89 232.43 1394.73 232.43C1397.85 232.43 1400.13 232.114 1400.13 230.626C1400.13 229.091 1397.28 228.46 1394.84 228.46Z"
                fill="#231F20"
              />
              <path
                d="M1394.78 225.144C1391.41 225.144 1388.81 223.452 1388.81 221.466C1388.81 220.248 1389.85 219.459 1391.52 219.075V218.985C1390.78 219.007 1389.08 219.03 1388.2 219.03H1383.78C1382.54 219.03 1382.24 219.21 1382.24 220.135V220.654H1381.93L1381.5 217.225H1398.48C1399.74 217.225 1399.97 217.112 1400.02 216.39L1400.04 216.007H1400.35C1400.44 216.526 1400.76 219.098 1400.76 219.098L1397.92 219.007V219.098C1399.52 219.459 1400.72 220.18 1400.72 221.557C1400.69 223.474 1398.23 225.144 1394.78 225.144ZM1394.84 219.098C1390.94 219.098 1389.29 220.226 1389.29 221.263C1389.29 222.797 1391.89 223.068 1394.73 223.068C1397.85 223.068 1400.13 222.752 1400.13 221.263C1400.13 219.707 1397.28 219.098 1394.84 219.098Z"
                fill="#231F20"
              />
              <path
                d="M1399.81 214.202C1399.81 214.496 1400.08 214.699 1400.67 214.856V215.127H1396.06V214.834C1398.71 214.518 1400.31 213.187 1400.31 211.63C1400.31 210.187 1399.32 209.6 1398.25 209.6C1397.06 209.6 1396.49 210.142 1395.68 212.059C1394.89 213.864 1394.17 215.037 1391.98 215.037C1389.94 215.037 1388.84 213.819 1388.84 212.285C1388.84 211.021 1389.54 210.57 1389.54 210.029C1389.54 209.713 1389.31 209.532 1388.86 209.442V209.172H1392.97V209.465C1390.76 209.758 1389.2 210.773 1389.2 212.285C1389.2 213.255 1389.92 214.044 1391.05 214.044C1392.27 214.044 1392.74 213.458 1393.53 211.653C1394.35 209.735 1395.2 208.585 1397.33 208.585C1399.34 208.585 1400.69 209.781 1400.69 211.653C1400.69 213.097 1399.81 213.751 1399.81 214.202Z"
                fill="#231F20"
              />
              <path
                d="M1397.31 200.982H1389.51V202.358H1389.2C1388.88 200.915 1387.71 199.696 1384.21 199.516V199.132H1389.11V196.583H1389.51V199.132H1397.42C1399.2 199.132 1399.97 198.862 1399.97 198.072C1399.97 197.215 1398.77 196.651 1396.4 196.606V196.222C1399.36 196.222 1400.78 197.08 1400.78 198.546C1400.78 200.08 1399.68 200.982 1397.31 200.982Z"
                fill="#231F20"
              />
              <path
                d="M1394.73 195.658C1391.09 195.658 1388.7 193.673 1388.7 191.643C1388.7 189.59 1391.09 187.627 1394.69 187.627C1398.23 187.627 1400.81 189.545 1400.81 191.643C1400.81 193.741 1398.25 195.658 1394.73 195.658ZM1394.78 189.703C1391.41 189.703 1389.06 190.109 1389.06 191.643C1389.06 193.222 1391.37 193.583 1394.73 193.583C1398.01 193.583 1400.44 193.222 1400.44 191.598C1400.44 189.973 1398.05 189.703 1394.78 189.703Z"
                fill="#231F20"
              />
              <path
                d="M1384.43 180.498C1383.71 180.498 1383.1 179.934 1383.1 179.167C1383.1 178.468 1383.69 177.859 1384.43 177.859C1385.16 177.859 1385.76 178.445 1385.76 179.167C1385.74 179.889 1385.16 180.498 1384.43 180.498ZM1400.08 181.13V180.904C1400.08 180.002 1399.79 179.822 1398.59 179.822H1390.98C1389.72 179.822 1389.4 179.98 1389.4 180.904V181.13H1389.11L1388.88 178.017H1398.57C1399.77 178.017 1400.06 177.859 1400.06 176.934V176.708H1400.42V181.107H1400.08V181.13Z"
                fill="#231F20"
              />
              <path
                d="M1397.31 175.332H1389.51V176.708H1389.2C1388.88 175.265 1387.71 174.046 1384.21 173.866V173.482H1389.11V170.933H1389.51V173.482H1397.42C1399.2 173.482 1399.97 173.212 1399.97 172.422C1399.97 171.565 1398.77 171.001 1396.4 170.956V170.572C1399.36 170.572 1400.78 171.429 1400.78 172.896C1400.78 174.452 1399.68 175.332 1397.31 175.332Z"
                fill="#231F20"
              />
              <path
                d="M1399.81 168.835C1399.81 169.128 1400.08 169.331 1400.67 169.489V169.76H1396.06V169.467C1398.71 169.151 1400.31 167.82 1400.31 166.263C1400.31 164.819 1399.32 164.233 1398.25 164.233C1397.06 164.233 1396.49 164.774 1395.68 166.692C1394.89 168.497 1394.17 169.67 1391.98 169.67C1389.94 169.67 1388.84 168.452 1388.84 166.918C1388.84 165.654 1389.54 165.203 1389.54 164.662C1389.54 164.346 1389.31 164.165 1388.86 164.075V163.804H1392.97V164.098C1390.76 164.391 1389.2 165.406 1389.2 166.918C1389.2 167.888 1389.92 168.677 1391.05 168.677C1392.27 168.677 1392.74 168.091 1393.53 166.286C1394.35 164.368 1395.2 163.218 1397.33 163.218C1399.34 163.218 1400.69 164.413 1400.69 166.286C1400.69 167.707 1399.81 168.361 1399.81 168.835Z"
                fill="#231F20"
              />
              <path
                d="M1394.82 156.495C1391.14 156.495 1388.7 154.577 1388.7 152.547C1388.7 150.923 1390.37 149.05 1393.83 149.05C1394.08 149.05 1394.5 149.073 1394.64 149.096V154.42C1397.73 154.442 1400.11 154.013 1400.11 152.096C1400.11 150.742 1399.04 149.908 1397.64 149.389L1397.8 149.073C1399.47 149.637 1400.81 150.675 1400.81 152.434C1400.81 154.645 1398.61 156.495 1394.82 156.495ZM1394.19 152.051C1394.19 151.194 1393.92 150.991 1392.99 150.991C1390.87 150.991 1389.02 151.284 1389.02 152.592C1389.02 153.946 1391.09 154.374 1394.19 154.442V152.051Z"
                fill="#231F20"
              />
              <path
                d="M1391.23 147.765C1389.99 148.148 1389.49 148.396 1389.45 149.231H1389.13V144.854H1389.45C1389.49 145.644 1389.85 145.96 1390.28 145.96C1390.69 145.96 1391.19 145.824 1391.64 145.689L1397.76 143.907L1392.31 142.328C1391.66 142.147 1390.98 141.944 1390.46 141.944C1389.78 141.967 1389.51 142.237 1389.42 143.05H1389.11V140.049H1389.42C1389.51 140.952 1390.12 141.132 1391.19 141.448L1400.78 144.335V144.877L1391.23 147.765Z"
                fill="#231F20"
              />
              <path
                d="M1394.82 140.433C1391.14 140.433 1388.7 138.515 1388.7 136.485C1388.7 134.86 1390.37 132.988 1393.83 132.988C1394.08 132.988 1394.5 133.011 1394.64 133.033V138.357C1397.73 138.38 1400.11 137.951 1400.11 136.034C1400.11 134.68 1399.04 133.845 1397.64 133.326L1397.8 133.011C1399.47 133.575 1400.81 134.612 1400.81 136.372C1400.81 138.605 1398.61 140.433 1394.82 140.433ZM1394.19 136.011C1394.19 135.154 1393.92 134.951 1392.99 134.951C1390.87 134.951 1389.02 135.244 1389.02 136.552C1389.02 137.906 1391.09 138.335 1394.19 138.402V136.011Z"
                fill="#231F20"
              />
              <path
                d="M1400.08 132.514V132.379C1400.08 131.477 1399.79 131.274 1398.57 131.274H1390.98C1389.76 131.274 1389.45 131.499 1389.45 132.379V132.514H1389.13L1388.9 129.446L1393.38 129.627V129.604C1390.53 129.198 1388.77 128.273 1388.77 126.92C1388.77 125.882 1389.63 125.318 1390.64 125.318C1391.57 125.318 1392.22 125.679 1392.22 126.31C1392.22 126.807 1391.91 127.281 1391.09 127.281C1390.55 127.281 1390.19 126.987 1390.01 126.784C1389.88 126.671 1389.78 126.604 1389.65 126.604C1389.42 126.604 1389.36 126.784 1389.36 127.055C1389.36 128.521 1392.86 129.401 1395.16 129.401H1398.59C1399.81 129.401 1400.11 129.221 1400.11 128.318V127.867H1400.47V132.469H1400.08V132.514Z"
                fill="#231F20"
              />
              <path
                d="M1393.56 125.318V120.603H1394.93V125.318H1393.56Z"
                fill="#231F20"
              />
              <path
                d="M1403.22 119.655C1401.84 119.655 1400.65 118.437 1400.53 116.497C1400.38 117.986 1399.83 118.956 1398.62 118.956C1397.67 118.956 1396.97 118.324 1396.42 117.512C1395.7 118.415 1394.37 119.024 1392.92 119.024C1390.44 119.024 1388.7 117.49 1388.7 115.55C1388.7 114.512 1389.11 113.677 1390.08 112.978C1389.36 112.662 1388.7 112.211 1388.7 111.534C1388.7 110.902 1389.22 110.451 1390.03 110.451C1390.69 110.451 1391.12 110.812 1391.12 111.218C1391.12 111.624 1390.85 111.918 1390.06 111.94C1389.69 111.94 1389.51 111.963 1389.51 112.121C1389.51 112.301 1389.83 112.527 1390.37 112.752C1391.21 112.301 1392.04 112.121 1392.92 112.121C1395.39 112.121 1397.17 113.655 1397.17 115.55C1397.17 116.114 1397.01 116.745 1396.63 117.219C1396.92 117.67 1397.33 117.941 1397.85 117.941C1398.48 117.941 1398.89 117.377 1398.95 115.324C1399 113.474 1399.47 111.083 1402.23 111.083C1404.53 111.083 1406 113.136 1406 115.572C1406 117.806 1405.16 119.655 1403.22 119.655ZM1392.92 113.993C1391.21 113.993 1389.02 114.151 1389.02 115.572C1389.02 116.993 1391.09 117.174 1392.92 117.174C1394.78 117.174 1396.85 116.993 1396.85 115.572C1396.85 114.196 1394.78 113.993 1392.92 113.993ZM1402.91 111.963C1401.64 111.963 1400.72 112.91 1400.72 115.459C1400.72 117.806 1402.05 118.527 1403.24 118.527C1404.92 118.527 1405.66 117.151 1405.66 115.505C1405.64 113.474 1404.37 111.963 1402.91 111.963Z"
                fill="#231F20"
              />
              <path
                d="M1400.08 110.474V110.338C1400.08 109.436 1399.79 109.233 1398.57 109.233H1390.98C1389.76 109.233 1389.45 109.459 1389.45 110.338V110.474H1389.13L1388.9 107.406L1393.38 107.586V107.564C1390.53 107.157 1388.77 106.233 1388.77 104.879C1388.77 103.841 1389.63 103.277 1390.64 103.277C1391.57 103.277 1392.22 103.638 1392.22 104.27C1392.22 104.766 1391.91 105.24 1391.09 105.24C1390.55 105.24 1390.19 104.947 1390.01 104.744C1389.88 104.631 1389.78 104.563 1389.65 104.563C1389.42 104.563 1389.36 104.744 1389.36 105.014C1389.36 106.481 1392.86 107.36 1395.16 107.36H1398.59C1399.81 107.36 1400.11 107.18 1400.11 106.278V105.826H1400.47V110.429H1400.08V110.474Z"
                fill="#231F20"
              />
              <path
                d="M1394.73 103.255C1391.09 103.255 1388.7 101.269 1388.7 99.2391C1388.7 97.1862 1391.09 95.2235 1394.69 95.2235C1398.23 95.2235 1400.81 97.1411 1400.81 99.2391C1400.81 101.337 1398.25 103.255 1394.73 103.255ZM1394.78 97.299C1391.41 97.299 1389.06 97.7051 1389.06 99.2391C1389.06 100.818 1391.37 101.179 1394.73 101.179C1398.01 101.179 1400.44 100.818 1400.44 99.194C1400.44 97.5697 1398.05 97.299 1394.78 97.299Z"
                fill="#231F20"
              />
              <path
                d="M1391.25 93.9602C1390.06 94.276 1389.49 94.5467 1389.45 95.4266H1389.13V91.2305H1389.45C1389.49 91.9073 1389.74 92.2005 1390.17 92.2005C1390.53 92.2005 1391.07 92.1103 1391.61 91.9524L1397.58 90.283L1392.81 88.7941L1390.94 89.2904C1390.1 89.516 1389.49 89.719 1389.45 90.4635H1389.13V86.5155H1389.45C1389.49 87.2375 1389.74 87.5533 1390.17 87.5533C1390.53 87.5533 1391 87.4405 1391.64 87.2826L1397.35 85.8388L1392.31 84.4852C1391.59 84.3047 1390.87 84.1694 1390.37 84.1694C1389.72 84.1694 1389.51 84.4852 1389.45 85.2071H1389.13V82.342H1389.45C1389.54 83.1542 1390.17 83.4249 1391.25 83.7182L1400.81 86.4028V86.8088L1393.56 88.6587L1400.81 90.847V91.253L1391.25 93.9602Z"
                fill="#231F20"
              />
              <path
                d="M1384.43 81.575C1383.71 81.575 1383.1 81.0111 1383.1 80.244C1383.1 79.5447 1383.69 78.9356 1384.43 78.9356C1385.16 78.9356 1385.76 79.5221 1385.76 80.244C1385.74 80.9659 1385.16 81.575 1384.43 81.575ZM1400.08 82.2293V82.0037C1400.08 81.1013 1399.79 80.9208 1398.59 80.9208H1390.98C1389.72 80.9208 1389.4 81.0787 1389.4 82.0037V82.2293H1389.11L1388.88 79.1161H1398.57C1399.77 79.1161 1400.06 78.9581 1400.06 78.0332V77.8076H1400.42V82.2293H1400.08Z"
                fill="#231F20"
              />
              <path
                d="M1400.08 77.5144V77.379C1400.08 76.4766 1399.79 76.2736 1398.57 76.2736H1390.98C1389.76 76.2736 1389.45 76.4992 1389.45 77.379V77.5144H1389.13L1388.9 74.4463L1391.79 74.5591V74.5365C1390.01 74.1304 1388.88 73.1378 1388.88 71.7843C1388.88 70.1374 1389.88 69.0997 1392.56 69.0997H1398.57C1399.81 69.0997 1400.08 68.8741 1400.08 68.1071V67.9943H1400.44V72.1226H1400.08V71.9647C1400.08 71.1526 1399.79 70.927 1398.57 70.927H1391.86C1390.19 70.927 1389.74 71.4684 1389.74 72.2806C1389.74 73.7244 1391.73 74.4463 1393.99 74.4463H1398.57C1399.79 74.4463 1400.08 74.2884 1400.08 73.4537V73.2957H1400.44V77.5144H1400.08Z"
                fill="#231F20"
              />
              <path
                d="M1403.22 67.6333C1401.84 67.6333 1400.65 66.4151 1400.53 64.475C1400.38 65.9639 1399.83 66.934 1398.62 66.934C1397.67 66.934 1396.97 66.3023 1396.42 65.4902C1395.7 66.3926 1394.37 67.0017 1392.92 67.0017C1390.44 67.0017 1388.7 65.4676 1388.7 63.5275C1388.7 62.4898 1389.11 61.6551 1390.08 60.9557C1389.36 60.6399 1388.7 60.1887 1388.7 59.5119C1388.7 58.8803 1389.22 58.4291 1390.03 58.4291C1390.69 58.4291 1391.12 58.79 1391.12 59.1961C1391.12 59.6022 1390.85 59.8954 1390.06 59.918C1389.69 59.918 1389.51 59.9406 1389.51 60.0985C1389.51 60.279 1389.83 60.5046 1390.37 60.7301C1391.21 60.279 1392.04 60.0985 1392.92 60.0985C1395.39 60.0985 1397.17 61.6325 1397.17 63.5275C1397.17 64.0915 1397.01 64.7232 1396.63 65.1969C1396.92 65.6481 1397.33 65.9188 1397.85 65.9188C1398.48 65.9188 1398.89 65.3548 1398.95 63.3019C1399 61.452 1399.47 59.0607 1402.23 59.0607C1404.53 59.0607 1406 61.1137 1406 63.5501C1406 65.7835 1405.16 67.6333 1403.22 67.6333ZM1392.92 61.9709C1391.21 61.9709 1389.02 62.1288 1389.02 63.5501C1389.02 64.9939 1391.09 65.1518 1392.92 65.1518C1394.78 65.1518 1396.85 64.9713 1396.85 63.5501C1396.85 62.1514 1394.78 61.9709 1392.92 61.9709ZM1402.91 59.9406C1401.64 59.9406 1400.72 60.8881 1400.72 63.4373C1400.72 65.7835 1402.05 66.5054 1403.24 66.5054C1404.92 66.5054 1405.66 65.1292 1405.66 63.4824C1405.64 61.452 1404.37 59.9406 1402.91 59.9406Z"
                fill="#231F20"
              />
              <path
                d="M1400.08 52.7667V52.5862C1400.08 51.6161 1399.77 51.4582 1398.46 51.4582H1383.89C1382.58 51.4582 1382.26 51.6838 1382.26 52.7667V52.9923H1381.9L1381.45 49.6986H1398.46C1399.77 49.6986 1400.08 49.5407 1400.08 48.5706V48.3901H1400.44V52.8343H1400.08V52.7667Z"
                fill="#231F20"
              />
              <path
                d="M1394.82 47.7133C1391.14 47.7133 1388.7 45.7957 1388.7 43.7654C1388.7 42.1411 1390.37 40.2687 1393.83 40.2687C1394.08 40.2687 1394.5 40.2912 1394.64 40.3138V45.6378C1397.73 45.6604 1400.11 45.2318 1400.11 43.3142C1400.11 41.9606 1399.04 41.1259 1397.64 40.6071L1397.8 40.2912C1399.47 40.8552 1400.81 41.893 1400.81 43.6526C1400.81 45.886 1398.61 47.7133 1394.82 47.7133ZM1394.19 43.2691C1394.19 42.4118 1393.92 42.2088 1392.99 42.2088C1390.87 42.2088 1389.02 42.5021 1389.02 43.8105C1389.02 45.1641 1391.09 45.5927 1394.19 45.6604V43.2691Z"
                fill="#231F20"
              />
              <path
                d="M1403.22 39.5918C1401.84 39.5918 1400.65 38.3736 1400.53 36.4335C1400.38 37.9224 1399.83 38.8925 1398.62 38.8925C1397.67 38.8925 1396.97 38.2608 1396.42 37.4487C1395.7 38.3511 1394.37 38.9602 1392.92 38.9602C1390.44 38.9602 1388.7 37.4261 1388.7 35.486C1388.7 34.4483 1389.11 33.6136 1390.08 32.9142C1389.36 32.5984 1388.7 32.1472 1388.7 31.4704C1388.7 30.8388 1389.22 30.3876 1390.03 30.3876C1390.69 30.3876 1391.12 30.7485 1391.12 31.1546C1391.12 31.5607 1390.85 31.8539 1390.06 31.8765C1389.69 31.8765 1389.51 31.8991 1389.51 32.057C1389.51 32.2374 1389.83 32.463 1390.37 32.6886C1391.21 32.2374 1392.04 32.057 1392.92 32.057C1395.39 32.057 1397.17 33.591 1397.17 35.486C1397.17 36.05 1397.01 36.6817 1396.63 37.1554C1396.92 37.6066 1397.33 37.8773 1397.85 37.8773C1398.48 37.8773 1398.89 37.3133 1398.95 35.2604C1399 33.4105 1399.47 31.0192 1402.23 31.0192C1404.53 31.0192 1406 33.0721 1406 35.5086C1406 37.7645 1405.16 39.5918 1403.22 39.5918ZM1392.92 33.952C1391.21 33.952 1389.02 34.1099 1389.02 35.5311C1389.02 36.9524 1391.09 37.1329 1392.92 37.1329C1394.78 37.1329 1396.85 36.9524 1396.85 35.5311C1396.85 34.1324 1394.78 33.952 1392.92 33.952ZM1402.91 31.9216C1401.64 31.9216 1400.72 32.8691 1400.72 35.4183C1400.72 37.7645 1402.05 38.4864 1403.24 38.4864C1404.92 38.4864 1405.66 37.1103 1405.66 35.4635C1405.64 33.4331 1404.37 31.9216 1402.91 31.9216Z"
                fill="#231F20"
              />
              <path
                d="M1394.82 30.7936C1391.14 30.7936 1388.7 28.8761 1388.7 26.8457C1388.7 25.2214 1390.37 23.349 1393.83 23.349C1394.08 23.349 1394.5 23.3716 1394.64 23.3941V28.7182C1397.73 28.7407 1400.11 28.3121 1400.11 26.3945C1400.11 25.041 1399.04 24.2063 1397.64 23.6874L1397.8 23.3716C1399.47 23.9355 1400.81 24.9733 1400.81 26.7329C1400.81 28.9663 1398.61 30.7936 1394.82 30.7936ZM1394.19 26.372C1394.19 25.5147 1393.92 25.3117 1392.99 25.3117C1390.87 25.3117 1389.02 25.6049 1389.02 26.9134C1389.02 28.267 1391.09 28.6956 1394.19 28.7633V26.372Z"
                fill="#231F20"
              />
              <path
                d="M1400.08 22.8302V22.6948C1400.08 21.7924 1399.79 21.5894 1398.57 21.5894H1390.98C1389.76 21.5894 1389.45 21.815 1389.45 22.6948V22.8302H1389.13L1388.9 19.7621L1391.79 19.8749V19.8523C1390.01 19.4462 1388.88 18.4536 1388.88 17.1001C1388.88 15.4532 1389.88 14.4155 1392.56 14.4155H1398.57C1399.81 14.4155 1400.08 14.1899 1400.08 13.4229V13.3101H1400.44V17.4384H1400.08V17.2805C1400.08 16.4684 1399.79 16.2428 1398.57 16.2428H1391.86C1390.19 16.2428 1389.74 16.7842 1389.74 17.5964C1389.74 19.0402 1391.73 19.7621 1393.99 19.7621H1398.57C1399.79 19.7621 1400.08 19.6042 1400.08 18.7695V18.6115H1400.44V22.8302H1400.08Z"
                fill="#231F20"
              />
              <path
                d="M1394.78 12.8363C1391.41 12.8363 1388.81 11.1444 1388.81 9.15912C1388.81 7.94091 1389.85 7.15133 1391.52 6.76782V6.70014C1390.78 6.7227 1389.08 6.74526 1388.2 6.74526H1383.78C1382.54 6.74526 1382.24 6.92574 1382.24 7.85067V8.36954H1381.93L1381.5 4.9405H1398.48C1399.74 4.9405 1399.97 4.8277 1400.02 4.1058L1400.04 3.72229H1400.35C1400.44 4.24116 1400.76 6.81294 1400.76 6.81294L1397.92 6.7227V6.81294C1399.52 7.17389 1400.72 7.89579 1400.72 9.27192C1400.69 11.1669 1398.23 12.8363 1394.78 12.8363ZM1394.84 6.76782C1390.94 6.76782 1389.29 7.89579 1389.29 8.93353C1389.29 10.4676 1391.89 10.7383 1394.73 10.7383C1397.85 10.7383 1400.13 10.4225 1400.13 8.93353C1400.13 7.39948 1397.28 6.76782 1394.84 6.76782Z"
                fill="#231F20"
              />
              <path
                d="M1399.43 2.57178C1398.71 2.57178 1398.16 2.03035 1398.16 1.30845C1398.16 0.541427 1398.71 0 1399.43 0C1400.17 0 1400.74 0.541427 1400.74 1.30845C1400.74 2.03035 1400.2 2.57178 1399.43 2.57178Z"
                fill="#231F20"
              />
            </svg>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { NFTConnect, EthereumNetworks } from "@poolside-fm/nft-connect";

import API from "./api";
import dayjs from "dayjs";

export default {
  name: "SignupForm",
  components: {},
  data() {
    return {
      modelName: "",
      modelEmail: "",
      modelInterestLevel: "",
      modelMix: "",
      error: false,
      submitting: false,
      submitted: false,
      connectWalletText: "Connect your wallet",
      execMemberAddress: false,
      verifiedExecMemberAddress: false,
    };
  },
  computed: {
    imageData() {
      return {
        template: "Powdyxbdx0NblYBAgQ",
        modifications: [
          {
            name: "name",
            text: `Dear ${this.modelName}`,
          },
          {
            name: "date",
            text: dayjs().format("MMMM DD, YYYY"),
          },
        ],
        webhook_url: null,
        transparent: false,
        metadata: null,
      };
    },
  },
  mounted() {
    this.nftConnect = new NFTConnect({
      rpcUrl: process.env.VUE_APP_RPC_URL,
      network: process.env.VUE_APP_RPC_URL.includes("rinkeby") ? EthereumNetworks.Rinkeby : EthereumNetworks.Mainnet,
      appName: "Manor DAO",
      appDescription: "Login with the power of the Web III",
      serverHost: process.env.VUE_APP_POOLSUITE_HOST,
      serverAPIKey: process.env.VUE_APP_POOLSUITE_API_KEY,
    });
  },
  methods: {
    async decideAction() {
      if (this.execMemberAddress) {
        if (this.verifiedExecMemberAddress) {
          return;
        }
        this.verifyNFT();
      } else {
        this.toggleConnectOverlay();
      }
    },
    async toggleConnectOverlay() {
      this.error = false;
      this.connectWalletText = "Connecting wallet...";
      const address = await this.nftConnect.connectWallet();
      this.connectWalletText = "Connect your wallet";
      if (address) {
        this.connectWalletText = "Wallet found. Now, please verify ownership.";
        this.execMemberAddress = address;
      }
    },
    async verifyNFT() {
      this.error = false;
      this.connectWalletText = "Requesting signature...";
      try {
        await this.nftConnect.verifyMembershipWithSignedMessage(
          "Sign this message to validate your Poolsuite Executive Membership"
        );
        this.verifiedExecMemberAddress = this.execMemberAddress;
        this.connectWalletText = "Thanks! Ownership verified";
      } catch (e) {
        this.connectWalletText = "Wallet found. Now, please verify ownership.";
        this.error = "Sorry, there has been a problem. Please try again";
      }
    },
    submitForm(event) {
      this.error = false;
      event.preventDefault();

      if (
        (this.modelName && this.modelEmail && this.modelInterestLevel) ||
        (this.modelName &&
          this.modelEmail &&
          this.modelInterestLevel ==
            "Become a core contributor to the project" &&
          this.modelMix)
      ) {
        this.submitting = true;
        let submitDataObject = {
          name: this.modelName,
          email: this.modelEmail,
          interestLevel: this.modelInterestLevel,
          holdsExecutiveClubNFT: this.verifiedExecMemberAddress ? true : false,
          skills: this.modelMix,
          walletAddress: this.verifiedExecMemberAddress
            ? this.verifiedExecMemberAddress
            : "",
        };
        let params = new URLSearchParams(submitDataObject).toString();
        API.get(
          `https://hooks.zapier.com/hooks/catch/9323097/b96jpft/?${params}`
        ).then(() => {
          this.submitting = false;
          this.submitted = true;
        });
      } else {
        this.error = "Please fill out all fields before submitting";
      }
    },
  },
};
</script>

<style>
*:disabled {
  cursor: default;
}
</style>
