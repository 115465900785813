import axios from "axios";

const API = {
  get(path, params) {
    path = `${path}`;
    return axios({
      method: "GET", //you can set what request you want to be
      url: path,
      data: params,
    });
  },
};

export default API;
